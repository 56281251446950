// External
import { zodResolver } from '@hookform/resolvers/zod'
import type { StackScreenProps } from '@react-navigation/stack'
import { zonedTimeToUtc } from 'date-fns-tz'
import { createRef, type RefObject } from 'react'
import {
  Controller,
  useFieldArray,
  useForm,
  type UseFormReturn
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ScrollView,
  StyleSheet,
  View,
  type TextInput,
  type TouchableOpacity
} from 'react-native'
import { z } from 'zod'
// Components
import {
  Button,
  DateInput,
  Input,
  RadioButton,
  Text,
  TopBar
} from '@/common/components'
import { CustomFieldAttachmentList } from '@/profile/components'
// Constants
import { colors } from '@/common/constants'
// Layouts
import { KeyboardFix, SafeArea } from '@/common/layouts'
// Models
import {
  CustomFieldTypes,
  type CustomFieldsStackParamList
} from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { fillCustomFields } from '@/profile/useCases'
// Utils
import { handleError } from '@/common/utils'
import {
  AttachmentCustomFieldsSchema,
  CustomFieldsSchema
} from '@/profile/models'

type Props = StackScreenProps<CustomFieldsStackParamList, 'CustomFields'>

const CustomFields = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { missingCustomFields, setMissingCustomFields, currentFacility } =
    useAppStore((state) => ({
      missingCustomFields: state.missingCustomFields,
      setMissingCustomFields: state.setMissingCustomFields,
      currentFacility: state.currentFacility
    }))

  const formSchema = z.object({
    customFields: z.array(CustomFieldsSchema),
    attachmentCustomFields: z.array(AttachmentCustomFieldsSchema)
  })

  type FormData = z.infer<typeof formSchema>

  const defaultValues: FormData = {
    customFields: missingCustomFields
      .filter((field) => field.type !== CustomFieldTypes.ATTACHMENTS)
      .map((field) => ({
        id: field._id,
        ref: createRef(),
        type: field.type,
        regex: field.regex,
        label: field.translation.name,
        placeholder: field.translation.placeholder,
        regexDescription: field.translation.regexDescription,
        autoComplete: field.autoComplete,
        isRequired: field.isRequired,
        value: undefined // Initialize value here
      })),
    attachmentCustomFields: missingCustomFields
      .filter((field) => field.type === CustomFieldTypes.ATTACHMENTS)
      .map((field) => ({
        id: field._id,
        name: field.translation.name,
        description: field.translation.description,
        placeholder: field.translation.placeholder,
        value: [],
        isRequired: field.isRequired
      }))
  }

  const form: UseFormReturn<FormData> = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = form

  const { fields: customFields } = useFieldArray({
    control,
    name: 'customFields'
  })

  const { fields: attachmentCustomFields } = useFieldArray({
    control,
    name: 'attachmentCustomFields'
  })

  const onSubmitEditing = async (index: number) => {
    if (index < customFields.length - 1) {
      const nextField = customFields[index + 1]
      if (nextField !== undefined) {
        if (
          [CustomFieldTypes.NUMBER, CustomFieldTypes.TEXT].includes(
            nextField.type
          )
        ) {
          nextField.ref?.current?.focus()
          return
        }

        if (nextField.type === CustomFieldTypes.DATE) {
          nextField.ref?.current?.openCalendar()
        }
      }
    }

    if (index === customFields.length - 1) {
      await handleSubmit(onSubmit)()
    }
  }

  const onSubmit = async (values: FormData) => {
    try {
      await fillCustomFields({
        customFields: values.customFields
          .filter(
            (field) =>
              field.value !== undefined &&
              (field.value !== '' || typeof field.value === 'boolean')
          )
          .map((field) => ({
            customFieldId: field.id,
            value: field.value as string
          })),
        attachmentCustomFields: values.attachmentCustomFields
          .filter((field) => field.value.length > 0)
          .map((field) => ({
            id: field.id,
            value: field.value
          }))
      })
      setMissingCustomFields([])
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <SafeArea>
      <KeyboardFix contentContainerStyle={{ flex: 1 }}>
        <TopBar
          style={{ paddingHorizontal: 25 }}
          title={t('extraFields')}
          leftIcon={{
            name: 'back',
            onPress: () => {
              navigation.replace('SelectYourUserType')
            }
          }}
        />

        <ScrollView
          bounces={false}
          contentContainerStyle={styles.scrollContentContainer}
        >
          <Text style={{ marginBottom: 27.5 }}>
            {t('requiresAdditionalInformation', {
              facilityName: currentFacility?.name
            })}
          </Text>

          {customFields.map((field, index) => (
            <Controller
              key={field.id}
              control={control}
              name={`customFields.${index}.value`}
              render={({ field: { onBlur, onChange, value } }) => {
                const commonProps = {
                  onSubmitEditing: async () => {
                    await onSubmitEditing(index)
                  },
                  placeholder: field.placeholder,
                  errorMessage: errors.customFields?.[index]?.value
                    ?.message as string,
                  label: field.isRequired
                    ? field.label
                    : t('optional', { label: field.label })
                }

                if (field.type === CustomFieldTypes.BOOLEAN) {
                  return (
                    <View>
                      <Text variant="label">{commonProps.label}</Text>

                      <View style={styles.booleanOptions}>
                        <View style={styles.booleanOption}>
                          <Text variant="small">{t('yes')}</Text>

                          <RadioButton
                            checked={value === true}
                            onPress={() => {
                              onChange(true)
                              onBlur()
                            }}
                          />
                        </View>

                        <View style={styles.booleanOption}>
                          <Text variant="small">{t('no')}</Text>

                          <RadioButton
                            checked={value === false}
                            onPress={() => {
                              onChange(false)
                              onBlur()
                            }}
                          />
                        </View>
                      </View>

                      <Text
                        style={{ margin: 5 }}
                        variant="extraSmall"
                        color={colors.error}
                      >
                        {commonProps.errorMessage}
                      </Text>
                    </View>
                  )
                }

                if (field.type === CustomFieldTypes.DATE) {
                  return (
                    <DateInput
                      ref={field.ref as RefObject<typeof TouchableOpacity>}
                      value={
                        value !== undefined &&
                        typeof value === 'string' &&
                        value.length > 0
                          ? new Date(value)
                          : undefined
                      }
                      onDayPress={(data) => {
                        let date = new Date(data.year, data.month - 1, data.day)

                        if (currentFacility?.info.timezone !== undefined) {
                          date = zonedTimeToUtc(
                            new Date(data.year, data.month - 1, data.day),
                            currentFacility?.info.timezone
                          )
                        }

                        onChange(date.toISOString())
                        onBlur()
                      }}
                      {...commonProps}
                    />
                  )
                }

                return (
                  <Input
                    ref={field.ref as RefObject<TextInput>}
                    value={value as string | undefined}
                    onChangeText={(text) => {
                      onChange(text)
                    }}
                    onBlur={onBlur}
                    returnKeyType={
                      index === customFields.length - 1 ? 'done' : 'next'
                    }
                    {...(field.type === CustomFieldTypes.NUMBER && {
                      keyboardType: 'numeric'
                    })}
                    autoComplete={field.autoComplete}
                    {...commonProps}
                  />
                )
              }}
            />
          ))}

          {attachmentCustomFields.map((field, index) => (
            <Controller
              key={field.id}
              control={control}
              name={`attachmentCustomFields.${index}.value`}
              render={({ field: { onBlur, onChange, value } }) => (
                <CustomFieldAttachmentList
                  attachments={value}
                  onAddAttachment={(attachment) => {
                    onChange([...value, attachment])
                    onBlur()
                  }}
                  onRemoveAttachment={(index) => {
                    onChange(value.filter((_, i) => i !== index))
                    onBlur()
                  }}
                  name={field.name}
                  description={field.description}
                  placeholder={field.placeholder}
                  errorMessage={
                    errors?.attachmentCustomFields?.[index]?.value?.message
                  }
                  isRequired={field.isRequired}
                />
              )}
            />
          ))}
        </ScrollView>

        <Button
          title={t('save')}
          style={styles.button}
          onPress={handleSubmit(onSubmit)}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </KeyboardFix>
    </SafeArea>
  )
}

export default CustomFields

const styles = StyleSheet.create({
  scrollContentContainer: {
    paddingTop: 23,
    paddingHorizontal: 25,
    paddingBottom: 25
  },
  button: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  booleanOptions: {
    flexDirection: 'row',
    gap: 8
  },
  booleanOption: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})
