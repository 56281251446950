// External
import { zodResolver } from '@hookform/resolvers/zod'
import type { StackScreenProps } from '@react-navigation/stack'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { z } from 'zod'
// Components
import {
  Input,
  ProgressBar,
  StepNavigationButtons,
  Text
} from '@/common/components'
// Models
import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
// Stores
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'
// Layouts
import { SafeArea } from '@/common/layouts'

type Props = StackScreenProps<CSPPTWStackParamList, 'NewRequestStep5'>

const NewRequestStep5 = ({ navigation }: Props) => {
  const { values: currentValues, setValues } = useNewRequestStore()
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      z.object({
        description: z.string().nonempty({ message: t('requiredField') })
      }),
    [t]
  )

  type FormValues = z.infer<typeof formSchema>

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: { description: currentValues?.description ?? '' }
  })

  const onSubmit = (values: FormValues) => {
    setValues({
      ...currentValues,
      ...values
    })
    navigation.navigate('NewRequestStep6')
  }

  return (
    <SafeArea style={styles.container}>
      <ProgressBar style={styles.progressBar} value={0.5555} />

      <Text variant="h2Bold" style={{ marginBottom: 8 }}>
        {t('workDescription')}
      </Text>

      <Text style={{ marginBottom: 25 }}>
        {t('descriptionOfTheWorkToBePerformed')}
      </Text>

      <Controller
        control={control}
        name="description"
        render={({
          field: { onBlur, onChange, value },
          fieldState: { error }
        }) => (
          <Input
            label={t('workDescription')}
            placeholder={t('typeTheDescriptionOfTheWork')}
            onChangeText={onChange}
            onBlur={onBlur}
            value={value}
            errorMessage={error?.message}
          />
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default NewRequestStep5

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  }
})
