import { client } from '@/common/config'
import type { PaginatedGetParams } from '@/common/models'

interface Params extends PaginatedGetParams {
  facilityId: string
}

export const getAuthorizedCompanies = async ({
  facilityId,
  page,
  limit = 10
}: Params) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/company/{facilityId}/search',
    {
      params: {
        path: {
          facilityId
        },
        query: {
          isAuthorized: true,
          page,
          limit
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting authorized:', error)
    return await Promise.reject(error)
  }

  return data
}
