// External
import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { z, ZodIssueCode } from 'zod'
// Components
import { Select, StepNavigationButtons } from '@/common/components'
import { PermitRichText } from '../PermitRichText'
// Constants
import {
  contractorSchema,
  equipmentSchema,
  type Contractor,
  type Equipment
} from '@/cspPermitToWork/constants'
// Stores
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'

interface Props {
  cursor: Equipment
  back: () => void
  onSubmit: (values: { equipment: Equipment; worker?: Contractor }) => void
}

export const LastAdditionalEquipmentForm = ({
  cursor,
  back,
  onSubmit
}: Props) => {
  const { values: currentValues } = useNewRequestStore((state) => ({
    values: state.values
  }))
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      z
        .object({
          equipment: equipmentSchema,
          worker: contractorSchema.optional()
        })
        .superRefine(({ equipment, worker }, ctx) => {
          if (equipment.mustSelectWorker && worker === undefined) {
            ctx.addIssue({
              code: ZodIssueCode.custom,
              message: t('requiredField'),
              path: ['worker']
            })
          }
        }),
    [t]
  )

  type FormValues = z.infer<typeof formSchema>

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    watch
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      equipment: cursor
    }
  })

  const { equipment } = watch()

  return (
    <>
      {(equipment?.mustSelectWorker ?? false) && (
        <Controller
          control={control}
          name="worker"
          render={({
            field: { onBlur, onChange, value },
            fieldState: { error }
          }) => (
            <Select
              options={[
                ...(currentValues?.employees ?? []),
                ...(currentValues?.subcontractedCompanies?.flatMap(
                  ({ subcontractedEmployees }) => subcontractedEmployees
                ) ?? [])
              ].map((employee) => ({
                label: `${employee.appUser.firstName} ${employee.appUser.lastName}`,
                value: employee._id,
                original: employee
              }))}
              onSelect={(option) => {
                onChange(option.original)
                onBlur()
              }}
              errorMessage={error?.message}
              label={t('selectEmployee')}
              placeholder={t('searchEmployees')}
              value={value?._id}
            />
          )}
        />
      )}

      {cursor.applicationStandards !== undefined &&
        cursor.applicationStandards.length > 0 && (
          <PermitRichText
            style={styles.applicationStandards}
            title={t('applicationStandardsFor', { zone: cursor.name })}
            html={cursor.applicationStandards}
          />
        )}

      <StepNavigationButtons
        style={{ marginBottom: 25 }}
        backOnPress={() => {
          back()
          reset()
        }}
        nextOnPress={handleSubmit(onSubmit)}
        nextTitleTranslationKey="selectEquipment"
        nextDisabled={isSubmitting}
      />
    </>
  )
}

const styles = StyleSheet.create({
  applicationStandards: {
    flex: 1,
    marginBottom: 25
  }
})
