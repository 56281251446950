import { colors } from '@/common/constants'
import type { IconNode, InputProps } from '@rneui/base'
import { Input } from '@rneui/themed'
import {
  Platform,
  TouchableOpacity as RNTouchableOpacity,
  type ViewStyle
} from 'react-native'
import { TouchableOpacity as GHTouchableOpacity } from 'react-native-gesture-handler'

const Wrapper =
  Platform.OS === 'android' ? RNTouchableOpacity : GHTouchableOpacity

interface Props {
  style?: ViewStyle
  inputStyle?: InputProps['inputStyle']
  disabled?: boolean
  value?: string
  placeholder?: string
  onPress?: () => void
  label?: string
  errorMessage?: string
  rightIcon?: IconNode
}

export const TouchableInput = ({
  style,
  inputStyle,
  disabled = false,
  value,
  placeholder,
  onPress,
  label,
  errorMessage,
  rightIcon
}: Props) => {
  return (
    <Wrapper style={style} disabled={disabled} onPress={onPress}>
      <Input
        label={label}
        // If passed directly it'll override the theme's base styles
        inputStyle={[inputStyle]}
        inputContainerStyle={{
          borderColor: errorMessage !== undefined ? colors.error : 'transparent'
        }}
        disabled={disabled}
        editable={false}
        value={value}
        placeholder={placeholder}
        rightIcon={rightIcon}
        errorMessage={errorMessage}
      />
    </Wrapper>
  )
}
