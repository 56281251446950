// External
import { useTranslation } from 'react-i18next'
import {
  Dimensions,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native'
// Components
import { Icon, Text } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import type { components } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { goBackToTheFacility } from '@/findFacility/useCases'
// Utils
import { generateImageUrl, handleError } from '@/common/utils'

interface Props {
  visitedFacilities: Array<
    components['schemas']['LastVisitedFacilitiesResponse']
  >
  horizontalPadding: {
    paddingLeft: number
    paddingRight: number
  }
}

export const FacilitiesVisited = ({
  visitedFacilities,
  horizontalPadding
}: Props) => {
  const logIntoFacility = useAppStore((state) => state.logIntoFacility)
  const { t } = useTranslation()
  const width =
    Dimensions.get('window').width -
    (horizontalPadding.paddingLeft + horizontalPadding.paddingRight)

  return (
    <View style={styles.container}>
      <Text
        variant="baseBold"
        style={[horizontalPadding, { marginBottom: 14 }]}
      >
        {t('facilitiesVisited')}
      </Text>

      {visitedFacilities.length > 0 ? (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator
          contentContainerStyle={{ ...horizontalPadding, gap: 25 }}
          bounces={false}
        >
          {visitedFacilities.map((visitedFacility) => (
            <TouchableOpacity
              key={visitedFacility.facilityId}
              style={[
                styles.facilityContainer,
                { backgroundColor: colors.inactive, width }
              ]}
              onPress={async () => {
                try {
                  const { user, facility } = await goBackToTheFacility({
                    facilityId: visitedFacility.facilityId,
                    userTypeId: visitedFacility.userType._id
                  })

                  logIntoFacility(user, visitedFacility.userType, [], facility)
                } catch (error) {
                  handleError(error)
                }
              }}
            >
              <Image
                resizeMode="cover"
                style={[styles.avatar, { borderColor: colors.white }]}
                source={{
                  uri: generateImageUrl(visitedFacility.avatarUrl, {
                    height: 57,
                    width: 57
                  })
                }}
              />

              <View style={{ flex: 1 }}>
                <Text variant="mediumBold" numberOfLines={1}>
                  {visitedFacility.name}
                </Text>

                <Text numberOfLines={1}>{t('goBackToTheFacility')}</Text>
              </View>

              <Icon size={13} name="go-back-facility" />
            </TouchableOpacity>
          ))}
        </ScrollView>
      ) : (
        <View
          style={[styles.notVisitedContainer, { borderColor: colors.inactive }]}
        >
          <Text>{t('notVisitedAnyFacilities')}</Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 150,
    marginTop: 25,
    paddingBottom: 18
  },
  notVisitedContainer: {
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 3,
    borderStyle: 'dashed',
    height: 85
  },
  facilityContainer: {
    borderRadius: 25,
    height: 85,
    paddingVertical: 10,
    paddingHorizontal: 25,
    flexDirection: 'row',
    gap: 20,
    alignItems: 'center'
  },
  avatar: {
    borderWidth: 4,
    height: 57,
    width: 57,
    borderRadius: 28.5
  }
})
