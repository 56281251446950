import { client } from '@/common/config'
import type {
  CSPPermitToWorkRequestStatusEnum,
  PaginatedGetParams
} from '@/common/models'

interface Params extends PaginatedGetParams {
  status: CSPPermitToWorkRequestStatusEnum
}

export const getPermits = async ({ status, page, limit = 10 }: Params) => {
  const { data, error } = await client.GET(
    '/api/app/csp-permit-to-work/requests/search',
    {
      params: {
        query: {
          status,
          page,
          limit
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting permits:', error)
    return await Promise.reject(error)
  }

  return {
    totalCount: data.total,
    nextPage: data.nextPage,
    hasNextPage: data.nextPage !== null,
    results: data.data
  }
}
