// External
import { zodResolver } from '@hookform/resolvers/zod'
import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, type TextInput } from 'react-native'
import { z } from 'zod'
import { type StackScreenProps } from '@react-navigation/stack'
// Components
import {
  Input,
  ProgressBar,
  StepNavigationButtons,
  Text
} from '@/common/components'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import { nameRegEx } from '@/common/constants'
import useCreateAccountStore from '@/auth/stores/useCreateAccountStore'
// Models
import { type PreHomeStackParamList } from '@/common/models'

type Props = StackScreenProps<PreHomeStackParamList, 'CreateAccountStep4'>

const CreateAccountStep4 = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { firstName, lastName, setFullName } = useCreateAccountStore(
    (state) => ({
      firstName: state.firstName,
      lastName: state.lastName,
      setFullName: state.setFullName
    })
  )
  const lastNameInputRef = useRef<TextInput>(null)

  const validationSchema = z.object({
    firstName: z
      .string()
      .min(1, { message: t('provideName') })
      .regex(nameRegEx, { message: t('nameInstructions') }),
    lastName: z
      .string()
      .min(1, { message: t('provideLastName') })
      .regex(nameRegEx, { message: t('nameInstructions') })
  })

  type FormValues = z.infer<typeof validationSchema>

  const onSubmit = ({
    firstName: formFirstName,
    lastName: formLastName
  }: FormValues) => {
    setFullName(formFirstName, formLastName)
    navigation.navigate('CreateAccountStep5')
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstName,
      lastName
    }
  })

  return (
    <SafeArea style={styles.container} withBackground>
      <ProgressBar style={{ marginTop: 11 }} value={0.6} />

      <Text variant="h2Bold" style={styles.header}>
        {t('yourName')}
      </Text>

      <Text>{t('fillWithFullName')}</Text>

      <Text style={{ marginBottom: 25 }}>{t('whatYouSeeIn')}</Text>

      <Controller
        control={control}
        name="firstName"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            placeholder={t('yourName')}
            onChangeText={onChange}
            onSubmitEditing={() => {
              lastNameInputRef.current?.focus()
            }}
            onBlur={() => {
              onChange(value?.trim())
              onBlur()
            }}
            value={value}
            returnKeyType="next"
            autoComplete="name"
            autoCapitalize="words"
            errorMessage={errors.firstName?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="lastName"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            ref={lastNameInputRef}
            placeholder={t('yourLastName')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(onSubmit)}
            onBlur={() => {
              onChange(value?.trim())
              onBlur()
            }}
            value={value}
            returnKeyType="done"
            autoComplete="name-family"
            autoCapitalize="words"
            errorMessage={errors.lastName?.message}
          />
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default CreateAccountStep4

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 25,
    marginBottom: 25
  },
  header: {
    marginTop: 48,
    marginBottom: 8,
    textTransform: 'capitalize'
  }
})
