import type { NewRequestState } from '@/cspPermitToWork/models'
import { create } from 'zustand'

const useNewRequestStore = create<NewRequestState>((set, get) => ({
  values: undefined,
  setValues: (values) => {
    set({ values })
  },
  resetZone: () => {
    const { values } = get()
    set({
      values: {
        ...values,
        zone: undefined,
        extraQuestion: undefined,
        specificZone: undefined
      }
    })
  },
  resetMachinery: () => {
    const { values } = get()
    set({ values: { ...values, machinery: [] } })
  }
}))

export default useNewRequestStore
