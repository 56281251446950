import { client } from '@/common/config'
import type { PaginatedGetParams } from '@/common/models'

interface Params extends PaginatedGetParams {
  parentMachineryId?: string
}

export const getMachinery = async ({
  parentMachineryId,
  page,
  limit = 100
}: Params) => {
  const { data, error } = await client.GET(
    '/api/app/csp-permit-to-work/procedures/machines',
    {
      params: {
        query: {
          parentId: parentMachineryId,
          page,
          limit
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting machinery:', error)
    return await Promise.reject(error)
  }

  return data.data
}
