import { Avatar } from '@rneui/themed'
import { StyleSheet, View } from 'react-native'
import { Text } from './Text'

interface Props {
  firstName: string
  lastName: string
  email: string
  profileImage?: string
}

export const UserOption = ({
  firstName,
  lastName,
  email,
  profileImage
}: Props) => {
  return (
    <View style={styles.container}>
      <Avatar
        size={32}
        title={`${firstName[0] as string}${lastName[0] as string}`}
        imageProps={{
          source: {
            uri: profileImage
          }
        }}
      />

      <Text>{`${firstName} ${lastName}`}</Text>

      <Text variant="small">{email}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})
