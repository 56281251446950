import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { PermitRichText } from '../PermitRichText'

interface Props {
  applicationProcedures: string
  applicationStandards: string
  goodPractices: string
}

export const ProceduresAndStandards = ({
  applicationProcedures,
  applicationStandards,
  goodPractices
}: Props) => {
  const { t } = useTranslation()
  return (
    <View style={{ gap: 30 }}>
      {applicationProcedures.length > 0 && (
        <PermitRichText
          title={t('applicationProcedures')}
          html={applicationProcedures}
        />
      )}

      {applicationStandards.length > 0 && (
        <PermitRichText
          title={t('applicationStandards')}
          html={applicationStandards}
        />
      )}

      {goodPractices.length > 0 && (
        <PermitRichText title={t('goodPractices')} html={goodPractices} />
      )}
    </View>
  )
}
