import { Avatar, Text } from '@/common/components'
import { colors } from '@/common/constants'
import { generateImageUrl } from '@/common/utils'
import { StyleSheet, View, type ViewStyle } from 'react-native'

interface Props {
  style?: ViewStyle
  title?: string
  email: string
  firstName: string
  lastName: string
  profileImage?: string
}

export const PermitPersonCard = ({
  style,
  title,
  email,
  firstName,
  lastName,
  profileImage
}: Props) => {
  return (
    <View style={style}>
      {title !== undefined && (
        <Text style={{ marginBottom: 4 }} variant="label">
          {title}
        </Text>
      )}

      <View style={styles.company}>
        <Avatar
          size={40}
          borderRadius={20}
          source={{
            uri: generateImageUrl(profileImage, {
              width: 40,
              height: 40
            })
          }}
          initials={`${firstName[0]!}${lastName[0]!}`}
        />

        <Text
          numberOfLines={1}
          style={{
            fontFamily: 'PlusJakartaSans_500Medium',
            flexShrink: 1
          }}
          variant="small"
        >
          {`${firstName} ${lastName}`}
        </Text>

        <Text
          numberOfLines={1}
          style={{
            fontFamily: 'PlusJakartaSans_300Light',
            flex: 1
          }}
          variant="small"
        >
          {email}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  company: {
    height: 52,
    borderRadius: 7,
    backgroundColor: colors.inactive,
    paddingHorizontal: 12,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 5
  }
})
