// External
import { type InputProps } from '@rneui/themed'
import {
  forwardRef,
  useImperativeHandle,
  useState,
  type ForwardedRef
} from 'react'
import { SheetManager } from 'react-native-actions-sheet'
// Components
import { Icon } from './Icon'
import { TouchableInput } from './TouchableInput'
// Models
import type {
  BaseSelectProps,
  Option,
  PaginatedGetParams
} from '@/common/models'

interface Props<T> extends BaseSelectProps<T> {
  inputStyle?: InputProps['inputStyle']
  keepPlaceholder?: boolean
  queryKey: any[]
  queryFn: (params: PaginatedGetParams & Record<string, string>) => Promise<any>
  getOptions: (data: any[]) => Array<Option<T>>
  defaultSelectedOption?: Option<T>
  defaultSelectedOptions?: Array<Option<T>>
  onMultipleSelect?: (options: Array<Option<T>>) => void
}

export const InfiniteSelectInner = <T,>(
  {
    style,
    inputStyle,
    label,
    disabled = false,
    onSelect,
    searchable = false,
    keepPlaceholder = false,
    placeholder,
    searchPlaceholder,
    errorMessage,
    queryKey,
    queryFn,
    getOptions,
    noResultsTranslationKey,
    renderOption,
    defaultSelectedOption,
    defaultSelectedOptions = [],
    onMultipleSelect
  }: Props<T>,
  ref: ForwardedRef<unknown>
) => {
  const [selectedOption, setSelectedOption] = useState<Option<T> | undefined>(
    defaultSelectedOption ?? undefined
  )
  const [selectedOptions, setSelectedOptions] = useState<Array<Option<T>>>(
    defaultSelectedOptions
  )

  const openSheet = async () => {
    if (onSelect !== undefined) {
      const result = await SheetManager.show('infinite-select', {
        payload: {
          searchable,
          placeholder,
          searchPlaceholder,
          queryKey,
          queryFn,
          getOptions,
          noResultsTranslationKey,
          renderOption
        }
      })
      if (result !== undefined) {
        setSelectedOption(result)
        onSelect(result)
      }
      return
    }

    if (onMultipleSelect !== undefined) {
      const result = await SheetManager.show('multiple-infinite-select', {
        payload: {
          searchable,
          placeholder,
          searchPlaceholder,
          queryKey,
          queryFn,
          getOptions,
          noResultsTranslationKey,
          selectedOptions: defaultSelectedOptions
        }
      })
      if (result !== undefined) {
        // TODO - There is a bug that causes the result to have an option with undefined label and value
        const correctedResult = result.filter(
          (option) => option.label !== undefined && option.value !== undefined
        )
        setSelectedOptions(correctedResult)
        onMultipleSelect(correctedResult)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    openSheet
  }))

  const getValue = () => {
    if (keepPlaceholder) {
      return undefined
    }

    if (selectedOption !== undefined) {
      return selectedOption.selectedLabel ?? selectedOption.label
    }

    if (selectedOptions.length > 0) {
      return selectedOptions.map((option) => option.label).join(', ')
    }

    return undefined
  }

  return (
    <TouchableInput
      style={style}
      disabled={disabled}
      onPress={openSheet}
      label={label}
      inputStyle={
        inputStyle ?? {
          fontFamily: 'PlusJakartaSans_500Medium'
        }
      }
      value={getValue()}
      placeholder={placeholder}
      rightIcon={
        <Icon
          style={{
            opacity: disabled ? 0.33 : 1
          }}
          name="dropdown-arrow"
          size={10}
        />
      }
      errorMessage={errorMessage}
    />
  )
}

export const InfiniteSelect = forwardRef(InfiniteSelectInner)
