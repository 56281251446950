// External
import { ListItem } from '@rneui/themed'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { Button, Icon, Text, TopBar, TouchableInput } from '@/common/components'
import { ExtraQuestionAnswer } from '../ExtraQuestionAnswer'
import { ProcedureSelectSkeleton } from '../ProcedureSelectSkeleton'
import { LastZoneForm } from './LastZoneForm'
// Constants
import { colors } from '@/common/constants'
import type { Zone } from '@/cspPermitToWork/constants'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { components } from '@/common/models'
// Services
import { getZones } from '@/cspPermitToWork/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { getCursorValueString } from '@/cspPermitToWork/utils'

interface Props {
  placeholder?: string
  errorMessage?: string
  value: {
    zone?: Zone
    specificZone?: string
    extraQuestion?: {
      question: string
      answer: string
      extraQuestionId: string
    }
  }
  onSelect: (values: {
    zone: Zone
    specificZone?: string
    extraQuestion?: {
      question: string
      answer: string
      extraQuestionId: string
    }
  }) => void
}

export const ZoneSelect = ({
  placeholder,
  errorMessage,
  value,
  onSelect
}: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const [cursor, setCursor] = useState<Zone>()
  const [selecting, setSelecting] = useState(false)
  const { t } = useTranslation()
  const { bottom } = useSafeAreaInsets()
  const isRTL = useIsRTL()

  const back = useCallback(() => {
    // the function is only available when cursor is defined
    if (cursor!.parents.length === 0) {
      setCursor(undefined)
      return
    }

    // @ts-expect-error - it only gets here when there's at least one parent
    setCursor((prev) => ({
      ...prev?.parents[0],
      parents: prev?.parents.slice(1)
    }))
  }, [cursor])
  const next = useCallback(
    (zone: components['schemas']['AppCSPZoneResponseDto']) => {
      setCursor((prev) => ({
        applicationProcedures: zone.applicationProcedures,
        applicationStandards: zone.applicationStandards,
        extraQuestions: zone.extraQuestions,
        id: zone.id,
        isLast: zone.isLast,
        mustSpecifyZone: zone.mustSpecifyZone,
        name: zone.name,
        parents: [
          ...(prev !== undefined
            ? [
                {
                  applicationProcedures: prev.applicationProcedures,
                  applicationStandards: prev.applicationStandards,
                  extraQuestions: prev.extraQuestions,
                  id: prev.id,
                  isLast: prev.isLast,
                  mustSpecifyZone: prev.mustSpecifyZone,
                  name: prev.name,
                  requiredDocuments: prev.requiredDocuments
                }
              ]
            : []),
          ...(prev?.parents ?? [])
        ],
        requiredDocuments: zone.requiredDocuments
      }))
    },
    [cursor]
  )

  const { data, isFetching } = useQuery({
    queryKey: ['cspZones', currentFacility?._id, cursor?.id],
    queryFn: async () =>
      await getZones({ parentZoneId: cursor?.id, page: 1, limit: 100 })
  })

  useEffect(() => {
    if (value.zone === undefined) {
      setSelecting(true)
    }
  }, [])

  return (
    <>
      <TouchableInput
        onPress={() => {
          if (value.zone !== undefined) {
            setCursor(value.zone)
          }
          setSelecting(true)
        }}
        label={t('zone')}
        placeholder={placeholder}
        errorMessage={errorMessage}
        rightIcon={<Icon name="next" size={20} />}
        value={getCursorValueString(value.zone)}
      />

      {value.specificZone !== undefined && (
        <Text variant="smallBold">
          {t('specificZone')}
          <Text
            variant="small"
            color={colors.placeholder}
          >{` ${value.specificZone}`}</Text>
        </Text>
      )}

      {value.extraQuestion !== undefined && (
        <ExtraQuestionAnswer
          question={value.extraQuestion.question}
          answer={value.extraQuestion.answer}
        />
      )}

      {selecting && (
        <SafeArea style={styles.container} edges={['top', 'right', 'left']}>
          <TopBar
            title={cursor?.name ?? t('zone')}
            leftIcon={{
              name: 'close',
              onPress: () => {
                setCursor(undefined)
                setSelecting(false)
              }
            }}
          />

          <Text style={{ marginTop: 27 }} variant="small">
            {t('selectTheWorkZone')}
          </Text>

          {!(cursor?.isLast ?? false) && (
            <>
              <ScrollView
                style={{ flex: 1 }}
                contentContainerStyle={{
                  paddingBottom: bottom + 25
                }}
              >
                {isFetching ? (
                  <ProcedureSelectSkeleton />
                ) : (
                  data?.map((zone, index, { length }) => (
                    <ListItem
                      onPress={() => {
                        next(zone)
                      }}
                      key={zone.id}
                      bottomDivider={index < length - 1}
                    >
                      <ListItem.Title>{zone.name}</ListItem.Title>

                      {
                        <Icon
                          name="next"
                          size={16}
                          color={colors.placeholder}
                        />
                      }
                    </ListItem>
                  ))
                )}
              </ScrollView>

              {cursor !== undefined && (
                <Button
                  style={styles.backButton}
                  compact
                  type="secondary"
                  onPress={back}
                  iconName={isRTL ? 'next' : 'back'}
                />
              )}
            </>
          )}

          {(cursor?.isLast ?? false) && (
            <LastZoneForm
              zone={cursor!}
              onSelect={(values) => {
                onSelect(values)
                setSelecting(false)
                setCursor(undefined)
              }}
              back={back}
            />
          )}
        </SafeArea>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999
  },
  backButton: {
    marginTop: 'auto',
    alignSelf: 'flex-start',
    marginBottom: 25
  }
})
