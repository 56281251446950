// External
import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
// Components
import { Input, StepNavigationButtons } from '@/common/components'
// Models
import type { MachineryZone } from '@/cspPermitToWork/constants'

interface Props {
  zone: MachineryZone
  onSelect: ({
    zone,
    specificZone
  }: {
    zone: MachineryZone
    specificZone?: string
  }) => void
  back: () => void
}

export const MachinerySpecificZoneForm = ({ zone, onSelect, back }: Props) => {
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      z
        .object({
          specificZone: z.string().optional()
        })
        .superRefine(({ specificZone }, ctx) => {
          if (
            zone.mustSpecifyZone &&
            (specificZone === undefined || specificZone.length === 0)
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('requiredField'),
              path: ['specificZone']
            })
          }
        }),
    [zone, t]
  )

  type FormValues = z.infer<typeof formSchema>

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema)
  })

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = form

  const onSubmit = (values: FormValues) => {
    onSelect({
      zone,
      ...values
    })
  }

  return (
    <>
      {zone.mustSpecifyZone && (
        <Controller
          control={control}
          name="specificZone"
          render={({
            field: { onBlur, onChange, value },
            fieldState: { error }
          }) => (
            <Input
              style={{ marginTop: 2 }}
              placeholder={t('specifyZoneOfMachinery')}
              errorMessage={error?.message}
              value={value}
              onChangeText={onChange}
              onBlur={onBlur}
            />
          )}
        />
      )}

      <StepNavigationButtons
        style={{ marginBottom: 25 }}
        backOnPress={back}
        nextOnPress={handleSubmit(onSubmit)}
        nextTitleTranslationKey="selectZone"
        nextDisabled={isSubmitting}
      />
    </>
  )
}
