import { z } from 'zod'
import { extraQuestionSchema } from './extraQuestionSchema'

const baseZoneSchema = z.object({
  applicationStandards: z.string().optional(),
  applicationProcedures: z.string().optional(),
  extraQuestions: z.array(extraQuestionSchema),
  id: z.string(),
  isLast: z.boolean(),
  mustSpecifyZone: z.boolean(),
  name: z.string(),
  requiredDocuments: z.string().optional()
})

export const zoneSchema = baseZoneSchema.extend({
  parents: z.array(baseZoneSchema)
})

export type Zone = z.infer<typeof zoneSchema>
