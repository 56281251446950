// External
import { type ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { type ViewStyle } from 'react-native'
// Components
import { Calendar } from './Calendar'
import { Icon } from './Icon'
import { TouchableInput } from './TouchableInput'
// Utils
import { formatDate } from '@/common/utils'
// Stores
import usePortalStore from '@/common/stores/usePortalStore'

interface Props {
  style?: ViewStyle
  label?: string
  value?: Date
  minDate?: string
  onDayPress: (data: { year: number; month: number; day: number }) => void
  onSubmitEditing?: () => void
  placeholder?: string
  searchPlaceholder?: string
  hideErrorMessage?: boolean
  errorMessage?: string
  disabled?: boolean
}

export const DateInput = forwardRef(
  (
    {
      style,
      label,
      value,
      minDate,
      onDayPress,
      onSubmitEditing,
      placeholder,
      hideErrorMessage = false,
      errorMessage,
      disabled = false
    }: Props,
    ref: ForwardedRef<unknown>
  ) => {
    const { showModal, hideModal } = usePortalStore((state) => ({
      showModal: state.showModal,
      hideModal: state.hideModal
    }))

    const openCalendar = () => {
      showModal({
        content: (
          <Calendar
            {...(minDate !== undefined && {
              minDate: new Date(minDate)
            })}
            onDayPress={(data) => {
              onDayPress(data)
              hideModal()
              if (onSubmitEditing !== undefined) {
                setTimeout(() => {
                  onSubmitEditing()
                }, 100)
              }
            }}
          />
        )
      })
    }

    useImperativeHandle(ref, () => ({
      openCalendar
    }))

    const getValue = () => {
      if (value !== undefined) {
        return formatDate(value, 'P')
      }

      return value
    }

    return (
      <TouchableInput
        style={style}
        disabled={disabled}
        onPress={openCalendar}
        label={label}
        value={getValue()}
        placeholder={placeholder}
        rightIcon={
          <Icon
            style={{
              opacity: disabled ? 0.33 : 1
            }}
            name="calendar"
            size={24}
          />
        }
        errorMessage={hideErrorMessage ? undefined : errorMessage}
      />
    )
  }
)
