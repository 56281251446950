// External
import AsyncStorage from '@react-native-async-storage/async-storage'
import { getLocales } from 'expo-localization'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// Constants
import { locales, resources } from '@/common/constants'
import { defaultLocale } from '@/common/constants/defaultLocale'
// Models
import type { StowlogLocale } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

const initialize = async () => {
  const languageCode = getLocales()[0]?.languageCode
  let lng: StowlogLocale = defaultLocale
  // ! Trying to read the locale from useAppStore.getState() won't work here
  const appStorage = await AsyncStorage.getItem('app-storage')
  const locale = JSON.parse(appStorage!)?.state.locale

  if (locale !== undefined) {
    lng = locale as StowlogLocale
  } else if (
    languageCode !== undefined &&
    languageCode !== null &&
    Object.keys(locales).includes(languageCode)
  ) {
    lng = languageCode as StowlogLocale
  }

  useAppStore.getState().setLocale(lng)
  void i18n.use(initReactI18next).init({
    resources,
    fallbackLng: defaultLocale,
    debug: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    lng
  })
}

void initialize()

export default i18n
