// External
import { useState } from 'react'
import { StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import type { ImagePickerAsset } from 'expo-image-picker'
import { SheetManager } from 'react-native-actions-sheet'
import { type StackScreenProps } from '@react-navigation/stack'
// Components
import {
  Text,
  ProgressBar,
  StepNavigationButtons,
  SelectAttachment
} from '@/common/components'
// Layouts
import { SafeArea } from '@/common/layouts'
import useCreateAccountStore from '@/auth/stores/useCreateAccountStore'
// Models
import { type PreHomeStackParamList } from '@/common/models'

type Props = StackScreenProps<PreHomeStackParamList, 'CreateAccountStep5'>

const CreateAccountStep5 = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { profileImage, setProfileImage } = useCreateAccountStore((state) => ({
    profileImage: state.profileImage,
    setProfileImage: state.setProfileImage
  }))
  const [photo, setPhoto] = useState<ImagePickerAsset | undefined>(profileImage)

  return (
    <SafeArea style={styles.container} withBackground>
      <ProgressBar style={{ marginTop: 11 }} value={0.8} />

      <Text variant="h2Bold" style={styles.header}>
        {t('chooseYourPhoto')}
      </Text>

      <Text style={{ marginBottom: 58 }}>{t('uploadYourPhoto')}</Text>

      <SelectAttachment
        photo={photo?.uri}
        onRemovePhoto={() => {
          setPhoto(undefined)
        }}
        iconName="profile-picture"
        text={t('uploadPhoto')}
        onPress={() => {
          void SheetManager.show('upload-options', {
            payload: {
              onImagePickerAsset: (asset) => {
                setPhoto(asset)
                void SheetManager.hide('upload-options')
              }
            }
          })
        }}
      />

      <StepNavigationButtons
        nextTitleTranslationKey="save"
        nextOnPress={() => {
          setProfileImage(photo as ImagePickerAsset)
          navigation.navigate('ReviewYourInformation')
        }}
      />
    </SafeArea>
  )
}

export default CreateAccountStep5

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 25,
    marginBottom: 25
  },
  header: {
    marginTop: 48,
    marginBottom: 8
  }
})
