import {
  getPermit,
  getPermitAttachments,
  getPermitSignatures
} from '@/cspPermitToWork/services'

export const getPermitDetail = async (id: string) => {
  try {
    const [detail, signatures, attachments] = await Promise.all([
      getPermit(id),
      getPermitSignatures(id),
      getPermitAttachments(id)
    ])

    return {
      detail,
      signatures,
      attachments
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}
