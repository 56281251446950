import i18n from '@/common/config/i18n'
import type { components } from '@/common/models'
import { z } from 'zod'

const stringToRegex = (regexString: string) => {
  const regexPattern = regexString.slice(1, regexString.lastIndexOf('/'))
  const regexFlags = regexString.slice(regexString.lastIndexOf('/') + 1)

  const escapedPattern = regexPattern.replace(/\\\\/g, '\\\\')

  return new RegExp(escapedPattern, regexFlags)
}

export const generateCustomFieldSchema = (
  customField:
    | components['schemas']['GetMissingCustomFieldsResponseItem']
    | components['schemas']['GetAppUserCustomFieldResponseItem']
) => {
  const { t } = i18n
  const {
    isRequired,
    regex,
    translation: { regexDescription }
  } = customField

  let schema = z.string()

  if (isRequired) {
    schema = z.string().min(1, { message: t('requiredField') })
  }

  if (regex !== undefined && regex?.toString().length > 0) {
    const compiledRegex = stringToRegex(regex)
    schema = schema.regex(compiledRegex, {
      message: regexDescription ?? t('wrongFormat')
    })
    return schema
  }

  return schema
}
