import { countries } from '@/common/constants'
import useAppStore from '@/common/stores/useAppStore'

export const getFacilityPhonePrefix = () => {
  const { currentFacility } = useAppStore()

  return countries
    .find(
      (country) =>
        country.code.toLowerCase() ===
        currentFacility?.info.isoCode.toLowerCase()
    )
    ?.dial_code.replace('+', '')
}
