// External
import { ProgressBar, StepNavigationButtons, Text } from '@/common/components'
import { zodResolver } from '@hookform/resolvers/zod'
import type { StackScreenProps } from '@react-navigation/stack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { z } from 'zod'
// Components
import { PermitRichText, ZoneSelect } from '@/cspPermitToWork/components'
// Constants
import { zoneSchema } from '@/cspPermitToWork/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
// Stores
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'

const formSchema = z.object({
  zone: zoneSchema.required(),
  specificZone: z.string().optional(),
  extraQuestion: z
    .object({
      question: z.string(),
      answer: z.string(),
      extraQuestionId: z.string()
    })
    .optional()
})

type FormValues = z.infer<typeof formSchema>

type Props = StackScreenProps<CSPPTWStackParamList, 'NewRequestStep4Zone'>

const NewRequestStep4Zone = ({ navigation }: Props) => {
  const { values: currentValues, setValues } = useNewRequestStore()
  const { t } = useTranslation()

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      extraQuestion: currentValues?.extraQuestion,
      specificZone: currentValues?.specificZone,
      zone: currentValues?.zone
    }
  })

  const { zone, specificZone, extraQuestion } = watch()

  const onSubmit = ({ extraQuestion, ...rest }: FormValues) => {
    setValues({
      ...currentValues,
      ...(extraQuestion !== undefined && {
        extraQuestion: {
          answer: extraQuestion.answer,
          question: extraQuestion.question,
          extraQuestionId: extraQuestion.extraQuestionId
        }
      }),
      ...rest
    })
    navigation.navigate('NewRequestStep5')
  }

  return (
    <SafeArea style={styles.container}>
      <ProgressBar style={styles.progressBar} value={0.4444} />

      <Text variant="h2Bold" style={{ marginBottom: 8 }}>
        {t('zone')}
      </Text>

      <Text style={{ marginBottom: 25 }}>{t('selectTheZoneAndMachinery')}</Text>

      <ZoneSelect
        placeholder={t('selectAZone')}
        errorMessage={errors.zone?.message}
        value={{
          zone,
          specificZone,
          extraQuestion
        }}
        onSelect={(value) => {
          setValue('zone', value.zone, {
            shouldDirty: true
          })
          if (value.extraQuestion !== undefined) {
            setValue('extraQuestion', value.extraQuestion, {
              shouldDirty: true
            })
          }
          if (value.specificZone !== undefined) {
            setValue('specificZone', value.specificZone, {
              shouldDirty: true
            })
          }
        }}
      />

      {zone?.applicationStandards !== undefined && (
        <PermitRichText
          style={styles.applicationStandards}
          title={t('applicationStandardsFor', {
            zone: zone.name
          })}
          html={zone.applicationStandards}
        />
      )}

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default NewRequestStep4Zone

const styles = StyleSheet.create({
  container: {
    marginBottom: 25,
    marginHorizontal: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  applicationStandards: {
    flexShrink: 1,
    marginBottom: 25
  }
})
