// External
import { createDrawerNavigator } from '@react-navigation/drawer'
import { useWindowDimensions } from 'react-native'
// Components
import { DrawerContent } from '@/common/components'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
import useRedirectNotificationsHandler from '@/notifications/hooks/useRedirectNotificationsHandler'
// Navigators
import CSPPermitToWorkStack from '@/cspPermitToWork/navigators/CSPPermitToWorkStack'
import PermitToWorkStack from '@/permitToWork/navigators/PermitToWorkStack'
import ProfileStack from '@/profile/navigators/ProfileStack'
import InductionStack from '@/safetyInduction/navigators/InductionStack'
import SuspensionStack from '@/suspension/navigators/SuspensionStack'
import VisitStack from '@/visit/navigators/VisitStack'
// Models
import type { HomeDrawerParamList } from '@/home/models'
// Views
import RestorePassword from '@/auth/views/RestorePassword'
import Settings from '@/common/views/Settings'
import Geoposition from '@/geoposition/views/Geoposition'
import Home from '@/home/views/Home'
import QRCode from '@/home/views/QRCode'
import Notifications from '@/notifications/views/Notifications'
import VirtualMap from '@/virtualMap/views/VirtualMap'
// Utils
import { getDrawerScreenOptions } from '@/common/utils'

const Drawer = createDrawerNavigator<HomeDrawerParamList>()

const HomeDrawer = () => {
  useRedirectNotificationsHandler()
  const isRTL = useIsRTL()
  const { width } = useWindowDimensions()

  return (
    <Drawer.Navigator
      initialRouteName="Home"
      // ! when backBehavior is not set to initialRoute, the app will not be able to navigate correctly when logging
      // ! into a facility from a push notification when the app is not started
      backBehavior="initialRoute"
      drawerContent={DrawerContent}
      screenOptions={{
        ...getDrawerScreenOptions(width),
        drawerPosition: isRTL ? 'right' : 'left'
      }}
    >
      <Drawer.Screen name="Home" component={Home} />

      <Drawer.Screen
        name="Settings"
        component={Settings}
        options={{ swipeEnabled: false }}
      />

      <Drawer.Screen
        name="InductionStack"
        component={InductionStack}
        options={{ unmountOnBlur: true, swipeEnabled: false }}
      />

      <Drawer.Screen
        name="QRCode"
        component={QRCode}
        options={{ swipeEnabled: false }}
      />

      <Drawer.Screen
        name="Geoposition"
        component={Geoposition}
        options={{ unmountOnBlur: true, swipeEnabled: false }}
      />

      <Drawer.Screen
        name="Notifications"
        component={Notifications}
        options={{ unmountOnBlur: true, swipeEnabled: false }}
      />

      <Drawer.Screen
        name="VirtualMap"
        component={VirtualMap}
        options={{ unmountOnBlur: true, swipeEnabled: false }}
      />

      <Drawer.Screen
        name="ProfileStack"
        component={ProfileStack}
        options={{ lazy: false, swipeEnabled: false }}
      />

      <Drawer.Screen
        name="VisitStack"
        component={VisitStack}
        options={{ unmountOnBlur: true, swipeEnabled: false }}
      />

      <Drawer.Screen
        name="PermitToWorkStack"
        component={PermitToWorkStack}
        options={{ unmountOnBlur: true, swipeEnabled: false }}
      />

      <Drawer.Screen
        name="RestorePassword"
        component={RestorePassword}
        options={{ unmountOnBlur: true, swipeEnabled: false }}
      />

      <Drawer.Screen
        name="SuspensionStack"
        component={SuspensionStack}
        options={{ unmountOnBlur: true, swipeEnabled: false }}
      />

      <Drawer.Screen
        name="CSPPermitToWorkStack"
        component={CSPPermitToWorkStack}
        options={{ unmountOnBlur: true, swipeEnabled: false }}
      />
    </Drawer.Navigator>
  )
}
export default HomeDrawer
