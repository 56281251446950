import i18n from '@/common/config/i18n'
import { AutoCompleteEnum, CustomFieldTypes } from '@/common/models'
import { z } from 'zod'

const { t } = i18n

export const CustomFieldsSchema = z
  .object({
    id: z.string(),
    ref: z.any(),
    type: z.nativeEnum(CustomFieldTypes),
    label: z.string(),
    placeholder: z.string().optional(),
    regex: z.string(),
    regexDescription: z.string().optional(),
    autoComplete: z.nativeEnum(AutoCompleteEnum).optional(),
    isRequired: z.boolean(),
    value: z.union([z.string(), z.boolean()]).optional()
  })
  .superRefine(({ value, isRequired, regex, regexDescription }, ctx) => {
    if (
      (value === '' ||
        value === undefined ||
        (typeof value === 'string' && value.length === 0)) &&
      isRequired
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('requiredField'),
        path: ['value']
      })
    }

    if (typeof value === 'string' && regex !== '') {
      const regexPattern = new RegExp(regex.replace(/^\/|\/[gimsuy]*$/g, ''))
      if (!regexPattern.test(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: regexDescription ?? t('wrongFormat'),
          path: ['value']
        })
      }
    }
  })

export const AttachmentCustomFieldsSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string().optional(),
    placeholder: z.string(),
    value: z.array(z.any()), // Adjust the schema for attachment type
    isRequired: z.boolean()
  })
  .superRefine(({ value, isRequired }, ctx) => {
    if (value.length === 0 && isRequired) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('requiredField'),
        path: ['value']
      })
    }
  })
