import { client } from '@/common/config'

interface Params {
  requestId: string
  attachmentId: string
}

export const deletePermitRequestAttachment = async ({
  requestId,
  attachmentId
}: Params) => {
  const { error } = await client.DELETE(
    '/api/app/csp-permit-to-work/requests/{requestId}/attachments/{attachmentId}',
    {
      params: {
        path: {
          requestId,
          attachmentId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error deleting permit request attachment:', error)
    return await Promise.reject(error)
  }
}
