import { apiUrl } from '@/common/constants'
import { Platform } from 'react-native'
import BackgroundGeolocation, {
  type Config
} from 'react-native-background-geolocation'
import { getUniqueIdSync } from 'react-native-device-info'

export const backgroundGeolocationConfig: Config = {
  ...(__DEV__ &&
    {
      // debug: true,
      // logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
    }),
  disableElasticity: true,
  distanceFilter: 0,
  desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
  geofenceModeHighAccuracy: true,
  disableLocationAuthorizationAlert: true,
  ...(Platform.OS === 'android' && {
    heartbeatInterval: 60
  }),
  notification: {
    title: 'Stowlog is using your location',
    text: 'You will only be tracked when inside a facility'
  },
  url: `${apiUrl}/app/geoposition/geofence/`,
  backgroundPermissionRationale: {
    title: 'Background location permission required',
    message:
      'For safety purposes we ask you to enable your location. We will use it to know your current position only within the facility; your position will be monitored in the background. You can revoke the access at any time through your profile settings'
  },
  params: {
    deviceId: getUniqueIdSync()
  },
  stopTimeout: 15,
  // ! Default is unlimited which could be causing the out of memory errors shown on Android Vitals
  maxRecordsToPersist: 100
}
