import { z } from 'zod'

export const cspManagerSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  profileImage: z.string().optional()
})

export type CSPManager = z.infer<typeof cspManagerSchema>
