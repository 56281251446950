// External
import { Divider } from '@rneui/themed'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, View } from 'react-native'
import ActionSheet, { type ActionSheetRef } from 'react-native-actions-sheet'
// Components
import { Avatar, Button, DragIndicator, Icon, Text } from '@/common/components'
import { SignPermit } from './SignPermit'
// Constants
import { colors } from '@/common/constants'
// Models
import {
  CSPPermitToWorkRequestStatusEnum,
  CSPPermitToWorkSignatureNames,
  type components
} from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePortalStore from '@/common/stores/usePortalStore'
// Utils
import { formatDate, generateImageUrl } from '@/common/utils'

interface Props {
  permit: components['schemas']['AppCSPPermitToWorkRequestDetailResponseDTO']
  signatures: Array<
    components['schemas']['CSPPermitToWorkSignatureDetailResponseDto']
  >
}

export const PermitSignatures = ({ permit, signatures }: Props) => {
  const { user, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentFacility: state.currentFacility
  }))
  const { showModal } = usePortalStore((state) => ({
    showModal: state.showModal
  }))
  const { t } = useTranslation()
  const signaturesSheetRef = useRef<ActionSheetRef>(null)

  useEffect(() => {
    signaturesSheetRef.current?.show()
  }, [])

  return (
    <ActionSheet
      ref={signaturesSheetRef}
      backgroundInteractionEnabled
      gestureEnabled
      closable={false}
      isModal={false}
      snapPoints={Platform.select({
        android: [25],
        ios: [0],
        web: [25]
      })}
      initialSnapIndex={0}
      containerStyle={styles.container}
      CustomHeaderComponent={<DragIndicator style={{ marginBottom: 25 }} />}
    >
      <Text style={styles.header} variant="baseBold">
        {t('signatures')}
      </Text>

      <Divider />

      {signatures.map((signature) => {
        let onPress: (() => void) | undefined

        const signer = signature.signers.find(
          ({ email }) => email === user?.email
        )

        const shouldSign =
          signature.name === CSPPermitToWorkSignatureNames.RP &&
          signer !== undefined &&
          (signature.signedBy === undefined || signature.signedBy === null) &&
          (permit.status === CSPPermitToWorkRequestStatusEnum.PENDING ||
            permit.status === CSPPermitToWorkRequestStatusEnum.REJECTED)

        if (shouldSign) {
          onPress = () => {
            showModal({
              content: (
                <SignPermit
                  preventiveOfficerId={signer?.id}
                  requestId={permit.id}
                  signatureId={signature.id}
                  permitSeq={permit.seq}
                />
              )
            })
          }
        }

        return (
          <View key={signature.name} style={styles.signatureContainer}>
            <Avatar
              size={40}
              borderRadius={20}
              {...(signature.signedBy?.profileImage != null && {
                source: {
                  uri: generateImageUrl(signature.signedBy.profileImage, {
                    height: 40,
                    width: 40
                  })
                }
              })}
              initials={
                signature.signedBy != null
                  ? `${signature.signedBy.firstName[0] as string}${
                      signature.signedBy.lastName[0] as string
                    }`
                  : t(
                      `cspPermitSignatureInitialsPlaceholders.${signature.name}`
                    )
              }
            />

            <View style={{ flex: 1 }}>
              <Text variant="smallBold">
                {t(`cspPermitSignatures.${signature.name}`)}
              </Text>

              <Text variant="small" color={colors.text70Percent}>
                {signature.signedBy != null
                  ? `${signature.signedBy.firstName} ${signature.signedBy.lastName}`
                  : t('pending')}
                {signature.signedAt !== null && (
                  <Text variant="small">
                    {` - ${formatDate(
                      new Date(signature.signedAt),
                      'Pp',
                      currentFacility?.info.timezone
                    )}`}
                  </Text>
                )}
              </Text>
            </View>

            {onPress !== undefined && (
              <Button
                onPress={onPress}
                title={t('sign')}
                type="secondary"
                compact
              />
            )}

            {onPress == null && (
              <Icon
                style={{ marginStart: 'auto' }}
                name={signature.signedAt !== null ? 'check-fill' : 'alert-fill'}
                color={
                  signature.signedAt !== null ? colors.success : colors.rajah
                }
                size={32}
              />
            )}
          </View>
        )
      })}
    </ActionSheet>
  )
}

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    paddingTop: 10,
    paddingHorizontal: 25,
    paddingBottom: 13.5,
    backgroundColor: '#FAFAFA'
  },
  header: {
    marginBottom: 25,
    textAlign: 'center'
  },
  signatureContainer: {
    flexDirection: 'row',
    gap: 15,
    padding: 11.5,
    alignItems: 'center'
  }
})
