// External
import { zodResolver } from '@hookform/resolvers/zod'
import type { StackScreenProps } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { z } from 'zod'
// Components
import {
  Input,
  PhoneNumberInput,
  ProgressBar,
  Select,
  StepNavigationButtons,
  Text
} from '@/common/components/'
import { ContractorCompanySelect } from '@/cspPermitToWork/components'
// Constants
import { phoneNumberRegEx } from '@/common/constants'
import { companySchema, cspManagerSchema } from '@/cspPermitToWork/constants'
// Hooks
import usePreventGoingBack from '@/common/hooks/usePreventGoingBack'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
// Services
import { getCSPManagers } from '@/cspPermitToWork/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'
// Utils
import { getFacilityPhonePrefix } from '@/common/utils'

type Props = StackScreenProps<CSPPTWStackParamList, 'NewRequestStep1'>

const NewRequestStep1 = ({ navigation }: Props) => {
  const { user, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentFacility: state.currentFacility
  }))
  const { values: currentValues, setValues } = useNewRequestStore()
  const { t } = useTranslation()
  usePreventGoingBack({
    condition: currentValues !== undefined,
    onLeave: () => {
      setValues(undefined)
    }
  })

  const formSchema = useMemo(
    () =>
      z.object({
        company: companySchema,
        preventiveOfficerPhone: z.object({
          prefix: z.string(),
          phone: z
            .string()
            .nonempty({ message: t('provideMobileNumber') })
            .regex(phoneNumberRegEx, {
              message: t('phoneNumberRegExError')
            })
        }),
        cspManager: cspManagerSchema
      }),
    [t]
  )

  type FormValues = z.infer<typeof formSchema>

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      company: currentValues?.company,
      preventiveOfficerPhone:
        currentValues?.preventiveOfficerPhone !== undefined
          ? {
              prefix: String(currentValues?.preventiveOfficerPhone.prefix),
              phone: currentValues?.preventiveOfficerPhone.phone
            }
          : {
              prefix:
                user?.phoneNumber?.prefix !== undefined
                  ? String(user.phoneNumber.prefix)
                  : getFacilityPhonePrefix(),
              phone: user?.phoneNumber?.phone ?? ''
            },
      cspManager: currentValues?.cspManager
    }
  })

  const preventiveOfficerPhone = watch('preventiveOfficerPhone')

  const onSubmit = ({ preventiveOfficerPhone, ...rest }: FormValues) => {
    setValues({
      ...currentValues,
      ...rest,
      preventiveOfficerPhone: {
        prefix: parseInt(preventiveOfficerPhone.prefix),
        phone: preventiveOfficerPhone.phone
      }
    })
    navigation.navigate('NewRequestStep2')
  }

  const { data } = useQuery({
    queryKey: ['cspManagers', currentFacility?._id],
    queryFn: getCSPManagers
  })

  return (
    <SafeArea style={styles.container} withBackground>
      <ProgressBar style={{ marginTop: 11 }} value={0.1111} />

      <Text variant="h2Bold" style={styles.header}>
        {t('createNewRequest')}
      </Text>

      <Text style={{ marginBottom: 25 }}>{t('generalInformation')}</Text>

      <Controller
        control={control}
        name="company"
        render={({ field: { onChange, onBlur, value } }) => (
          <ContractorCompanySelect
            label={t('company')}
            placeholder={t('selectCompany')}
            searchPlaceholder={t('searchCompany')}
            onSelect={({ original }) => {
              onChange({
                _id: original?._id,
                uuid: original?.uuid,
                name: original?.name
              })
              onBlur()
            }}
            errorMessage={errors.company?.message}
            noResultsTranslationKey="noCompaniesFound"
            value={value?._id}
          />
        )}
      />

      <Input disabled label={t('preventiveOfficer')} value={user?.fullName} />

      <PhoneNumberInput
        labelTranslationKey="preventiveOfficerPhone"
        prefixValue={preventiveOfficerPhone.prefix}
        value={preventiveOfficerPhone.phone}
        onChangePrefix={(option) => {
          setValue('preventiveOfficerPhone.prefix', option.value, {
            shouldDirty: true
          })
        }}
        onChangePhone={(phone) => {
          setValue('preventiveOfficerPhone.phone', phone, {
            shouldDirty: true
          })
        }}
        errorMessage={
          errors.preventiveOfficerPhone?.message ??
          errors.preventiveOfficerPhone?.phone?.message
        }
      />

      <Controller
        control={control}
        name="cspManager"
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            searchable
            label={t('cspManager')}
            placeholder={t('selectCSPManager')}
            searchPlaceholder={t('searchCSPManager')}
            onSelect={({ original }) => {
              onChange(original)
              onBlur()
            }}
            errorMessage={errors.cspManager?.message}
            options={
              data !== undefined
                ? data.map((cspManager) => ({
                    label: `${cspManager.firstName} ${cspManager.lastName}`,
                    value: cspManager.id,
                    original: cspManager
                  }))
                : []
            }
            noResultsTranslationKey="noCSPManagersFound"
            value={value?.id}
          />
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default NewRequestStep1

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 25,
    marginBottom: 25
  },
  header: {
    marginTop: 48,
    marginBottom: 8
  }
})
