// TODO - Improve this / automate it somehow
// ar
import authAr from '@locales/auth/ar.json'
import ar from '@locales/common/ar.json'
import cspPermitToWorkAr from '@locales/cspPermitToWork/ar.json'
import findFacilityAr from '@locales/findFacility/ar.json'
import geopositionAr from '@locales/geoposition/ar.json'
import homeAr from '@locales/home/ar.json'
import notificationsAr from '@locales/notifications/ar.json'
import permissionsAr from '@locales/permissions/ar.json'
import permitToWorkAr from '@locales/permitToWork/ar.json'
import profileAr from '@locales/profile/ar.json'
import safetyInductionAr from '@locales/safetyInduction/ar.json'
import suspensionAr from '@locales/suspension/ar.json'
import visitAr from '@locales/visit/ar.json'
// ca
import authCaES from '@locales/auth/ca.json'
import caES from '@locales/common/ca.json'
import cspPermitToWorkCaES from '@locales/cspPermitToWork/ca.json'
import findFacilityCaES from '@locales/findFacility/ca.json'
import geopositionCaEs from '@locales/geoposition/ca.json'
import homeCaES from '@locales/home/ca.json'
import notificationsCaES from '@locales/notifications/ca.json'
import permissionsCaES from '@locales/permissions/ca.json'
import permitToWorkCaES from '@locales/permitToWork/ca.json'
import profileCaES from '@locales/profile/ca.json'
import safetyInductionCaES from '@locales/safetyInduction/ca.json'
import suspensionCaES from '@locales/suspension/ca.json'
import visitCaES from '@locales/visit/ca.json'
// de
import authDe from '@locales/auth/de.json'
import de from '@locales/common/de.json'
import cspPermitToWorkDe from '@locales/cspPermitToWork/de.json'
import findFacilityDe from '@locales/findFacility/de.json'
import homeDe from '@locales/home/de.json'
import notificationsDe from '@locales/notifications/de.json'
import permissionsDe from '@locales/permissions/de.json'
import permitToWorkDe from '@locales/permitToWork/de.json'
import profileDe from '@locales/profile/de.json'
import safetyInductionDe from '@locales/safetyInduction/de.json'
import suspensionDe from '@locales/suspension/de.json'
import visitDe from '@locales/visit/de.json'
// en-GB
import authEnGB from '@locales/auth/en-GB.json'
import enGB from '@locales/common/en-GB.json'
import cspPermitToWorkEnGB from '@locales/cspPermitToWork/en-GB.json'
import findFacilityEnGB from '@locales/findFacility/en-GB.json'
import geopositionEnGB from '@locales/geoposition/en-GB.json'
import homeEnGB from '@locales/home/en-GB.json'
import notificationsEnGB from '@locales/notifications/en-GB.json'
import permissionsEnGB from '@locales/permissions/en-GB.json'
import permitToWorkEnGB from '@locales/permitToWork/en-GB.json'
import profileEnGB from '@locales/profile/en-GB.json'
import safetyInductionEnGB from '@locales/safetyInduction/en-GB.json'
import suspensionEnGB from '@locales/suspension/en-GB.json'
import visitEnGB from '@locales/visit/en-GB.json'
// en-US
import authEnUS from '@locales/auth/en-US.json'
import enUS from '@locales/common/en-US.json'
import cspPermitToWorkEnUS from '@locales/cspPermitToWork/en-US.json'
import findFacilityEnUS from '@locales/findFacility/en-US.json'
import geopositionEnUS from '@locales/geoposition/en-US.json'
import homeEnUS from '@locales/home/en-US.json'
import notificationsEnUS from '@locales/notifications/en-US.json'
import permissionsEnUS from '@locales/permissions/en-US.json'
import permitToWorkEnUS from '@locales/permitToWork/en-US.json'
import profileEnUS from '@locales/profile/en-US.json'
import safetyInductionEnUS from '@locales/safetyInduction/en-US.json'
import suspensionEnUS from '@locales/suspension/en-US.json'
import visitEnUS from '@locales/visit/en-US.json'
// es
import authEs from '@locales/auth/es.json'
import es from '@locales/common/es.json'
import cspPermitToWorkEs from '@locales/cspPermitToWork/es.json'
import findFacilityEs from '@locales/findFacility/es.json'
import geopositionEs from '@locales/geoposition/es.json'
import homeEs from '@locales/home/es.json'
import notificationsEs from '@locales/notifications/es.json'
import permissionsEs from '@locales/permissions/es.json'
import permitToWorkEs from '@locales/permitToWork/es.json'
import profileEs from '@locales/profile/es.json'
import safetyInductionEs from '@locales/safetyInduction/es.json'
import suspensionEs from '@locales/suspension/es.json'
import visitEs from '@locales/visit/es.json'
// fr
import authFr from '@locales/auth/fr.json'
import fr from '@locales/common/fr.json'
import cspPermitToWorkFr from '@locales/cspPermitToWork/fr.json'
import findFacilityFr from '@locales/findFacility/fr.json'
import geopositionFr from '@locales/geoposition/fr.json'
import homeFr from '@locales/home/fr.json'
import notificationsFr from '@locales/notifications/fr.json'
import permissionsFr from '@locales/permissions/fr.json'
import permitToWorkFr from '@locales/permitToWork/fr.json'
import profileFr from '@locales/profile/fr.json'
import safetyInductionFr from '@locales/safetyInduction/fr.json'
import suspensionFr from '@locales/suspension/fr.json'
import visitFr from '@locales/visit/fr.json'
// hr
import authHr from '@locales/auth/hr.json'
import hr from '@locales/common/hr.json'
import cspPermitToWorkHr from '@locales/cspPermitToWork/hr.json'
import findFacilityHr from '@locales/findFacility/hr.json'
import geopositionHr from '@locales/geoposition/hr.json'
import homeHr from '@locales/home/hr.json'
import notificationsHr from '@locales/notifications/hr.json'
import permissionsHr from '@locales/permissions/hr.json'
import permitToWorkHr from '@locales/permitToWork/hr.json'
import profileHr from '@locales/profile/hr.json'
import safetyInductionHr from '@locales/safetyInduction/hr.json'
import suspensionHr from '@locales/suspension/hr.json'
import visitHr from '@locales/visit/hr.json'
// hu
import authHu from '@locales/auth/hu.json'
import hu from '@locales/common/hu.json'
import cspPermitToWorkHu from '@locales/cspPermitToWork/hu.json'
import findFacilityHu from '@locales/findFacility/hu.json'
import geopositionHu from '@locales/geoposition/hu.json'
import homeHu from '@locales/home/hu.json'
import notificationsHu from '@locales/notifications/hu.json'
import permissionsHu from '@locales/permissions/hu.json'
import permitToWorkHu from '@locales/permitToWork/hu.json'
import profileHu from '@locales/profile/hu.json'
import safetyInductionHu from '@locales/safetyInduction/hu.json'
import suspensionHu from '@locales/suspension/hu.json'
import visitHu from '@locales/visit/hu.json'
// it
import authIt from '@locales/auth/it.json'
import it from '@locales/common/it.json'
import cspPermitToWorkIt from '@locales/cspPermitToWork/it.json'
import findFacilityIt from '@locales/findFacility/it.json'
import geopositionIt from '@locales/geoposition/it.json'
import homeIt from '@locales/home/it.json'
import notificationsIt from '@locales/notifications/it.json'
import permissionsIt from '@locales/permissions/it.json'
import permitToWorkIt from '@locales/permitToWork/it.json'
import profileIt from '@locales/profile/it.json'
import safetyInductionIt from '@locales/safetyInduction/it.json'
import suspensionIt from '@locales/suspension/it.json'
import visitIt from '@locales/visit/it.json'
// pl
import authPl from '@locales/auth/pl.json'
import pl from '@locales/common/pl.json'
import cspPermitToWorkPl from '@locales/cspPermitToWork/pl.json'
import findFacilityPl from '@locales/findFacility/pl.json'
import geopositionPl from '@locales/geoposition/pl.json'
import homePl from '@locales/home/pl.json'
import notificationsPl from '@locales/notifications/pl.json'
import permissionsPl from '@locales/permissions/pl.json'
import permitToWorkPl from '@locales/permitToWork/pl.json'
import profilePl from '@locales/profile/pl.json'
import safetyInductionPl from '@locales/safetyInduction/pl.json'
import suspensionPl from '@locales/suspension/pl.json'
import visitPl from '@locales/visit/pl.json'
// sv
import authSv from '@locales/auth/sv.json'
import sv from '@locales/common/sv.json'
import cspPermitToWorkSv from '@locales/cspPermitToWork/sv.json'
import findFacilitySv from '@locales/findFacility/sv.json'
import geopositionSv from '@locales/geoposition/sv.json'
import homeSv from '@locales/home/sv.json'
import notificationsSv from '@locales/notifications/sv.json'
import permissionsSv from '@locales/permissions/sv.json'
import permitToWorkSv from '@locales/permitToWork/sv.json'
import profileSv from '@locales/profile/sv.json'
import safetyInductionSv from '@locales/safetyInduction/sv.json'
import suspensionSv from '@locales/suspension/sv.json'
import visitSv from '@locales/visit/sv.json'
// ro
import authRo from '@locales/auth/ro.json'
import ro from '@locales/common/ro.json'
import cspPermitToWorkRo from '@locales/cspPermitToWork/ro.json'
import findFacilityRo from '@locales/findFacility/ro.json'
import geopositionRo from '@locales/geoposition/ro.json'
import homeRo from '@locales/home/ro.json'
import notificationsRo from '@locales/notifications/ro.json'
import permissionsRo from '@locales/permissions/ro.json'
import permitToWorkRo from '@locales/permitToWork/ro.json'
import profileRo from '@locales/profile/ro.json'
import safetyInductionRo from '@locales/safetyInduction/ro.json'
import suspensionRo from '@locales/suspension/ro.json'
import visitRo from '@locales/visit/ro.json'
// hi
import authHi from '@locales/auth/hi.json'
import commonHi from '@locales/common/hi.json'
import cspPermitToWorkHi from '@locales/cspPermitToWork/hi.json'
import findFacilityHi from '@locales/findFacility/hi.json'
import geopositionHi from '@locales/geoposition/hi.json'
import homeHi from '@locales/home/hi.json'
import notificationsHi from '@locales/notifications/hi.json'
import permissionsHi from '@locales/permissions/hi.json'
import permitToWorkHi from '@locales/permitToWork/hi.json'
import profileHi from '@locales/profile/hi.json'
import safetyInductionHi from '@locales/safetyInduction/hi.json'
import suspensionHi from '@locales/suspension/hi.json'
import visitHi from '@locales/visit/hi.json'
// ur
import authUr from '@locales/auth/ur.json'
import commonUr from '@locales/common/ur.json'
import cspPermitToWorkUr from '@locales/cspPermitToWork/ur.json'
import findFacilityUr from '@locales/findFacility/ur.json'
import geopositionUr from '@locales/geoposition/ur.json'
import homeUr from '@locales/home/ur.json'
import notificationsUr from '@locales/notifications/ur.json'
import permissionsUr from '@locales/permissions/ur.json'
import permitToWorkUr from '@locales/permitToWork/ur.json'
import profileUr from '@locales/profile/ur.json'
import safetyInductionUr from '@locales/safetyInduction/ur.json'
import suspensionUr from '@locales/suspension/ur.json'
import visitUr from '@locales/visit/ur.json'

export const resources = {
  ar: {
    translation: {
      ...ar,
      ...authAr,
      ...permissionsAr,
      ...findFacilityAr,
      ...homeAr,
      ...safetyInductionAr,
      ...notificationsAr,
      ...profileAr,
      ...findFacilityAr,
      ...visitAr,
      ...permitToWorkAr,
      ...suspensionAr,
      ...geopositionAr,
      ...cspPermitToWorkAr
    }
  },
  ca: {
    translation: {
      ...caES,
      ...authCaES,
      ...permissionsCaES,
      ...findFacilityCaES,
      ...homeCaES,
      ...safetyInductionCaES,
      ...notificationsCaES,
      ...profileCaES,
      ...visitCaES,
      ...permitToWorkCaES,
      ...suspensionCaES,
      ...geopositionCaEs,
      ...cspPermitToWorkCaES
    }
  },
  de: {
    translation: {
      ...de,
      ...authDe,
      ...permissionsDe,
      ...findFacilityDe,
      ...homeDe,
      ...safetyInductionDe,
      ...notificationsDe,
      ...profileDe,
      ...visitDe,
      ...permitToWorkDe,
      ...suspensionDe,
      ...cspPermitToWorkDe
    }
  },
  'en-GB': {
    translation: {
      ...enGB,
      ...authEnGB,
      ...permissionsEnGB,
      ...findFacilityEnGB,
      ...homeEnGB,
      ...safetyInductionEnGB,
      ...notificationsEnGB,
      ...profileEnGB,
      ...visitEnGB,
      ...permitToWorkEnGB,
      ...suspensionEnGB,
      ...geopositionEnGB,
      ...cspPermitToWorkEnGB
    }
  },
  'en-US': {
    translation: {
      ...enUS,
      ...authEnUS,
      ...permissionsEnUS,
      ...findFacilityEnUS,
      ...homeEnUS,
      ...safetyInductionEnUS,
      ...notificationsEnUS,
      ...profileEnUS,
      ...visitEnUS,
      ...permitToWorkEnUS,
      ...suspensionEnUS,
      ...geopositionEnUS,
      ...cspPermitToWorkEnUS
    }
  },
  es: {
    translation: {
      ...es,
      ...authEs,
      ...permissionsEs,
      ...findFacilityEs,
      ...homeEs,
      ...safetyInductionEs,
      ...notificationsEs,
      ...profileEs,
      ...visitEs,
      ...permitToWorkEs,
      ...suspensionEs,
      ...geopositionEs,
      ...cspPermitToWorkEs
    }
  },
  fr: {
    translation: {
      ...fr,
      ...authFr,
      ...permissionsFr,
      ...findFacilityFr,
      ...homeFr,
      ...safetyInductionFr,
      ...notificationsFr,
      ...profileFr,
      ...visitFr,
      ...permitToWorkFr,
      ...suspensionFr,
      ...geopositionFr,
      ...cspPermitToWorkFr
    }
  },
  hr: {
    translation: {
      ...hr,
      ...authHr,
      ...permissionsHr,
      ...findFacilityHr,
      ...homeHr,
      ...safetyInductionHr,
      ...notificationsHr,
      ...profileHr,
      ...visitHr,
      ...permitToWorkHr,
      ...suspensionHr,
      ...geopositionHr,
      ...cspPermitToWorkHr
    }
  },
  hu: {
    translation: {
      ...hu,
      ...authHu,
      ...permissionsHu,
      ...findFacilityHu,
      ...homeHu,
      ...safetyInductionHu,
      ...notificationsHu,
      ...profileHu,
      ...visitHu,
      ...permitToWorkHu,
      ...suspensionHu,
      ...geopositionHu,
      ...cspPermitToWorkHu
    }
  },
  it: {
    translation: {
      ...it,
      ...authIt,
      ...permissionsIt,
      ...findFacilityIt,
      ...homeIt,
      ...safetyInductionIt,
      ...notificationsIt,
      ...profileIt,
      ...visitIt,
      ...permitToWorkIt,
      ...suspensionIt,
      ...geopositionIt,
      ...cspPermitToWorkIt
    }
  },
  pl: {
    translation: {
      ...pl,
      ...authPl,
      ...permissionsPl,
      ...findFacilityPl,
      ...homePl,
      ...safetyInductionPl,
      ...notificationsPl,
      ...profilePl,
      ...visitPl,
      ...permitToWorkPl,
      ...suspensionPl,
      ...geopositionPl,
      ...cspPermitToWorkPl
    }
  },
  sv: {
    translation: {
      ...sv,
      ...authSv,
      ...permissionsSv,
      ...findFacilitySv,
      ...homeSv,
      ...safetyInductionSv,
      ...notificationsSv,
      ...profileSv,
      ...visitSv,
      ...permitToWorkSv,
      ...suspensionSv,
      ...geopositionSv,
      ...cspPermitToWorkSv
    }
  },
  ro: {
    translation: {
      ...ro,
      ...authRo,
      ...permissionsRo,
      ...findFacilityRo,
      ...homeRo,
      ...safetyInductionRo,
      ...notificationsRo,
      ...profileRo,
      ...visitRo,
      ...permitToWorkRo,
      ...suspensionRo,
      ...geopositionRo,
      ...cspPermitToWorkRo
    }
  },
  hi: {
    translation: {
      ...commonHi,
      ...authHi,
      ...permissionsHi,
      ...findFacilityHi,
      ...homeHi,
      ...safetyInductionHi,
      ...notificationsHi,
      ...profileHi,
      ...visitHi,
      ...permitToWorkHi,
      ...suspensionHi,
      ...geopositionHi,
      ...cspPermitToWorkHi
    }
  },
  ur: {
    translation: {
      ...commonUr,
      ...authUr,
      ...permissionsUr,
      ...findFacilityUr,
      ...homeUr,
      ...safetyInductionUr,
      ...notificationsUr,
      ...profileUr,
      ...visitUr,
      ...permitToWorkUr,
      ...suspensionUr,
      ...geopositionUr,
      ...cspPermitToWorkUr
    }
  }
}
