// External
import { zodResolver } from '@hookform/resolvers/zod'
import type { StackScreenProps } from '@react-navigation/stack'
import {
  addMilliseconds,
  addMinutes,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isSameDay
} from 'date-fns'
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz'
import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { z } from 'zod'
// Components
import {
  DateInput,
  ProgressBar,
  Select,
  StepNavigationButtons,
  Text
} from '@/common/components'
// Constants
import { checkInTimeValues } from '@/visit/constants'
// Models
import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'
// Layouts
import { SafeArea } from '@/common/layouts'
// Utils
import { getCheckInTimeValues } from '@/visit/utils'

type Props = StackScreenProps<CSPPTWStackParamList, 'NewRequestStep2'>

const NewRequestStep2 = ({ navigation }: Props) => {
  const { values: currentValues, setValues } = useNewRequestStore()
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const getSelectValue = useCallback((value: Date) => {
    const base = addMilliseconds(
      value,
      getTimezoneOffset(currentFacility?.info.timezone as string)
    )
    const hours = base.getUTCHours()
    const minutes = base.getUTCMinutes()
    return (hours * 60 + minutes).toString()
  }, [])

  const formSchema = useMemo(
    () =>
      z
        .object({
          startDate: z
            .date({ message: t('requiredField') })
            .min(new Date(), { message: t('startDateCannotBeInPast') }),
          endDate: z.date({ message: t('requiredField') })
        })
        .refine(
          (data) => {
            if (data.startDate === undefined || data.endDate === undefined) {
              return true
            }
            return data.endDate >= addMinutes(data.startDate, 15)
          },
          {
            message: t('endDateMustBeAtLeast'),
            path: ['endDate']
          }
        )
        .refine(
          (data) => {
            if (data.startDate === undefined || data.endDate === undefined) {
              return true
            }
            const hoursDiff =
              (data.endDate.getTime() - data.startDate.getTime()) /
              (1000 * 60 * 60)
            return hoursDiff <= 24
          },
          {
            message: t('endDateCannotBeMore'),
            path: ['endDate']
          }
        ),
    [t]
  )

  type FormValues = z.infer<typeof formSchema>

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      endDate: currentValues?.endDate,
      startDate: currentValues?.startDate
    }
  })

  const onSubmit = (values: FormValues) => {
    setValues({
      ...currentValues,
      ...values
    })
    navigation.navigate('NewRequestStep3')
  }

  const getOnDayPressDate = useCallback(
    (data: { year: number; month: number; day: number }, timezone: string) => {
      const baseDate = zonedTimeToUtc(
        new Date(data.year, data.month - 1, data.day),
        timezone
      )
      const now = new Date()

      if (isSameDay(baseDate, now)) {
        const totalMinutes = getMinutes(now) + getHours(now) * 60

        if (totalMinutes <= 8 * 60) {
          return addMinutes(baseDate, 8 * 60)
        } else {
          const checkInTimeValue = checkInTimeValues.find(
            ({ value }) => parseInt(value) >= totalMinutes
          )
          if (checkInTimeValue !== undefined) {
            return addMinutes(baseDate, parseInt(checkInTimeValue.value))
          }
        }
      } else {
        return addMinutes(baseDate, 8 * 60)
      }
    },
    []
  )

  return (
    <SafeArea style={styles.container}>
      <ProgressBar style={styles.progressBar} value={0.2222} />

      <Text variant="h2Bold" style={{ marginBottom: 8 }}>
        {t('dateAndTime')}
      </Text>

      <Text style={{ marginBottom: 25 }}>
        {t('selectTheStartAndEndDatesAndTimes')}
      </Text>

      <Controller
        control={control}
        name="startDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <View>
            <DateInput
              label={t('startDate')}
              value={value}
              minDate={new Date().toDateString()}
              onDayPress={(data) => {
                const date = getOnDayPressDate(
                  data,
                  currentFacility?.info.timezone as string
                )
                onChange(date)
                onBlur()
              }}
              placeholder={t('selectADate')}
              errorMessage={errors.startDate?.message}
            />

            <Select
              value={getSelectValue(value)}
              disabled={value === undefined}
              label={t('startTime')}
              options={getCheckInTimeValues(isSameDay(value, new Date()))}
              onSelect={(option) => {
                const baseDate = zonedTimeToUtc(
                  new Date(getYear(value), getMonth(value), getDate(value)),
                  currentFacility?.info.timezone as string
                )
                onChange(addMinutes(baseDate, parseInt(option.value)))
                onBlur()
              }}
              placeholder={t('selectATime')}
              errorMessage={errors.startDate?.message}
            />
          </View>
        )}
      />

      <Controller
        control={control}
        name="endDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <View>
            <DateInput
              label={t('endDate')}
              disabled={watch('startDate') === undefined}
              value={value}
              minDate={new Date().toDateString()}
              onDayPress={(data) => {
                const date = getOnDayPressDate(
                  data,
                  currentFacility?.info.timezone as string
                )
                onChange(date)
                onBlur()
              }}
              placeholder={t('selectADate')}
              errorMessage={errors.endDate?.message}
            />

            <Select
              {...(value !== undefined && {
                value: getSelectValue(value)
              })}
              disabled={value === undefined}
              label={t('endTime')}
              options={
                value !== undefined
                  ? getCheckInTimeValues(isSameDay(value, new Date()))
                  : []
              }
              onSelect={(option) => {
                if (value === undefined) {
                  return
                }

                const baseDate = zonedTimeToUtc(
                  new Date(getYear(value), getMonth(value), getDate(value)),
                  currentFacility?.info.timezone as string
                )
                onChange(addMinutes(baseDate, parseInt(option.value)))
                onBlur()
              }}
              placeholder={t('selectATime')}
              errorMessage={errors.endDate?.message}
            />
          </View>
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default NewRequestStep2

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  }
})
