// External
import { ListItem } from '@rneui/themed'
import { useQuery } from '@tanstack/react-query'
import {
  type ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, type TouchableOpacity } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { Button, Icon, Link, Text, TopBar } from '@/common/components'
import { ProcedureSelectSkeleton } from '../ProcedureSelectSkeleton'
import { LastAdditionalEquipmentForm } from './LastAdditionalEquipmentForm'
// Constants
import { colors } from '@/common/constants'
import type { Contractor, Equipment } from '@/cspPermitToWork/constants'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Layouts
import { SafeArea } from '@/common/layouts'
// Mdoels
import type { components } from '@/common/models'
// Services
import { getAdditionalEquipment } from '@/cspPermitToWork/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import { cloneDeep } from 'lodash'

interface Props {
  numberSelected?: number
  onSelect: (values: { equipment: Equipment; worker?: Contractor }) => void
  onEdit: (
    index: number,
    values: { equipment: Equipment; worker?: Contractor }
  ) => void
}

export const AdditionalEquipmentSelect = forwardRef(
  (
    { numberSelected, onSelect, onEdit }: Props,
    ref: ForwardedRef<typeof TouchableOpacity>
  ) => {
    const { currentFacility } = useAppStore((state) => ({
      currentFacility: state.currentFacility
    }))
    const [cursor, setCursor] = useState<Equipment>()
    const [selecting, setSelecting] = useState(false)
    const [editing, setEditing] = useState<number>()
    const { t } = useTranslation()
    const { bottom } = useSafeAreaInsets()
    const isRTL = useIsRTL()

    const back = useCallback(() => {
      // the function is only available when cursor is defined
      if (cursor!.parents.length === 0) {
        setCursor(undefined)
        return
      }

      // @ts-expect-error - it only gets here when there's at least one parent
      setCursor((prev) => ({
        ...prev?.parents[0],
        parents: prev?.parents.slice(1)
      }))
    }, [cursor])
    const next = useCallback(
      (
        equipment: components['schemas']['AppCSPAdditionalEquipmentResponseDto']
      ) => {
        const currentCursor = cloneDeep(cursor)
        const newCursor = {
          applicationProcedures: equipment.applicationProcedures,
          applicationStandards: equipment.applicationStandards,
          id: equipment.id,
          isLast: equipment.isLast,
          mustSelectWorker: equipment.mustSelectWorker,
          name: equipment.name,
          parents: [
            ...(currentCursor !== undefined
              ? [
                  {
                    applicationProcedures: currentCursor.applicationProcedures,
                    applicationStandards: currentCursor.applicationStandards,
                    id: currentCursor.id,
                    isLast: currentCursor.isLast,
                    mustSelectWorker: currentCursor.mustSelectWorker,
                    name: currentCursor.name,
                    requiredDocuments: currentCursor.requiredDocuments
                  }
                ]
              : []),
            ...(currentCursor?.parents ?? [])
          ],
          requiredDocuments: equipment.requiredDocuments
        }

        if (
          (newCursor?.isLast ?? false) &&
          !(newCursor?.mustSelectWorker ?? false) &&
          newCursor.applicationStandards?.length === 0
        ) {
          if (selecting && editing === undefined) {
            onSelect({
              equipment: newCursor
            })
            setSelecting(false)
          } else if (typeof editing === 'number') {
            onEdit(editing, {
              equipment: newCursor
            })
            setEditing(undefined)
          }
          setCursor(undefined)
          return
        }

        setCursor(newCursor)
      },
      [cursor]
    )

    const { data, isFetching } = useQuery({
      queryKey: ['cspAdditionalEquipment', currentFacility?._id, cursor?.id],
      queryFn: async () =>
        await getAdditionalEquipment({
          parentAdditionalEquipmentId: cursor?.id,
          page: 1
        })
    })

    const edit = useCallback(
      (params: { cursor: Equipment; index: number }) => {
        setCursor(params.cursor)
        setEditing(params.index)
      },
      [data]
    )

    // @ts-expect-error TODO - correct typing
    useImperativeHandle(ref, () => ({
      edit: (params: { cursor: Equipment; index: number }) => {
        edit(params)
      }
    }))

    useEffect(() => {
      if (numberSelected === 0) {
        setSelecting(true)
      }
    }, [])

    return (
      <>
        <Link
          ref={ref}
          style={{ alignSelf: 'flex-end' }}
          icon={{
            name: 'add-file'
          }}
          onPress={() => {
            setSelecting(true)
          }}
        >
          {t(numberSelected === 0 ? 'addEquipment' : 'addAnotherEquipment')}
        </Link>

        {(selecting || editing !== undefined) && (
          <SafeArea style={styles.container} edges={['top', 'right', 'left']}>
            <TopBar
              title={cursor?.name ?? t('additionalEquipment')}
              leftIcon={{
                name: 'close',
                onPress: () => {
                  setCursor(undefined)
                  setSelecting(false)
                  setEditing(undefined)
                }
              }}
            />

            {!(cursor?.isLast ?? false) && (
              <>
                <ScrollView
                  bounces={false}
                  style={{ flex: 1 }}
                  contentContainerStyle={{
                    paddingBottom: bottom + 25
                  }}
                >
                  <>
                    <Text style={{ marginTop: 27 }} variant="small">
                      {t('selectTheAdditionalEquipment')}
                    </Text>

                    {isFetching ? (
                      <ProcedureSelectSkeleton />
                    ) : (
                      data?.map((equipment, index, { length }) => (
                        <ListItem
                          onPress={() => {
                            next(equipment)
                          }}
                          key={equipment.id}
                          bottomDivider={index < length - 1}
                        >
                          <ListItem.Title>{equipment.name}</ListItem.Title>

                          {
                            <Icon
                              name="next"
                              size={16}
                              color={colors.placeholder}
                            />
                          }
                        </ListItem>
                      ))
                    )}
                  </>
                </ScrollView>

                {cursor !== undefined && (
                  <Button
                    style={styles.backButton}
                    compact
                    type="secondary"
                    onPress={back}
                    iconName={isRTL ? 'next' : 'back'}
                  />
                )}
              </>
            )}

            {(cursor?.isLast ?? false) && (
              <LastAdditionalEquipmentForm
                cursor={cursor!}
                onSubmit={(values) => {
                  if (selecting && editing === undefined) {
                    onSelect(values)
                    setSelecting(false)
                  } else if (typeof editing === 'number') {
                    onEdit(editing, values)
                    setEditing(undefined)
                  }
                  setCursor(undefined)
                }}
                back={back}
              />
            )}
          </SafeArea>
        )}
      </>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999
  },
  backButton: {
    marginTop: 'auto',
    alignSelf: 'flex-start',
    marginBottom: 25
  }
})
