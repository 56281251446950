import { client } from '@/common/config'

export const getPermitSignatures = async (requestId: string) => {
  const { data, error } = await client.GET(
    '/api/app/csp-permit-to-work/requests/signatures/{requestId}',
    {
      params: {
        path: {
          requestId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error("Error getting permit's signatures:", error)
    return await Promise.reject(error)
  }

  return data.data
}
