// External
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
// Components
import { Button, Text } from '@/common/components'
// Constants
import { toast } from '@/common/constants'
// Services
import { signPermit as signCSPPermit } from '@/cspPermitToWork/services'
// Stores
import usePortalStore from '@/common/stores/usePortalStore'
// Utils
import { handleError } from '@/common/utils'

interface Props {
  preventiveOfficerId: string
  requestId: string
  signatureId: string
  permitSeq: number
}

export const SignPermit = ({ permitSeq, ...rest }: Props) => {
  const { hideModal } = usePortalStore((state) => ({
    hideModal: state.hideModal
  }))
  const [signing, setSigning] = useState(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return (
    <>
      <Text variant="baseBold">
        {t('signPermit', {
          seq: permitSeq
        })}
      </Text>

      <Text style={{ marginBottom: 8 }}>{t('doYouWishToSign')}</Text>

      <Text style={{ marginBottom: 8 }}>{t('bySigningThisPermit')}</Text>

      <Text style={{ marginBottom: 24 }}>{t('additionallyYouConfirm')}</Text>

      <View style={{ gap: 16 }}>
        <Button
          disabled={signing}
          loading={signing}
          title={t('sign')}
          onPress={async () => {
            try {
              setSigning(true)

              await signCSPPermit({
                preventiveOfficerId: rest.preventiveOfficerId,
                requestId: rest.requestId,
                signatureId: rest.signatureId
              })
              await queryClient.refetchQueries({
                queryKey: ['cspPermitDetail', rest.requestId]
              })
              toast.success({
                data: {
                  messageTranslationKey: 'permitSigned'
                }
              })
              hideModal()
            } catch (error) {
              setSigning(false)
              handleError(error)
            }
          }}
        />

        <Button
          disabled={signing}
          type="secondary"
          title={t('cancel')}
          onPress={hideModal}
        />
      </View>
    </>
  )
}
