// External
import { zodResolver } from '@hookform/resolvers/zod'
import type { StackScreenProps } from '@react-navigation/stack'
import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isSameDay
} from 'date-fns'
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { z } from 'zod'
// Components
import {
  Button,
  Checkbox,
  DateInput,
  Input,
  Select,
  Text,
  TopBar
} from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
import { checkInTimeValues } from '@/visit/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { PTWStackParamList } from '@/permitToWork/models'
// Services
import { requestPermitExtension } from '@/permitToWork/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { handleError } from '@/common/utils'
import { getCheckInTimeValues } from '@/visit/utils'

const getOnDayPressDate = (
  data: { year: number; month: number; day: number },
  timezone: string
) => {
  const baseDate = zonedTimeToUtc(
    new Date(data.year, data.month - 1, data.day),
    timezone
  )
  const now = new Date()

  if (isSameDay(baseDate, now)) {
    const totalMinutes = getMinutes(now) + getHours(now) * 60

    if (totalMinutes <= 8 * 60) {
      return addMinutes(baseDate, 8 * 60)
    } else {
      const checkInTimeValue = checkInTimeValues.find(
        ({ value }) => parseInt(value) >= totalMinutes
      )
      if (checkInTimeValue !== undefined) {
        return addMinutes(baseDate, parseInt(checkInTimeValue.value))
      }
    }
  } else {
    return addMinutes(baseDate, 8 * 60)
  }
}

const getSelectValue = (value: Date, timezone: string) => {
  const base = addMilliseconds(value, getTimezoneOffset(timezone))
  const hours = base.getUTCHours()
  const minutes = base.getUTCMinutes()
  return (hours * 60 + minutes).toString()
}

type Props = StackScreenProps<PTWStackParamList, 'RequestAnExtension'>

const RequestAnExtension = ({ navigation, route }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { _id, endDate } = route.params
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      z.object({
        observations: z.string().optional(),
        endDate: z
          .date()
          .min(addHours(new Date(endDate), 1), {
            message: t('newEndDateMustBeAtLeast')
          })
          .max(addDays(new Date(endDate), 2), {
            message: t('newEndDateCantBe')
          }),
        iUnderstandIMustWaitForApproval: z.boolean().refine((val) => val, {
          message: t('requiredField')
        })
      }),
    [t, endDate]
  )

  type FormValues = z.infer<typeof formSchema>

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      endDate: addHours(new Date(endDate), 1),
      iUnderstandIMustWaitForApproval: false
    }
  })

  const onSubmit = async (values: FormValues) => {
    try {
      await requestPermitExtension({
        ptwRequestId: _id,
        extensionDate: values.endDate.toISOString(),
        observations: values.observations
      })
      toast.success({
        data: {
          titleTranslationKey: 'requestSubmittedSuccessfully',
          messageTranslationKey: 'pleaseWaitForTheIssuersApproval'
        }
      })
      navigation.goBack()
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <SafeArea style={{ marginHorizontal: 25, marginBottom: 25 }}>
      <TopBar
        title={t('requestAnExtension')}
        style={{ marginBottom: 24 }}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack
        }}
      />

      <Controller
        control={control}
        name="observations"
        render={({ field: { onBlur, onChange, value } }) => (
          <Input
            value={value}
            onBlur={onBlur}
            onChangeText={onChange}
            label={t('comments')}
            placeholder={t('typeAnyCommentsRelevantToTheExtension')}
            errorMessage={errors.observations?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="endDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <View>
            <DateInput
              label={t('endDate')}
              value={value}
              minDate={endDate}
              onDayPress={(data) => {
                const date = getOnDayPressDate(
                  data,
                  currentFacility?.info.timezone as string
                )
                onChange(date)
                onBlur()
              }}
              placeholder={t('selectADate')}
              errorMessage={errors.endDate?.message}
            />

            <Select
              {...(value !== undefined && {
                value: getSelectValue(
                  value,
                  currentFacility?.info.timezone as string
                )
              })}
              disabled={value === undefined}
              label={t('endTime')}
              options={
                value !== undefined
                  ? getCheckInTimeValues(isSameDay(value, new Date()))
                  : []
              }
              onSelect={(option) => {
                if (value === undefined) {
                  return
                }

                const baseDate = zonedTimeToUtc(
                  new Date(getYear(value), getMonth(value), getDate(value)),
                  currentFacility?.info.timezone as string
                )
                onChange(addMinutes(baseDate, parseInt(option.value)))
                onBlur()
              }}
              placeholder={t('selectATime')}
              errorMessage={errors.endDate?.message}
            />
          </View>
        )}
      />

      <View style={styles.condition}>
        <Controller
          control={control}
          name="iUnderstandIMustWaitForApproval"
          render={({ field: { onChange, onBlur, value } }) => (
            <Checkbox
              checked={value}
              onPress={() => {
                onChange(!value)
                onBlur()
              }}
              onBlur={onBlur}
            />
          )}
        />

        <Text style={{ flex: 1 }}>{t('iUnderstandIMustWaitForApproval')}</Text>
      </View>

      {errors.iUnderstandIMustWaitForApproval?.message !== undefined && (
        <Text style={styles.error}>
          {errors.iUnderstandIMustWaitForApproval.message}
        </Text>
      )}

      <Button
        style={{ marginTop: 32 }}
        onPress={handleSubmit(onSubmit)}
        title={t('submitExtensionRequest')}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
    </SafeArea>
  )
}

export default RequestAnExtension

const styles = StyleSheet.create({
  condition: {
    flexDirection: 'row',
    gap: 16,
    marginTop: 'auto'
  },
  error: {
    marginTop: 12,
    color: colors.error,
    fontFamily: 'PlusJakartaSans_400Regular'
  }
})
