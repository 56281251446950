import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
import NewRequestStep1 from '@/cspPermitToWork/views/NewRequest/NewRequestStep1'
import NewRequestStep2 from '@/cspPermitToWork/views/NewRequest/NewRequestStep2'
import NewRequestStep3 from '@/cspPermitToWork/views/NewRequest/NewRequestStep3'
import NewRequestStep4Machinery from '@/cspPermitToWork/views/NewRequest/NewRequestStep4Machinery'
import NewRequestStep4Zone from '@/cspPermitToWork/views/NewRequest/NewRequestStep4Zone'
import NewRequestStep5 from '@/cspPermitToWork/views/NewRequest/NewRequestStep5'
import NewRequestStep6 from '@/cspPermitToWork/views/NewRequest/NewRequestStep6'
import NewRequestStep7 from '@/cspPermitToWork/views/NewRequest/NewRequestStep7'
import NewRequestStep8 from '@/cspPermitToWork/views/NewRequest/NewRequestStep8'
import NewRequestStep9 from '@/cspPermitToWork/views/NewRequest/NewRequestStep9'
import PermitDetail from '@/cspPermitToWork/views/PermitDetail'
import PermitToWork from '@/cspPermitToWork/views/PermitToWork'
import { createStackNavigator } from '@react-navigation/stack'

const Stack = createStackNavigator<CSPPTWStackParamList>()

const CSPPermitToWorkStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="PermitToWork"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="PermitToWork" component={PermitToWork} />

      <Stack.Screen name="NewRequestStep1" component={NewRequestStep1} />

      <Stack.Screen name="NewRequestStep2" component={NewRequestStep2} />

      <Stack.Screen name="NewRequestStep3" component={NewRequestStep3} />

      <Stack.Screen
        name="NewRequestStep4Zone"
        component={NewRequestStep4Zone}
      />

      <Stack.Screen
        name="NewRequestStep4Machinery"
        component={NewRequestStep4Machinery}
      />

      <Stack.Screen name="NewRequestStep5" component={NewRequestStep5} />

      <Stack.Screen name="NewRequestStep6" component={NewRequestStep6} />

      <Stack.Screen name="NewRequestStep7" component={NewRequestStep7} />

      <Stack.Screen name="NewRequestStep8" component={NewRequestStep8} />

      <Stack.Screen name="NewRequestStep9" component={NewRequestStep9} />

      <Stack.Screen name="PermitDetail" component={PermitDetail} />
    </Stack.Navigator>
  )
}

export default CSPPermitToWorkStack
