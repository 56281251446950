import { z } from 'zod'
import { extraQuestionSchema } from './extraQuestionSchema'

const baseMachinerySchema = z.object({
  applicationProcedures: z.string().optional(),
  applicationStandards: z.string().optional(),
  extraQuestions: z.array(extraQuestionSchema),
  id: z.string(),
  isLast: z.boolean(),
  name: z.string(),
  requiredDocuments: z.string().optional(),
  mustSpecifyMachinery: z.boolean()
})

export const machinerySchema = baseMachinerySchema.extend({
  parents: z.array(baseMachinerySchema)
})

export type Machinery = z.infer<typeof machinerySchema>
