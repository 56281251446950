/**
 * Avatar image processing configuration options.
 * @docs https://docs.bunny.net/docs/stream-image-processing
 */
export interface ImageProcessingConfiguration {
  width?: number
  height?: number
  format?: 'webp' | 'png'
  aspect?: 'auto' | '1:1' | '16:9' | '4:3' | '3:2' | '5:4' | '9:16' | '1:2'
  quality?: number
  blur?: number
}

/**
 * Generates a URL with query parameters for processing avatar images.
 *
 * @param src - The original image URL.
 * @param width - The desired width of the avatar in pixels.
 * @param height - The desired height of the avatar in pixels.
 * @param format - The desired image format (e.g., 'webp', 'png').
 * @param quality - The desired image quality (0 to 100).
 * @returns The modified URL with appended query parameters.
 */
export function generateImageUrl(
  src?: string,
  {
    aspect = 'auto',
    format = 'webp',
    quality = 80,
    width = 100,
    height = 100,
    blur = 0
  }: ImageProcessingConfiguration = {}
): string | undefined {
  try {
    if (src === undefined) {
      return
    }

    const url = new URL(src)
    const params = url.searchParams

    params.set('optimizer', 'true')

    // Set resizing parameters
    params.set('width', (width * 1.01).toString())
    params.set('height', (height * 1.01).toString())

    // Enable face detection for centering
    params.set('face', 'true')

    // Set image format
    params.set('format', format)

    // Set image quality
    params.set('quality', quality.toString())

    // Set image blur
    params.set('blur', blur.toString())

    // if (aspect) {
    params.set('aspect_ratio', aspect)
    // }

    return url.toString()
  } catch (error) {
    console.error('Invalid URL provided:', error)
    return src // Return the original source if there's an error
  }
}

// export function generateTableAvatarUrl(
//   src: string | undefined = undefined,
//   size = 32
// ): string | undefined {
//   if (!src) return undefined

//   try {
//     const url = new URL(src)
//     const params = url.searchParams

//     params.set('height', size.toString())
//     params.set('format', 'webp')
//     params.set('quality', '30')
//     params.set('aspect_ratio', '1:1')

//     return url.toString()
//   } catch (error) {
//     console.error('Invalid URL provided:', error)
//     return src // Return the original source if there's an error
//   }
// }
