// External
import type { ReactNode } from 'react'
import { Pressable, StyleSheet } from 'react-native'
// Components
import { Checkbox } from './Checkbox'
import { Text } from './Text'
// Models
import { type Option } from '@/common/models'

interface Props {
  disabled?: boolean
  selected?: boolean
  option: Option
  onPress: () => void
  renderOption?: (option: Option) => ReactNode
}

export const SelectOption = ({
  disabled = false,
  selected,
  option,
  onPress,
  renderOption
}: Props) => {
  return (
    <Pressable style={styles.container} disabled={disabled} onPress={onPress}>
      {renderOption !== undefined && option.original !== undefined ? (
        renderOption(option)
      ) : (
        <Text style={{ flex: 1 }} variant="button">
          {option.label}
        </Text>
      )}

      {selected !== undefined && (
        <Checkbox disabled={disabled} checked={selected} onPress={onPress} />
      )}
    </Pressable>
  )
}
const styles = StyleSheet.create({
  container: {
    minHeight: 62,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
