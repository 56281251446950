import {
  Georama_100Thin,
  Georama_100Thin_Italic,
  Georama_200ExtraLight,
  Georama_200ExtraLight_Italic,
  Georama_300Light,
  Georama_300Light_Italic,
  Georama_400Regular,
  Georama_400Regular_Italic,
  Georama_500Medium,
  Georama_500Medium_Italic,
  Georama_600SemiBold,
  Georama_600SemiBold_Italic,
  Georama_700Bold,
  Georama_800ExtraBold,
  Georama_800ExtraBold_Italic,
  Georama_900Black,
  Georama_900Black_Italic,
  useFonts
} from '@expo-google-fonts/georama'
import {
  PlusJakartaSans_200ExtraLight,
  PlusJakartaSans_200ExtraLight_Italic,
  PlusJakartaSans_300Light,
  PlusJakartaSans_300Light_Italic,
  PlusJakartaSans_400Regular,
  PlusJakartaSans_400Regular_Italic,
  PlusJakartaSans_500Medium,
  PlusJakartaSans_500Medium_Italic,
  PlusJakartaSans_600SemiBold,
  PlusJakartaSans_600SemiBold_Italic,
  PlusJakartaSans_700Bold,
  PlusJakartaSans_700Bold_Italic,
  PlusJakartaSans_800ExtraBold,
  PlusJakartaSans_800ExtraBold_Italic
} from '@expo-google-fonts/plus-jakarta-sans'
import * as SplashScreen from 'expo-splash-screen'
import { useEffect } from 'react'

const useAssets = () => {
  const [fontsLoaded] = useFonts({
    Stowlog: require('@assets/fonts/StowlogIcons.ttf'),
    Georama_100Thin,
    Georama_200ExtraLight,
    Georama_300Light,
    Georama_400Regular,
    Georama_500Medium,
    Georama_600SemiBold,
    Georama_700Bold,
    Georama_800ExtraBold,
    Georama_900Black,
    Georama_100Thin_Italic,
    Georama_200ExtraLight_Italic,
    Georama_300Light_Italic,
    Georama_400Regular_Italic,
    Georama_500Medium_Italic,
    Georama_600SemiBold_Italic,
    Georama_800ExtraBold_Italic,
    Georama_900Black_Italic,
    PlusJakartaSans_200ExtraLight,
    PlusJakartaSans_300Light,
    PlusJakartaSans_400Regular,
    PlusJakartaSans_500Medium,
    PlusJakartaSans_600SemiBold,
    PlusJakartaSans_700Bold,
    PlusJakartaSans_800ExtraBold,
    PlusJakartaSans_200ExtraLight_Italic,
    PlusJakartaSans_300Light_Italic,
    PlusJakartaSans_400Regular_Italic,
    PlusJakartaSans_500Medium_Italic,
    PlusJakartaSans_600SemiBold_Italic,
    PlusJakartaSans_700Bold_Italic,
    PlusJakartaSans_800ExtraBold_Italic
  })

  useEffect(() => {
    if (fontsLoaded) {
      void SplashScreen.hideAsync()
    }
  }, [fontsLoaded])

  return fontsLoaded
}

export default useAssets
