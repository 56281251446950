// External
import { zodResolver } from '@hookform/resolvers/zod'
import type { DrawerScreenProps } from '@react-navigation/drawer'
import { CommonActions } from '@react-navigation/native'
import type { StackScreenProps } from '@react-navigation/stack'
import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type TextInput, StyleSheet } from 'react-native'
import { z } from 'zod'
// Components
import { Button, Input } from '@/common/components'
// Constants
import {
  lowercaseRegex,
  noSpaceRegex,
  numbersRegex,
  symbolRegex,
  toast,
  uppercaseRegex
} from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import { type PreHomeStackParamList } from '@/common/models'
import type { HomeDrawerParamList } from '@/home/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { resetPassword } from '@/auth/services'
// Utils
import { handleError } from '@/common/utils'

type Props =
  | StackScreenProps<PreHomeStackParamList, 'RestorePassword'>
  | DrawerScreenProps<HomeDrawerParamList, 'RestorePassword'>

const RestorePassword = ({ navigation, route }: Props) => {
  const { user, currentUserType, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentUserType: state.currentUserType,
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()
  const repeatPasswordInputRef = useRef<TextInput>(null)

  const validationSchema = z
    .object({
      password: z
        .string()
        .min(8, { message: t('passwordLengthError', { length: 8 }) })
        .regex(noSpaceRegex, { message: t('passwordSpaceError') })
        .regex(uppercaseRegex, { message: t('passwordUppercaseError') })
        .regex(lowercaseRegex, { message: t('passwordLowercaseError') })
        .regex(numbersRegex, { message: t('passwordNumberError') })
        .regex(symbolRegex, { message: t('passwordSymbolError') }),
      repeatPassword: z.string()
    })
    .refine((data) => data.password === data.repeatPassword, {
      message: t('passwordsMustMatch'),
      path: ['repeatPassword'] // This is the path to the error
    })

  type FormValues = z.infer<typeof validationSchema>

  const onSubmit = async ({ password }: FormValues) => {
    try {
      await resetPassword({
        password,
        token: route.params.token
      })
      toast.success({
        data: {
          titleTranslationKey: 'passwordResetSuccessTitle',
          messageTranslationKey: 'passwordResetSuccessMessage'
        }
      })

      if (
        user !== undefined &&
        currentUserType !== undefined &&
        currentFacility !== undefined
      ) {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: 'Home' }]
          })
        )
        return
      }

      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{ name: 'Welcome' }, { name: 'Signin' }]
        })
      )
    } catch (error) {
      handleError(error)
    }
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      password: '',
      repeatPassword: ''
    }
  })

  return (
    <SafeArea style={styles.container} withBackground>
      <Controller
        control={control}
        name="password"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            secureTextEntry
            placeholder={t('yourPassword')}
            onChangeText={onChange}
            onSubmitEditing={() => {
              repeatPasswordInputRef.current?.focus()
            }}
            onBlur={onBlur}
            value={value}
            returnKeyType="next"
            autoComplete="password"
            autoCapitalize="none"
            errorMessage={errors.password?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="repeatPassword"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            ref={repeatPasswordInputRef}
            secureTextEntry
            placeholder={t('repeatYourPassword')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(onSubmit)}
            onBlur={onBlur}
            value={value}
            returnKeyType="done"
            autoComplete="password"
            autoCapitalize="none"
            errorMessage={errors.repeatPassword?.message}
          />
        )}
      />

      <Button
        title={t('restorePassword')}
        disabled={isSubmitting}
        loading={isSubmitting}
        onPress={handleSubmit(onSubmit)}
      />
    </SafeArea>
  )
}
export default RestorePassword

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 25
  }
})
