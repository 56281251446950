// External
import { zodResolver } from '@hookform/resolvers/zod'
import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type TextInput } from 'react-native'
import { type SheetProps } from 'react-native-actions-sheet'
import { z } from 'zod'
// Components
import { Button, Input, Text } from '@/common/components'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'

type Props = SheetProps<'edit-email'>

export const EditEmail = ({ payload, sheetId }: Props) => {
  const { t } = useTranslation()
  const repeatEmailInputRef = useRef<TextInput>(null)

  const validationSchema = z
    .object({
      email: z
        .string()
        .nonempty({ message: t('provideEmail') })
        .email({ message: t('invalidEmail') }),
      repeatEmail: z
        .string()
        .nonempty({ message: t('provideEmail') })
        .email({ message: t('invalidEmail') })
    })
    .refine((data) => data.email === data.repeatEmail, {
      message: t('emailsMustMatch'),
      path: ['repeatEmail']
    })

  type FormValues = z.infer<typeof validationSchema>

  const onSubmit = ({ email }: FormValues) => {
    void payload?.onSubmit(email)
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: payload?.initialValue as string,
      repeatEmail: ''
    }
  })

  return (
    <ActionSheetContainer id={sheetId} isModal={false}>
      <Text
        style={{
          textAlign: 'center',
          marginBottom: 30
        }}
        variant="baseBold"
      >
        {t('editYourEmail')}
      </Text>

      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            placeholder={t('yourEmail')}
            onChangeText={onChange}
            onSubmitEditing={() => {
              repeatEmailInputRef.current?.focus()
            }}
            onBlur={onBlur}
            value={value}
            inputMode="email"
            returnKeyType="next"
            autoComplete="email"
            autoCapitalize="none"
            errorMessage={errors.email?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="repeatEmail"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            placeholder={t('repeatYourEmail')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(onSubmit)}
            onBlur={onBlur}
            value={value}
            inputMode="email"
            returnKeyType="done"
            autoComplete="email"
            autoCapitalize="none"
            errorMessage={errors.repeatEmail?.message}
          />
        )}
      />

      <Button
        style={{ marginTop: 18 }}
        title={t('saveChanges')}
        onPress={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
    </ActionSheetContainer>
  )
}
