// External
import { createTheme } from '@rneui/themed'
import { Platform } from 'react-native'
// Constants
import { colors, textBaseStylesMap } from '@/common/constants'

export const theme = createTheme({
  mode: 'light',
  components: {
    CheckBox: {
      containerStyle: { backgroundColor: 'transparent', padding: 0, margin: 0 }
    },
    Divider: {
      color: colors.lightGray10Percent
    },
    Input: {
      selectionColor: colors.hydro,
      containerStyle: {
        paddingHorizontal: 0
      },
      inputContainerStyle: {
        backgroundColor: colors.inactive,
        height: 52,
        borderWidth: 1.5,
        borderRadius: 7,
        borderBottomWidth: 1.5,
        borderColor: colors.inactive,
        paddingHorizontal: 12,
        gap: 12
      },
      inputStyle: {
        color: colors.text,
        fontFamily: 'PlusJakartaSans_500Medium',
        fontSize: 13,
        ...(Platform.OS === 'web' && { outline: 'none' })
      },
      placeholderTextColor: colors.placeholder,
      renderErrorMessage: true,
      errorStyle: {
        color: colors.error,
        fontFamily: 'PlusJakartaSans_400Regular'
      },
      labelStyle: {
        ...textBaseStylesMap.label,
        ...(Platform.OS === 'web' && {
          fontWeight: '500'
        }),
        color: colors.text
      }
    },
    LinearProgress: {
      color: colors.hydro,
      trackColor: colors.inactive,
      style: {
        height: 5,
        borderRadius: 8
      }
    },
    Overlay: {
      overlayStyle: {
        padding: 25,
        borderRadius: 10,
        maxHeight: '80%',
        width: '80%'
      }
    },
    SearchBar: {
      containerStyle: {
        padding: 0,
        backgroundColor: 'transparent',
        borderTopWidth: 0,
        borderBottomWidth: 0
      },
      inputContainerStyle: {
        height: 48,
        borderRadius: 10,
        padding: 20,
        backgroundColor: colors.inactive
      },
      inputStyle: {
        alignSelf: 'center',
        fontFamily: 'PlusJakartaSans_500Medium',
        fontSize: 13,
        lineHeight: 19,
        color: colors.text
      },
      placeholderTextColor: colors.placeholder,
      selectionColor: colors.hydro
    },
    Skeleton: {
      style: {
        opacity: 0.33
      }
    },
    Switch: {
      ios_backgroundColor: '#E5E5EA',
      trackColor: {
        false: '#E5E5EA',
        true: colors.columbiaBlue
      },
      style: {
        transform: [{ scaleX: 0.85 }, { scaleY: 0.85 }]
      }
    },
    Tab: {
      disableIndicator: true,
      titleStyle: (active) => ({
        paddingHorizontal: 0,
        paddingVertical: 0,
        fontFamily: 'PlusJakartaSans_700Bold',
        fontSize: 12,
        lineHeight: 13,
        letterSpacing: 0.07,
        color: active ? colors.hydro : colors.darkSlateGray
      }),
      buttonStyle: (active) => ({
        padding: 10,
        borderWidth: 1.5,
        borderRadius: 10,
        borderColor: active ? '#CAE4EA' : colors.inactive,
        backgroundColor: active ? colors.hover : colors.white,
        height: 40,
        minWidth: 120
      })
    },
    Tooltip: {
      backgroundColor: colors.hydro
    },
    ListItem: {
      containerStyle: {
        justifyContent: 'space-between'
      }
    },
    ListItemTitle: {
      style: {
        fontFamily: 'PlusJakartaSans_500Medium',
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 17,
        color: colors.placeholder
      }
    }
  }
})
