import type { ExtendedPermitStatus, StowlogIcon } from '@/common/models'

export const permitStatusIcons: Record<ExtendedPermitStatus, StowlogIcon> = {
  ACTIVE: 'ptw-approved-active',
  QUEUED: 'ptw-queued',
  PENDING: 'ptw-pending',
  REJECTED: 'ptw-rejected',
  CANCELED: 'ptw-cancelled',
  CLOSED: 'ptw-closed',
  EXPIRED: 'ptw-expired',
  REQUESTED: 'ptw-pending',
  DENIED: 'ptw-rejected',
  FINISHED: 'ptw-approved-active'
}
