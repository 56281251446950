// External
import { ListItem } from '@rneui/themed'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { Button, Icon, Link, Text, TopBar } from '@/common/components'
import { ProcedureSelectSkeleton } from '../ProcedureSelectSkeleton'
import { LastMachineryForm } from './LastMachineryForm'
import { MachinerySpecificZoneForm } from './MachinerySpecificZoneForm'
// Constants
import { colors } from '@/common/constants'
import type {
  AnsweredExtraQuestion,
  Machinery,
  MachineryZone
} from '@/cspPermitToWork/constants'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { components } from '@/common/models'
// Services
import { getMachinery, getMachineryZones } from '@/cspPermitToWork/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import { cloneDeep } from 'lodash'

interface Props {
  numberSelected?: number
  onSelect: (value: {
    machinery: Machinery
    specificMachinery?: string
    zone: MachineryZone
    specificZone?: string
    extraQuestion?: AnsweredExtraQuestion
  }) => void
}

export const MachinerySelect = ({ numberSelected, onSelect }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const [machineryCursor, setMachineryCursor] = useState<Machinery>()
  const [zoneCursor, setZoneCursor] = useState<MachineryZone>()
  const [selectingMachinery, setSelectingMachinery] = useState(false)
  const [selectingZone, setSelectingZone] = useState(false)

  const [machinery, setMachinery] = useState<{
    machinery: Machinery
    specificMachinery?: string
    extraQuestion?: {
      answer: string
      extraQuestionId: string
      question: string
    }
  }>()

  const { t } = useTranslation()
  const { bottom } = useSafeAreaInsets()
  const isRTL = useIsRTL()

  const previousMachinery = useCallback(() => {
    // the function is only available when cursor is defined
    if (machineryCursor!.parents.length === 0) {
      setMachineryCursor(undefined)
      return
    }

    // @ts-expect-error - it only gets here when there's at least one parent
    setMachineryCursor((prev) => ({
      ...prev?.parents[0],
      parents: prev?.parents.slice(1)
    }))
  }, [machineryCursor])
  const nextMachinery = useCallback(
    (machinery: components['schemas']['AppCSPMachineResponseDto']) => {
      const currentCursor = cloneDeep(machineryCursor)
      const newCursor = {
        id: machinery.id,
        name: machinery.name,
        isLast: machinery.isLast,
        applicationStandards: machinery.applicationStandards,
        applicationProcedures: machinery.applicationProcedures,
        extraQuestions: machinery.extraQuestions,
        requiredDocuments: machinery.requiredDocuments,
        mustSpecifyMachinery: machinery.mustSpecifyZone,
        parents: [
          ...(currentCursor !== undefined
            ? [
                {
                  id: currentCursor.id,
                  name: currentCursor.name,
                  isLast: currentCursor.isLast,
                  applicationStandards: currentCursor.applicationStandards,
                  applicationProcedures: currentCursor.applicationProcedures,
                  extraQuestions: currentCursor.extraQuestions,
                  requiredDocuments: currentCursor.requiredDocuments,
                  mustSpecifyMachinery: machinery.mustSpecifyZone
                }
              ]
            : []),
          ...(currentCursor?.parents ?? [])
        ]
      }

      if (
        (newCursor?.isLast ?? false) &&
        newCursor?.extraQuestions?.length === 0 &&
        newCursor?.applicationStandards?.length === 0
      ) {
        setMachinery({
          machinery: newCursor
        })
        setSelectingMachinery(false)
        setSelectingZone(true)
        return
      }

      setMachineryCursor(newCursor)
    },
    [machineryCursor]
  )

  const { data: machineryData, isFetching: isFetchingMachinery } = useQuery({
    queryKey: ['cspMachinery', currentFacility?._id, machineryCursor?.id],
    queryFn: async () => {
      const res = await getMachinery({
        parentMachineryId: machineryCursor?.id,
        page: 1
      })
      return res
    }
  })

  const { data: zonesData, isFetching: isFetchingZones } = useQuery({
    queryKey: ['cspMachineryZones', currentFacility?._id],
    queryFn: async () => await getMachineryZones({ page: 1 })
  })

  useEffect(() => {
    if (numberSelected === 0) {
      setSelectingMachinery(true)
    }
  }, [numberSelected])

  return (
    <>
      <Link
        style={styles.link}
        icon={{
          name: 'add-file'
        }}
        onPress={() => {
          setSelectingMachinery(true)
        }}
      >
        {t(numberSelected === 0 ? 'addMachinery' : 'addAnotherMachinery')}
      </Link>

      {selectingMachinery && (
        <SafeArea style={styles.container} edges={['top', 'right', 'left']}>
          <TopBar
            title={machineryCursor?.name ?? t('machinery')}
            leftIcon={{
              name: 'close',
              onPress: () => {
                setMachineryCursor(undefined)
                setSelectingMachinery(false)
              }
            }}
          />

          {!(machineryCursor?.isLast ?? false) && (
            <>
              <Text style={{ marginTop: 27 }} variant="small">
                {t('selectTheMachinery')}
              </Text>

              <ScrollView
                bounces={false}
                style={{ flex: 1 }}
                contentContainerStyle={{
                  paddingBottom: bottom + 25
                }}
              >
                {isFetchingMachinery ? (
                  <ProcedureSelectSkeleton />
                ) : (
                  machineryData?.map((machinery, index, { length }) => (
                    <ListItem
                      onPress={() => {
                        nextMachinery(machinery)
                      }}
                      key={machinery.id}
                      bottomDivider={index < length - 1}
                    >
                      <ListItem.Title>{machinery.name}</ListItem.Title>

                      {
                        <Icon
                          name="next"
                          size={16}
                          color={colors.placeholder}
                        />
                      }
                    </ListItem>
                  ))
                )}
              </ScrollView>

              {machineryCursor !== undefined && (
                <Button
                  style={styles.backButton}
                  compact
                  type="secondary"
                  onPress={previousMachinery}
                  iconName={isRTL ? 'next' : 'back'}
                />
              )}
            </>
          )}

          {(machineryCursor?.isLast ?? false) && (
            <LastMachineryForm
              machinery={machineryCursor!}
              onSelect={(values) => {
                setMachinery(values)
                setSelectingMachinery(false)
                setSelectingZone(true)
              }}
              back={previousMachinery}
            />
          )}
        </SafeArea>
      )}

      {selectingZone && (
        <SafeArea style={styles.container} edges={['top', 'right', 'left']}>
          <TopBar
            title={zoneCursor?.name ?? t('zoneOfMachinery')}
            leftIcon={{
              name: 'close',
              onPress: () => {
                setZoneCursor(undefined)
                setSelectingZone(false)
              }
            }}
          />

          <Text style={{ marginTop: 27 }} variant="small">
            {t(zoneCursor === undefined ? 'selectTheWorkZone' : 'specifyZone')}
          </Text>

          {zoneCursor === undefined && (
            <>
              <ScrollView
                style={{ flex: 1 }}
                contentContainerStyle={{
                  paddingBottom: bottom + 25
                }}
              >
                {isFetchingZones ? (
                  <ProcedureSelectSkeleton />
                ) : (
                  zonesData?.map((zone, index, { length }) => (
                    <ListItem
                      onPress={() => {
                        setZoneCursor(zone)
                      }}
                      key={zone.id}
                      bottomDivider={index < length - 1}
                    >
                      <ListItem.Title>{zone.name}</ListItem.Title>

                      {
                        <Icon
                          name="next"
                          size={16}
                          color={colors.placeholder}
                        />
                      }
                    </ListItem>
                  ))
                )}
              </ScrollView>

              <Button
                style={styles.backButton}
                compact
                type="secondary"
                onPress={() => {
                  setSelectingZone(false)
                  setZoneCursor(undefined)
                  setSelectingMachinery(true)
                }}
                iconName={isRTL ? 'next' : 'back'}
              />
            </>
          )}

          {zoneCursor !== undefined && (
            <MachinerySpecificZoneForm
              zone={zoneCursor}
              onSelect={(values) => {
                if (machinery !== undefined) {
                  onSelect({
                    machinery: machinery.machinery,
                    specificMachinery: machinery.specificMachinery,
                    zone: values.zone,
                    specificZone: values.specificZone,
                    extraQuestion: machinery.extraQuestion
                  })
                  setMachineryCursor(undefined)
                  setZoneCursor(undefined)
                  setSelectingZone(false)
                }
              }}
              back={() => {
                setZoneCursor(undefined)
              }}
            />
          )}
        </SafeArea>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  link: {
    alignSelf: 'flex-end',
    marginTop: 10
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999
  },
  backButton: {
    marginTop: 'auto',
    alignSelf: 'flex-start',
    marginBottom: 25
  }
})
