import { AttachmentList, Link, RenderHTML, Text } from '@/common/components'
import { colors, toast } from '@/common/constants'
import {
  CSPPermitToWorkRequestStatusEnum,
  type components
} from '@/common/models'
import { handleError } from '@/common/utils'
import {
  deletePermitRequestAttachment,
  uploadPermitRequestAttachments
} from '@/cspPermitToWork/services'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { ViewStyle } from 'react-native'
import { View, useWindowDimensions } from 'react-native'
import { SheetManager } from 'react-native-actions-sheet'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

interface Props {
  style?: ViewStyle
  requestId: components['schemas']['AppCSPPermitToWorkRequestDetailResponseDTO']['id']
  status: components['schemas']['AppCSPPermitToWorkRequestDetailResponseDTO']['status']
  requiredDocuments: components['schemas']['AppCSPPermitToWorkRequestDetailResponseDTO']['requiredDocuments']
  attachments: Array<components['schemas']['AppCSPAttachmentDto']>
  refetch: () => void
}

export const PermitAttachments = ({
  style,
  requestId,
  status,
  requiredDocuments,
  attachments,
  refetch
}: Props) => {
  const [isUploading, setIsUploading] = useState(false)
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const { left, right } = useSafeAreaInsets()
  const allowActions = [
    CSPPermitToWorkRequestStatusEnum.PENDING,
    CSPPermitToWorkRequestStatusEnum.REQUESTED
  ].includes(status)

  return (
    <View style={[{ gap: 20 }, style]}>
      <View>
        <Text variant="label">{t('requestedAttachments')}</Text>

        {requiredDocuments.length > 0 ? (
          <RenderHTML
            html={requiredDocuments}
            contentWidth={width - 25 * 2 - left - right}
          />
        ) : (
          <Text variant="small">{t('pleaseUploadAnyFiles')}</Text>
        )}
      </View>

      <View>
        <Text variant="label">{t('attachments')}</Text>

        <AttachmentList
          style={{ marginBottom: 8 }}
          attachments={attachments.map(({ attachment }) => attachment)}
          {...(allowActions && {
            options: [
              {
                label: t('delete'),
                onPress: async (attachment) => {
                  try {
                    setIsUploading(true)
                    await deletePermitRequestAttachment({
                      requestId,
                      attachmentId: attachment._id
                    })
                    refetch()
                    toast.success({
                      data: {
                        messageTranslationKey: 'attachmentDeleted'
                      }
                    })
                  } catch (error) {
                    handleError(error)
                  } finally {
                    setIsUploading(false)
                  }
                }
              }
            ]
          })}
        />

        {allowActions && (
          <Link
            style={{
              alignSelf: 'center'
            }}
            onPress={() => {
              void SheetManager.show('upload-options', {
                payload: {
                  onImagePickerAsset: async (asset) => {
                    try {
                      setIsUploading(true)
                      await SheetManager.hide('upload-options')
                      await uploadPermitRequestAttachments({
                        requestId,
                        attachments: [asset]
                      })
                      refetch()
                      toast.success({
                        data: {
                          messageTranslationKey: 'attachmentUploaded'
                        }
                      })
                    } catch (error) {
                      handleError(error)
                    } finally {
                      setIsUploading(false)
                    }
                  },
                  onDocumentPickerAsset: async (asset) => {
                    try {
                      setIsUploading(true)
                      await SheetManager.hide('upload-options')
                      await uploadPermitRequestAttachments({
                        requestId,
                        attachments: [asset]
                      })
                      refetch()
                      toast.success({
                        data: {
                          messageTranslationKey: 'attachmentUploaded'
                        }
                      })
                    } catch (error) {
                      handleError(error)
                    } finally {
                      setIsUploading(false)
                    }
                  }
                }
              })
            }}
            color={colors.rajah}
            icon={{
              name: 'add-file'
            }}
            disabled={isUploading}
          >
            {t('uploadAttachments')}
          </Link>
        )}
      </View>
    </View>
  )
}
