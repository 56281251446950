import { Text } from '@/common/components'
import { colors } from '@/common/constants'
import useAppStore from '@/common/stores/useAppStore'
import { formatDate } from '@/common/utils'
import { isValid } from 'date-fns'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { TextStyle } from 'react-native'

interface Props {
  style?: TextStyle
  question?: string
  answer: string
}

export const ExtraQuestionAnswer = ({ style, question, answer }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const getExtraQuestionAnswer = useCallback(
    (answer: string) => {
      const date = new Date(answer)
      if (isValid(date)) {
        return formatDate(date, 'P, hh:mm a', currentFacility?.info.timezone)
      }

      if (answer === 'true') {
        return t('yes')
      }

      if (answer === 'false') {
        return t('no')
      }

      return answer
    },
    [t]
  )

  return (
    <Text style={style} variant="smallBold">
      {question}
      <Text
        variant="small"
        color={colors.placeholder}
      >{` ${getExtraQuestionAnswer(answer)}`}</Text>
    </Text>
  )
}
