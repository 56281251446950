import { Text } from '@/common/components'
import { colors } from '@/common/constants'
import type { components } from '@/common/models'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, type ViewStyle } from 'react-native'

interface Props {
  style?: ViewStyle
  company: components['schemas']['AppCSPPermitToWorkRequestDetailResponseDTO']['company']
}

export const PermitCompany = ({ style, company }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={style}>
      <Text style={{ marginBottom: 4 }} variant="label">
        {t('company')}
      </Text>

      <View style={styles.company}>
        <Text
          style={{
            fontFamily: 'PlusJakartaSans_500Medium'
          }}
          variant="small"
        >
          {company.name}
        </Text>

        <Text
          style={{
            fontFamily: 'PlusJakartaSans_300Light'
          }}
          variant="small"
        >
          {company.uid}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  company: {
    height: 52,
    borderRadius: 7,
    backgroundColor: colors.inactive,
    paddingHorizontal: 12,
    justifyContent: 'center'
  }
})
