import { type StowlogLocale } from '@/common/models'

export const locales: Record<
  StowlogLocale,
  {
    label: string
    isRTL: boolean
  }
> = {
  ar: {
    label: 'عربي',
    isRTL: true
  },
  ca: {
    label: 'Català',
    isRTL: false
  },
  de: {
    label: 'Deutsch',
    isRTL: false
  },
  'en-GB': {
    label: 'English (UK)',
    isRTL: false
  },
  'en-US': {
    label: 'English (US)',
    isRTL: false
  },
  es: {
    label: 'Español',
    isRTL: false
  },
  fr: {
    label: 'Français',
    isRTL: false
  },
  hr: {
    label: 'Hrvatski',
    isRTL: false
  },
  hu: {
    label: 'Magyar',
    isRTL: false
  },
  it: {
    label: 'Italiano',
    isRTL: false
  },
  pl: {
    label: 'Polski',
    isRTL: false
  },
  sv: {
    label: 'Svenska',
    isRTL: false
  },
  ro: {
    label: 'Română',
    isRTL: false
  },
  ur: {
    label: 'اردو',
    isRTL: true
  },
  hi: {
    label: 'हिन्दी',
    isRTL: false
  }
}
