// External
import type { StackScreenProps } from '@react-navigation/stack'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import {
  AttachmentList,
  Avatar,
  FacilityTimezone,
  Text,
  TopBar
} from '@/common/components'
import { CancelVisitRequest } from '@/visit/components/CancelVisitRequest'
// Constants
import { colors } from '@/common/constants'
// Hooks
import useRefetchOnFocus from '@/common/hooks/useRefetchOnFocus'
// Models
import {
  type StowlogIcon,
  VisitCheckinQuestionTypes,
  VisitRequestStatus,
  VisitSecurityCheckStatusEnum
} from '@/common/models'
import type { VisitStackParamList } from '@/visit/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePortalStore from '@/common/stores/usePortalStore'
// Use cases
import { getVisit } from '@/visit/useCases'
// Utils
import { formatDate, generateImageUrl, handleError } from '@/common/utils'
import { useEffect } from 'react'

type Props = StackScreenProps<VisitStackParamList, 'VisitDetail'>

const VisitDetail = ({ navigation, route }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { showModal } = usePortalStore((state) => ({
    showModal: state.showModal
  }))
  const { t } = useTranslation()
  const { top, bottom, left, right } = useSafeAreaInsets()

  const { data, refetch, isError, error } = useQuery({
    queryKey: ['visit', route.params.visitRequestUUID],
    queryFn: async () =>
      await getVisit({
        visitRequestId: route.params.visitRequestId,
        visitRequestUUID: route.params.visitRequestUUID,
        isApproved: route.params.isApproved
      })
  })

  useRefetchOnFocus([refetch])

  useEffect(() => {
    if (isError && error !== undefined) {
      handleError(error)
      navigation.goBack()
    }
  }, [isError, error])

  const dateKeys =
    data?.status === 'PENDING'
      ? ['checkIn', 'checkOut']
      : ['checkedInAt', 'checkedOutAt']

  return (
    <View style={{ flex: 1 }}>
      <TopBar
        style={{
          paddingTop: top,
          paddingLeft: left + 25,
          paddingRight: right + 25
        }}
        title={t('visit')}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack
        }}
        rightIcons={[
          ...(data !== undefined && data.status === VisitRequestStatus.PENDING
            ? [
                {
                  name: 'visit-cancelled' as StowlogIcon,
                  onPress: () => {
                    showModal({
                      content: (
                        <CancelVisitRequest
                          visitRequestUUID={data.uuid}
                          onCancel={() => {
                            navigation.goBack()
                          }}
                        />
                      )
                    })
                  }
                }
              ]
            : []),
          ...(data !== undefined &&
          route.params.isApproved &&
          'securityCheckStatus' in data &&
          data.securityCheckStatus === VisitSecurityCheckStatusEnum.DENIED
            ? [
                {
                  name: 'edit' as StowlogIcon,
                  onPress: () => {
                    navigation.navigate('EditPreVisitQuestions', {
                      visitId: data._id,
                      visitUUID: data.uuid,
                      visitQuestions: data.visitQuestions
                    })
                  }
                }
              ]
            : [])
        ]}
      />

      {data === undefined ? null : (
        <ScrollView
          bounces={false}
          contentContainerStyle={{
            paddingTop: 36,
            paddingLeft: left + 25,
            paddingRight: right + 25,
            paddingBottom: bottom + 25,
            gap: 24
          }}
        >
          <FacilityTimezone />

          <View style={styles.checkInCheckOut}>
            {dateKeys.map((key) => (
              <View style={{ flex: 1 }} key={key}>
                <Text variant="baseBold">
                  {t(
                    key === 'checkedIn' || key === 'checkedInAt'
                      ? 'checkin'
                      : 'checkout'
                  )}
                </Text>

                <Text variant="small" color={colors.placeholder}>
                  {data?.[key as keyof typeof data] !== undefined &&
                  data?.[key as keyof typeof data] !== null
                    ? formatDate(
                        new Date(data[key as keyof typeof data] as string),
                        'Pp',
                        currentFacility?.info.timezone
                      )
                    : '-'}
                </Text>
              </View>
            ))}
          </View>

          <View>
            <Text style={{ marginBottom: 8 }} variant="baseBold">
              {t('hosts')}
            </Text>

            <View style={{ gap: 8 }}>
              {data.hosts.map((host, index) => (
                <View key={index} style={styles.host}>
                  <Avatar
                    size={48}
                    initials={
                      host.firstName.charAt(0) + host.lastName.charAt(0)
                    }
                    {...(host.profileImage !== undefined && {
                      source: {
                        uri: generateImageUrl(host.profileImage, {
                          height: 48,
                          width: 48
                        })
                      }
                    })}
                  />

                  <View>
                    <Text variant="small">{`${host.firstName} ${host.lastName}`}</Text>

                    <Text variant="small" color={colors.placeholder}>
                      {host.email}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>

          {'questionAnswers' in data && data.questionAnswers.length > 0 && (
            <View>
              <Text style={{ marginBottom: 8 }} variant="baseBold">
                {t('checkinQuestionsAndAnswers')}
              </Text>

              <View style={{ gap: 8 }}>
                {data.questionAnswers.map((answer) => (
                  <View key={answer.question}>
                    <Text variant="smallBold">{answer.question}</Text>

                    {answer.type === VisitCheckinQuestionTypes.ATTACHMENT &&
                    answer.attachment !== undefined ? (
                      <AttachmentList attachments={[answer.attachment]} />
                    ) : (
                      <Text variant="small">{answer.selectedAnswer}</Text>
                    )}
                  </View>
                ))}
              </View>
            </View>
          )}

          {'cancellationReason' in data &&
            data.cancellationReason !== null &&
            data.cancellationReason.length > 0 && (
              <View>
                <Text style={{ marginBottom: 8 }} variant="baseBold">
                  {t('cancelationReason')}
                </Text>

                <Text variant="small">{data.cancellationReason}</Text>
              </View>
            )}

          {'deniedReason' in data &&
            data.deniedReason !== null &&
            data.deniedReason.length > 0 && (
              <View>
                <Text style={{ marginBottom: 8 }} variant="baseBold">
                  {t('denialReason')}
                </Text>

                <Text variant="small">{data.deniedReason}</Text>
              </View>
            )}

          {'visitQuestions' in data && data.visitQuestions.length > 0 && (
            <View>
              <Text style={{ marginBottom: 8 }} variant="baseBold">
                {t('checkinQuestionsAndAnswers')}
              </Text>

              <View style={{ gap: 8 }}>
                {data.visitQuestions.map(
                  ({
                    questionId,
                    translation: { question },
                    answer,
                    type,
                    attachment
                  }) => (
                    <View key={questionId}>
                      <Text variant="smallBold">{question}</Text>

                      {type === VisitCheckinQuestionTypes.ATTACHMENT &&
                      attachment !== undefined ? (
                        <AttachmentList attachments={[attachment]} />
                      ) : (
                        <Text variant="small">{answer}</Text>
                      )}
                    </View>
                  )
                )}

                {'securityCheckStatus' in data &&
                  data.securityCheckStatus ===
                    VisitSecurityCheckStatusEnum.DENIED &&
                  'securityDeniedReason' in data &&
                  data.securityDeniedReason !== undefined &&
                  data.securityDeniedReason !== null &&
                  data.securityDeniedReason.length > 0 && (
                    <View>
                      <Text style={{ marginBottom: 8 }} variant="baseBold">
                        {t('securityCheckDenialReason')}
                      </Text>

                      <Text variant="small">{data.securityDeniedReason}</Text>
                    </View>
                  )}
              </View>
            </View>
          )}
        </ScrollView>
      )}
    </View>
  )
}

export default VisitDetail

const styles = StyleSheet.create({
  checkInCheckOut: {
    flexDirection: 'row',
    gap: 8
  },
  host: {
    flexDirection: 'row',
    gap: 16
  }
})
