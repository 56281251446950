// External
import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { z } from 'zod'
// Components
import { Input, StepNavigationButtons } from '@/common/components'
import { ExtraQuestion } from '../ExtraQuestion'
import { PermitRichText } from '../PermitRichText'
// Constants
import type {
  AnsweredExtraQuestion,
  Machinery
} from '@/cspPermitToWork/constants'

interface Props {
  machinery: Machinery
  onSelect: (values: {
    machinery: Machinery
    specificMachinery?: string
    extraQuestion?: AnsweredExtraQuestion
  }) => void
  back: () => void
}

export const LastMachineryForm = ({ machinery, onSelect, back }: Props) => {
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      z
        .object({
          extraQuestion: z.any(),
          specificMachinery: z.string().optional()
        })
        .superRefine(({ specificMachinery }, ctx) => {
          if (
            machinery.mustSpecifyMachinery &&
            (specificMachinery === undefined || specificMachinery.length === 0)
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('requiredField'),
              path: ['specificMachinery']
            })
          }
        }),
    [machinery, t]
  )

  type FormValues = z.infer<typeof formSchema>

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema)
  })

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = methods

  const onSubmit = (values: FormValues) => {
    onSelect({
      machinery,
      ...values
    })
  }

  return (
    <>
      {machinery.mustSpecifyMachinery && (
        <Controller
          control={control}
          name="specificMachinery"
          render={({
            field: { onBlur, onChange, value },
            fieldState: { error }
          }) => (
            <Input
              label={t('specificMachinery')}
              placeholder={t('specifyTheMachinery')}
              errorMessage={error?.message}
              value={value}
              onChangeText={onChange}
              onBlur={onBlur}
            />
          )}
        />
      )}

      {machinery.extraQuestions[0] !== undefined && (
        <FormProvider {...methods}>
          <ExtraQuestion {...machinery.extraQuestions[0]} />
        </FormProvider>
      )}

      {machinery.applicationStandards !== undefined &&
        machinery.applicationStandards.length > 0 && (
          <PermitRichText
            style={styles.applicationStandards}
            title={t('applicationStandardsFor', { zone: machinery.name })}
            html={machinery.applicationStandards}
          />
        )}

      <StepNavigationButtons
        style={{ marginBottom: 25 }}
        backOnPress={back}
        nextOnPress={handleSubmit(onSubmit)}
        nextTitleTranslationKey="zoneOfMachinery"
        nextDisabled={isSubmitting}
      />
    </>
  )
}

const styles = StyleSheet.create({
  applicationStandards: {
    flex: 1,
    marginBottom: 25
  }
})
