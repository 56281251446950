import { Skeleton } from '@rneui/themed'

export const ProcedureSelectSkeleton = () => {
  return Array(11)
    .fill(0)
    .map((_, index, { length }) => (
      <Skeleton
        style={{ marginBottom: index < length ? 1 : 0, height: 45 }}
        animation="wave"
        key={index}
      />
    ))
}
