// External
import { StyleSheet, Pressable, View, type ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
// Components
import { Icon, Text } from '@/common/components'
import { EmailVerificationRequired } from './EmailVerificationRequired'
// Constants
import { colors } from '@/common/constants'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePortalStore from '@/common/stores/usePortalStore'
// Services
import { getVerificationCode } from '@/auth/services'

interface Props {
  style?: ViewStyle
}

export const EmailVerificationAlert = ({ style }: Props) => {
  const { user, currentUserType, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentUserType: state.currentUserType,
    currentFacility: state.currentFacility
  }))
  const { showModal } = usePortalStore((state) => ({
    showModal: state.showModal
  }))
  const { t } = useTranslation()

  if (
    user !== undefined &&
    currentUserType !== undefined &&
    currentFacility !== undefined &&
    !user.isEmailVerified
  ) {
    return (
      <Pressable
        style={[styles.container, style]}
        onPress={() => {
          void getVerificationCode(user.email)
          showModal({
            content: <EmailVerificationRequired />,
            dismissable: true
          })
        }}
      >
        <Icon
          style={{ marginTop: 4 }}
          name="alert"
          color={colors.white}
          size={32}
        />

        <View style={{ maxWidth: '82.5%' }}>
          <Text
            style={{
              color: colors.white
            }}
            variant="smallBold"
          >
            {t('emailNotVerified')}
          </Text>

          <Text style={{ color: colors.white }} variant="small">
            {t('pressHereToVerifyYourEmail')}
          </Text>
        </View>
      </Pressable>
    )
  }

  return null
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    paddingVertical: 10,
    backgroundColor: colors.error
  }
})
