export const getCursorValueString = (cursor?: {
  name: string
  [key: string]: any
  parents: Array<{
    name: string
    [key: string]: any
  }>
}) => {
  if (cursor === undefined) {
    return
  }

  if (cursor.parents.length === 0) {
    return cursor.name
  }

  return `${cursor.parents
    .map(({ name }) => name)
    .toReversed()
    .join(' > ')} > ${cursor.name}`
}
