// External
import { type TextStyle, View } from 'react-native'
// Components
import { Link } from './Link'
import { Text } from './Text'

interface Props {
  style?: TextStyle
  text: string
  linkText: string
  linkOnPress: () => void
}

export const TextAndLink = ({ style, text, linkText, linkOnPress }: Props) => {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'baseline'
        },
        style
      ]}
    >
      <Text>{`${text} `}</Text>

      <Link onPress={linkOnPress}>{linkText}</Link>
    </View>
  )
}
