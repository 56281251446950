// External
import { zodResolver } from '@hookform/resolvers/zod'
import type { StackScreenProps } from '@react-navigation/stack'
import { Input as RNEInput } from '@rneui/themed'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Platform, TouchableOpacity as RNTouchableOpacity } from 'react-native'
import { SheetManager } from 'react-native-actions-sheet'
import { TouchableOpacity as GHTouchableOpacity } from 'react-native-gesture-handler'
import { z } from 'zod'
// Components
import { Button, Icon, Input, Select, Text, TopBar } from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
import { checkinQuestionSchema } from '@/visit/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { Option } from '@/common/models'
import { VisitCheckinQuestionTypes } from '@/common/models'
import type { VisitStackParamList } from '@/visit/models'
// Use cases
import { editVisit } from '@/visit/useCases'
// Utils
import { handleError } from '@/common/utils'

const Wrapper =
  Platform.OS === 'android' ? RNTouchableOpacity : GHTouchableOpacity

const formSchema = z.object({
  visitQuestionAnswers: z.array(checkinQuestionSchema)
})

type FormValues = z.infer<typeof formSchema>

type Props = StackScreenProps<VisitStackParamList, 'EditPreVisitQuestions'>

const EditPreVisitQuestions = ({ navigation, route }: Props) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      visitQuestionAnswers: route.params.visitQuestions.map((question) => ({
        questionId: question.questionId,
        optional: question.optional,
        answer: question.answer,
        type: question.type,
        attachment: question.attachment
      }))
    }
  })

  const { fields } = useFieldArray<FormValues>({
    control,
    name: 'visitQuestionAnswers'
  })

  const onSubmit = async (values: FormValues) => {
    try {
      const answersToSubmit = values.visitQuestionAnswers
        .filter(
          (answer) =>
            answer.answer !== '' &&
            answer.answer !== undefined &&
            answer.type !== VisitCheckinQuestionTypes.ATTACHMENT
        )
        .map((answer) => ({
          questionId: answer.questionId,
          answer: answer.answer as string
        }))
      const attachmentsToUpload = values.visitQuestionAnswers
        .filter(
          (answer) =>
            answer.type === VisitCheckinQuestionTypes.ATTACHMENT &&
            answer.attachment !== undefined &&
            !('url' in answer.attachment)
        )
        .map((answer) => ({
          visitCheckinQuestionId: answer.questionId,
          attachment: answer.attachment
        }))
      await editVisit({
        visitId: route.params.visitId,
        visitUUID: route.params.visitUUID,
        visitQuestionAnswers: answersToSubmit,
        attachments: attachmentsToUpload
      })
      toast.success({
        data: {
          messageTranslationKey: 'theVisitHasBeenUpdated'
        }
      })
      navigation.goBack()
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <SafeArea style={{ marginHorizontal: 25 }}>
      <TopBar
        style={{ marginBottom: 36 }}
        title={t('editPreVisitQuestions')}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack
        }}
      />

      {fields.map((field, index) => {
        const checkinQuestion = route.params.visitQuestions[index]

        if (checkinQuestion === undefined) {
          return null
        }

        return (
          <Controller
            key={field.id}
            control={control}
            name={`visitQuestionAnswers.${index}`}
            render={({ field: { onChange, onBlur, value } }) => {
              const commonProps = {
                label: value.optional
                  ? t('optional', {
                      label: checkinQuestion.translation.question
                    })
                  : checkinQuestion.translation.question,
                value: value.answer,
                placeholder: checkinQuestion.translation.placeholder,
                errorMessage:
                  (errors.visitQuestionAnswers?.[index]?.answer
                    ?.message as string) ??
                  errors.visitQuestionAnswers?.[index]?.attachment?.message
              }

              if (checkinQuestion.type === VisitCheckinQuestionTypes.DROPDOWN) {
                return (
                  <Select
                    {...commonProps}
                    options={
                      checkinQuestion.translation.answers.map((answer) => ({
                        label: answer,
                        value: answer
                      })) as Option[]
                    }
                    onSelect={(option) => {
                      onChange({
                        ...value,
                        answer: option.value
                      })
                      onBlur()
                    }}
                  />
                )
              }

              if (
                checkinQuestion.type === VisitCheckinQuestionTypes.ATTACHMENT
              ) {
                let displayedFileName: string | undefined

                if (value.attachment !== undefined) {
                  if (
                    'name' in value.attachment &&
                    typeof value.attachment.name === 'string'
                  ) {
                    displayedFileName = value.attachment.name
                  } else if (
                    'fileName' in value.attachment &&
                    typeof value.attachment.fileName === 'string'
                  ) {
                    displayedFileName = value.attachment.fileName
                  } else if (
                    'uri' in value.attachment &&
                    typeof value.attachment.uri === 'string'
                  ) {
                    displayedFileName = `${new Date().toDateString()}.${String(
                      value.attachment.uri
                    )
                      .split('.')
                      .pop()!}`
                  }
                }

                return (
                  <>
                    <Text variant="label">{commonProps.label}</Text>

                    <Wrapper
                      onPress={() => {
                        void SheetManager.show('upload-options', {
                          payload: {
                            onImagePickerAsset: (asset) => {
                              onChange({
                                ...value,
                                attachment: asset
                              })
                              void SheetManager.hide('upload-options')
                            },
                            onDocumentPickerAsset: (asset) => {
                              onChange({
                                ...value,
                                attachment: asset
                              })
                              void SheetManager.hide('upload-options')
                            }
                          }
                        })
                      }}
                    >
                      <RNEInput
                        placeholder={commonProps.placeholder}
                        errorMessage={commonProps.errorMessage}
                        inputContainerStyle={{
                          borderColor:
                            commonProps.errorMessage !== undefined
                              ? colors.error
                              : 'transparent'
                        }}
                        editable={false}
                        rightIcon={<Icon name="add-file" size={20} />}
                        value={displayedFileName}
                      />
                    </Wrapper>
                  </>
                )
              }

              return (
                <Input
                  {...commonProps}
                  onChangeText={(text) => {
                    onChange({
                      ...value,
                      answer: text
                    })
                  }}
                  onBlur={onBlur}
                />
              )
            }}
          />
        )
      })}

      <Button
        disabled={isSubmitting}
        loading={isSubmitting}
        style={{ marginTop: 'auto' }}
        title={t('updateAndSubmit')}
        onPress={handleSubmit(onSubmit)}
      />
    </SafeArea>
  )
}

export default EditPreVisitQuestions
