// External
import { StyleSheet, Pressable, Keyboard, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { type StackScreenProps } from '@react-navigation/stack'
// Components
import {
  Text,
  ProgressBar,
  PhoneNumberInput,
  StepNavigationButtons
} from '@/common/components'
// Constants
import { phoneNumberRegEx } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
import useCreateAccountStore from '@/auth/stores/useCreateAccountStore'
// Models
import { type PreHomeStackParamList } from '@/common/models'

type Props = StackScreenProps<PreHomeStackParamList, 'CreateAccountStep3'>

const CreateAccountStep3 = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { phoneNumber, setFullPhoneNumber } = useCreateAccountStore(
    (state) => ({
      phoneNumber: state.phoneNumber,
      setFullPhoneNumber: state.setFullPhoneNumber
    })
  )

  const validationSchema = z.object({
    prefix: z.string().min(1),
    phone: z
      .string()
      .min(1, { message: t('provideMobileNumber') })
      .regex(phoneNumberRegEx, { message: t('phoneNumberRegExError') })
  })

  type FormValues = z.infer<typeof validationSchema>

  const onSubmit = ({ prefix, phone }: FormValues) => {
    setFullPhoneNumber(prefix, phone)
    navigation.navigate('CreateAccountStep4')
  }

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      prefix: phoneNumber.prefix !== '' ? phoneNumber.prefix : '34',
      phone: phoneNumber.phone
    }
  })

  const children = (
    <>
      <ProgressBar style={{ marginTop: 11 }} value={0.6} />

      <Text variant="h2Bold" style={styles.header}>
        {t('mobileNumber')}
      </Text>

      <Text>{t('fillPhoneNumber')}</Text>

      <Text style={{ marginBottom: 25 }}>{t('whatYouSeeIn')}</Text>

      <PhoneNumberInput
        prefixValue={watch('prefix')}
        value={watch('phone')}
        onChangePrefix={(option) => {
          setValue('prefix', option.value)
        }}
        onChangePhone={(phone) => {
          setValue('phone', phone)
        }}
        errorMessage={errors.phone?.message}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </>
  )

  return (
    <SafeArea style={styles.container} withBackground>
      {Platform.OS !== 'web' ? (
        <Pressable
          style={{ flex: 1 }}
          onPress={() => {
            Keyboard.dismiss()
          }}
        >
          {children}
        </Pressable>
      ) : (
        children
      )}
    </SafeArea>
  )
}

export default CreateAccountStep3

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 25,
    paddingBottom: 25
  },
  header: {
    marginTop: 48,
    marginBottom: 8
  }
})
