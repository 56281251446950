// External
import {
  addMilliseconds,
  addMinutes,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  isValid
} from 'date-fns'
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
// Components
import { Checkbox, DateInput, Input, Select, Text } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
import type { AnsweredExtraQuestion } from '@/cspPermitToWork/constants'
import { checkInTimeValues } from '@/visit/constants'
// Models
import { CSPPermitToWorkExtraQuestionTypes } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'
// Utils
import { getCheckInTimeValues } from '@/visit/utils'

interface Props {
  id: string
  placeholder?: string
  question: string
  type: CSPPermitToWorkExtraQuestionTypes
}

export const ExtraQuestion = ({ id, placeholder, question, type }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { values } = useNewRequestStore((state) => ({
    values: state.values
  }))
  const [renderConditionalDatePicker, setRenderConditionalDatePicker] =
    useState(false)
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<{
    extraQuestion?: AnsweredExtraQuestion
  }>()

  const getOnDayPressDate = useCallback(
    (data: { year: number; month: number; day: number }, timezone: string) => {
      const baseDate = zonedTimeToUtc(
        new Date(data.year, data.month - 1, data.day),
        timezone
      )
      const now = new Date()

      if (isSameDay(baseDate, now)) {
        const totalMinutes = getMinutes(now) + getHours(now) * 60

        if (totalMinutes <= 8 * 60) {
          return addMinutes(baseDate, 8 * 60)
        } else {
          const checkInTimeValue = checkInTimeValues.find(
            ({ value }) => parseInt(value) >= totalMinutes
          )
          if (checkInTimeValue !== undefined) {
            return addMinutes(baseDate, parseInt(checkInTimeValue.value))
          }
        }
      } else {
        return addMinutes(baseDate, 8 * 60)
      }
    },
    []
  )

  const getSelectValue = useCallback((value: Date) => {
    const base = addMilliseconds(
      value,
      getTimezoneOffset(currentFacility?.info.timezone as string)
    )
    const hours = base.getUTCHours()
    const minutes = base.getUTCMinutes()
    return (hours * 60 + minutes).toString()
  }, [])

  useEffect(() => {
    if (type === CSPPermitToWorkExtraQuestionTypes.CHECKBOX) {
      setValue(
        'extraQuestion',
        {
          answer: 'false',
          extraQuestionId: id,
          question
        },
        { shouldDirty: true }
      )
    }
  }, [])

  if (type === CSPPermitToWorkExtraQuestionTypes.CHECKBOX) {
    return (
      <Controller
        control={control}
        name="extraQuestion"
        render={({
          field: { onBlur, onChange, value },
          fieldState: { error }
        }) => (
          <View>
            <View style={styles.checkboxContainer}>
              <Text variant="label">{question}</Text>

              <Checkbox
                checked={value?.answer === 'true'}
                onPress={() => {
                  onChange({
                    answer: value?.answer === 'true' ? 'false' : 'true',
                    extraQuestionId: id,
                    question
                  })
                  onBlur()
                }}
              />
            </View>

            <Text
              style={{ margin: 5 }}
              variant="extraSmall"
              color={colors.error}
            >
              {error?.message}
            </Text>
          </View>
        )}
      />
    )
  }

  if (type === CSPPermitToWorkExtraQuestionTypes.CONDITIONAL_DATE) {
    return (
      <Controller
        control={control}
        name="extraQuestion"
        render={({
          field: { onBlur, onChange, value },
          fieldState: { error }
        }) => (
          <View>
            <View style={styles.checkboxContainer}>
              <Text variant="label">{question}</Text>

              <Checkbox
                checked={renderConditionalDatePicker}
                onPress={() => {
                  setRenderConditionalDatePicker((prev) => !prev)
                }}
              />
            </View>

            {renderConditionalDatePicker && (
              <>
                <DateInput
                  style={{ marginTop: 16 }}
                  label={t('date')}
                  onDayPress={(data) => {
                    const date = getOnDayPressDate(
                      data,
                      currentFacility?.info.timezone as string
                    )
                    onChange({
                      answer: date?.toISOString(),
                      extraQuestionId: id,
                      question
                    })
                    onBlur()
                  }}
                  placeholder={t('selectADate')}
                  errorMessage={error?.message}
                  value={
                    value?.answer !== undefined
                      ? new Date(value?.answer)
                      : undefined
                  }
                />

                <Select
                  value={
                    value?.answer !== undefined &&
                    isValid(new Date(value.answer))
                      ? getSelectValue(new Date(value?.answer))
                      : undefined
                  }
                  disabled={value?.answer === undefined}
                  label={t('time')}
                  options={
                    value?.answer !== undefined
                      ? getCheckInTimeValues(
                          isSameDay(new Date(value?.answer), new Date())
                        )
                      : checkInTimeValues
                  }
                  placeholder={t('selectATime')}
                  errorMessage={error?.message}
                  onSelect={(option) => {
                    if (value?.answer === undefined) {
                      return
                    }

                    const baseDate = zonedTimeToUtc(
                      new Date(
                        getYear(new Date(value.answer)),
                        getMonth(new Date(value.answer)),
                        getDate(new Date(value.answer))
                      ),
                      currentFacility?.info.timezone as string
                    )
                    onChange({
                      answer: addMinutes(
                        baseDate,
                        parseInt(option.value)
                      ).toISOString(),
                      extraQuestionId: id,
                      question
                    })
                    onBlur()
                  }}
                />
              </>
            )}
          </View>
        )}
        rules={{
          validate: (value) => {
            if (value?.answer === undefined && renderConditionalDatePicker) {
              return t('requiredField')
            }

            if (value?.answer !== undefined) {
              if (
                values?.startDate !== undefined &&
                values?.endDate !== undefined &&
                (isBefore(new Date(value.answer), new Date(values.startDate)) ||
                  isAfter(new Date(value.answer), new Date(values.endDate)))
              ) {
                return t('dateAndTimeIsOutsideOfPermitPeriod')
              }
            }
          }
        }}
      />
    )
  }

  return (
    <Controller
      control={control}
      name="extraQuestion"
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error }
      }) => (
        <Input
          label={question}
          placeholder={placeholder}
          errorMessage={error?.message}
          value={value?.answer}
          onChangeText={(text) => {
            onChange({
              answer: text,
              extraQuestionId: id,
              question
            })
          }}
          onBlur={onBlur}
        />
      )}
      rules={{
        validate: (value) => {
          if (value === undefined || value.answer.length === 0) {
            return t('requiredField')
          }
        }
      }}
    />
  )
}

const styles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
