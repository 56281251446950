import { CSPPermitToWorkExtraQuestionTypes } from '@/common/models'
import { z } from 'zod'

export const extraQuestionSchema = z.object({
  id: z.string(),
  placeholder: z.string().optional(),
  question: z.string(),
  type: z.nativeEnum(CSPPermitToWorkExtraQuestionTypes)
})

export type ExtraQuestion = z.infer<typeof extraQuestionSchema>
