import i18n from '@/common/config/i18n'
import { z } from 'zod'

const { t } = i18n

const baseEquipmentSchema = z.object(
  {
    applicationStandards: z.string().optional(),
    applicationProcedures: z.string().optional(),
    id: z.string(),
    isLast: z.boolean(),
    mustSelectWorker: z.boolean(),
    name: z.string(),
    requiredDocuments: z.string().optional()
  },
  { message: t('requiredField') }
)

export const equipmentSchema = baseEquipmentSchema.extend({
  parents: z.array(baseEquipmentSchema)
})

export type Equipment = z.infer<typeof equipmentSchema>
