// External
import type { StackScreenProps } from '@react-navigation/stack'
import { Tab } from '@rneui/themed'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
// Components
import { Button, ProgressBar, Text } from '@/common/components'
// Constants
import { alert } from '@/common/constants'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
// Stores
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'

type Props = StackScreenProps<CSPPTWStackParamList, 'NewRequestStep3'>

const NewRequestStep3 = ({ navigation }: Props) => {
  const {
    values: currentValues,
    resetZone,
    resetMachinery
  } = useNewRequestStore((state) => ({
    values: state.values,
    resetZone: state.resetZone,
    resetMachinery: state.resetMachinery
  }))
  const { t } = useTranslation()
  const [tabWidth, setTabWidth] = useState<number>()
  const isRTL = useIsRTL()

  return (
    <SafeArea
      style={styles.container}
      onLayout={({ nativeEvent }) => {
        setTabWidth(nativeEvent.layout.width)
      }}
    >
      <ProgressBar style={styles.progressBar} value={0.3333} />

      <Text variant="h2Bold" style={{ marginBottom: 8 }}>
        {t('zoneOrMachinery')}
      </Text>

      <Text style={{ marginBottom: 25 }}>{t('selectTheZoneOrMachinery')}</Text>

      <Tab
        scrollable
        style={{
          marginTop: 25
        }}
        value={3}
        onChange={(value) => {
          if (currentValues === undefined) {
            return
          }

          if (value === 0) {
            if (
              currentValues.machinery !== undefined &&
              currentValues.machinery.length > 0
            ) {
              alert(t('selectionChange'), t('youPreviouslySelectedMachinery'), [
                {
                  text: t('cancel'),
                  style: 'cancel'
                },
                {
                  text: t('continue'),
                  style: 'destructive',
                  onPress: () => {
                    resetMachinery()
                    navigation.navigate('NewRequestStep4Zone')
                  }
                }
              ])
              return
            }

            navigation.navigate('NewRequestStep4Zone')
            return
          }

          if (currentValues.zone !== undefined) {
            alert(t('selectionChange'), t('youPreviouslySelectedZone'), [
              {
                text: t('cancel'),
                style: 'cancel'
              },
              {
                text: t('continue'),
                style: 'destructive',
                onPress: () => {
                  resetZone()
                  navigation.navigate('NewRequestStep4Machinery')
                }
              }
            ])
            return
          }

          navigation.navigate('NewRequestStep4Machinery')
        }}
      >
        <Tab.Item
          style={{ width: tabWidth !== undefined ? tabWidth / 2 : 'auto' }}
          title={t('selectZone')}
        />

        <Tab.Item
          style={{
            marginLeft: 8,
            width: tabWidth !== undefined ? tabWidth / 2 - 8 : 'auto'
          }}
          title={t('selectMachinery')}
        />
      </Tab>

      <Button
        style={styles.backButton}
        compact
        type="secondary"
        onPress={navigation.goBack}
        iconName={isRTL ? 'next' : 'back'}
      />
    </SafeArea>
  )
}

export default NewRequestStep3

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 25,
    marginBottom: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  backButton: {
    marginTop: 'auto',
    alignSelf: 'flex-start'
  }
})
