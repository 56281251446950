// External
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
// Components
import { Text } from './Text'
// Models
import type { ExtendedPermitStatus } from '@/common/models'
import { differenceInMilliseconds } from 'date-fns'

interface Props {
  status: Extract<ExtendedPermitStatus, 'ACTIVE' | 'QUEUED'>
  startDate: string
  endDate: string
}

export const PermitCardTimer = memo(({ status, startDate, endDate }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setTimer] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const renderTime = () => {
    const now = new Date()
    const date = new Date(status === 'ACTIVE' ? endDate : startDate)
    const ms = differenceInMilliseconds(date, now)
    let firstValue: number
    let firstUnit: string
    let secondValue: number
    let secondUnit: string
    const totalHours = Math.floor(ms / (60 * 60 * 1000))

    if (ms > 24 * 60 * 60 * 1000) {
      firstValue = Math.floor(totalHours / 24)
      firstUnit = t('d')
      secondValue = totalHours % 24
      secondUnit = t('h')
    } else {
      firstValue = totalHours
      firstUnit = t('h')
      secondValue = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000))
      secondUnit = t('m')
    }

    return (
      <View
        style={{
          flexDirection: 'row',
          marginStart: 10,
          alignItems: 'flex-end'
        }}
      >
        <Text variant="largeBold">
          {firstValue < 10 ? `0${firstValue}` : firstValue}
        </Text>

        <Text>{firstUnit}</Text>

        <Text style={{ marginStart: 10 }} variant="largeBold">
          {secondValue < 10 ? `0${secondValue}` : secondValue}
        </Text>

        <Text>{secondUnit}</Text>
      </View>
    )
  }

  return (
    <View>
      <Text style={{ marginBottom: 'auto' }}>
        {t(status === 'ACTIVE' ? t('toFinish') : t('toStart'))}
      </Text>

      {renderTime()}
    </View>
  )
})
