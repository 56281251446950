// External
import { Platform } from 'react-native'
// Config
import { client } from '@/common/config'
// Models
import type { Asset } from '@/common/models'
// Utils
import { getAndroidFileType, getWebFile } from '@/common/utils'

interface Params {
  requestId: string
  attachments: Asset[]
}

export const uploadPermitRequestAttachments = async ({
  requestId,
  attachments
}: Params) => {
  try {
    let requestAttachments: any

    if (Platform.OS === 'web') {
      const promises = attachments.map(
        async (attachment) =>
          await getWebFile({
            asset: attachment,
            fallbackName: new Date().toDateString()
          })
      )
      requestAttachments = await Promise.all(promises)
    } else {
      requestAttachments = attachments.map((attachment) => {
        const isDocumentPickerAsset = 'name' in attachment

        return {
          name: isDocumentPickerAsset
            ? attachment.name.split('.')[0]
            : attachment.fileName ?? new Date().toDateString(),
          type:
            Platform.OS === 'android'
              ? getAndroidFileType(attachment)
              : isDocumentPickerAsset
              ? attachment.mimeType
              : attachment.type,
          uri:
            Platform.OS === 'ios'
              ? attachment.uri.replace('file://', '')
              : attachment.uri
        }
      })
    }

    const body = new FormData()

    // @ts-expect-error - attachment implicitly has an 'any' type
    requestAttachments.forEach((attachment) => {
      body.append('attachments', attachment)
    })

    const { error } = await client.POST(
      '/api/app/csp-permit-to-work/requests/{requestId}/attachments',
      {
        params: {
          path: {
            requestId
          }
        },
        // @ts-expect-error - body doesn't match OpenAPI's generated type
        body,
        // ! will not work if this line is removed
        bodySerializer: (body) => body
      }
    )

    if (error !== undefined) {
      console.error('Error uploading permit request attachments:', error)
      return await Promise.reject(error)
    }
  } catch (error) {
    console.error('Error uploading permit request attachments:', error)
    return await Promise.reject(error)
  }
}
