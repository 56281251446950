// External
import { useRef } from 'react'
import { StyleSheet, type TextInput } from 'react-native'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { type StackScreenProps } from '@react-navigation/stack'
// Components
import {
  Text,
  ProgressBar,
  Input,
  StepNavigationButtons
} from '@/common/components'
// Constants
import {
  numbersRegex,
  uppercaseRegex,
  lowercaseRegex,
  symbolRegex,
  noSpaceRegex
} from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
import useCreateAccountStore from '@/auth/stores/useCreateAccountStore'
// Models
import { type PreHomeStackParamList } from '@/common/models'

type Props = StackScreenProps<PreHomeStackParamList, 'CreateAccountStep2'>

const CreateAccountStep2 = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { password, setPassword } = useCreateAccountStore((state) => ({
    password: state.password,
    setPassword: state.setPassword
  }))
  const repeatPasswordInputRef = useRef<TextInput>(null)

  const validationSchema = z
    .object({
      password: z
        .string()
        .min(8, { message: t('passwordLengthError', { length: 8 }) })
        .regex(noSpaceRegex, { message: t('passwordSpaceError') })
        .regex(uppercaseRegex, { message: t('passwordUppercaseError') })
        .regex(lowercaseRegex, { message: t('passwordLowercaseError') })
        .regex(numbersRegex, { message: t('passwordNumberError') })
        .regex(symbolRegex, { message: t('passwordSymbolError') }),
      repeatPassword: z.string()
    })
    .refine((data) => data.password === data.repeatPassword, {
      message: t('passwordsMustMatch'),
      path: ['repeatPassword']
    })

  type FormValues = z.infer<typeof validationSchema>

  const onSubmit = ({ password: formPassword }: FormValues) => {
    setPassword(formPassword)
    navigation.navigate('CreateAccountStep3')
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      password,
      repeatPassword: password
    }
  })

  return (
    <SafeArea style={styles.container} withBackground>
      <ProgressBar style={{ marginTop: 11 }} value={0.4} />

      <Text variant="h2Bold" style={styles.header}>
        {t('createPassword')}
      </Text>

      <Text style={{ marginBottom: 25 }}>{t('passwordShouldContain')}</Text>

      <Controller
        control={control}
        name="password"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            secureTextEntry
            placeholder={t('yourPassword')}
            onChangeText={onChange}
            onSubmitEditing={() => {
              repeatPasswordInputRef.current?.focus()
            }}
            onBlur={onBlur}
            value={value}
            returnKeyType="next"
            autoComplete="password"
            autoCapitalize="none"
            errorMessage={errors.password?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="repeatPassword"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            ref={repeatPasswordInputRef}
            secureTextEntry
            placeholder={t('repeatYourPassword')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(onSubmit)}
            onBlur={onBlur}
            value={value}
            returnKeyType="done"
            autoComplete="password"
            autoCapitalize="none"
            errorMessage={errors.repeatPassword?.message}
          />
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default CreateAccountStep2

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 25,
    marginBottom: 25
  },
  header: {
    marginTop: 48,
    marginBottom: 8
  }
})
