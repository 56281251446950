// External
import type { StackScreenProps } from '@react-navigation/stack'
import { Divider } from '@rneui/themed'
import { useQuery } from '@tanstack/react-query'
import uniqBy from 'lodash/uniqBy'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  RefreshControl,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import {
  FacilityTimezone,
  Input,
  Link,
  Text,
  TopBar
} from '@/common/components'
import {
  ExtraQuestionAnswer,
  PermitAttachments,
  PermitCompany,
  PermitPersonCard,
  PermitPersonnel,
  PermitRichText,
  PermitSignatures,
  PermitStatus,
  PermitSubcontractedCompanies,
  ProceduresAndStandards
} from '@/cspPermitToWork/components'
import { PermitDetailSkeleton } from '@/permitToWork/components'
// Constants
import { colors, toast } from '@/common/constants'
// Hooks
import useRefetchOnFocus from '@/common/hooks/useRefetchOnFocus'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
// Services
import { closePermit } from '@/cspPermitToWork/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { getPermitDetail } from '@/cspPermitToWork/useCases'
// Utils
import { CSPPermitToWorkRequestStatusEnum } from '@/common/models'
import {
  callSOS,
  formatDate,
  getExtendedPermitStatus,
  handleError
} from '@/common/utils'

type Props = StackScreenProps<CSPPTWStackParamList, 'PermitDetail'>

const PermitDetail = ({ navigation, route }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const [subcontractedCompanies, setSubcontractedCompanies] = useState<any[]>(
    []
  )
  const [closing, setClosing] = useState(false)
  const { t } = useTranslation()
  const { top, right, left, bottom } = useSafeAreaInsets()
  const { height } = useWindowDimensions()

  const { id, seq } = route.params

  const {
    data: detailData,
    refetch: refetchDetail,
    isRefetching: isRefetchingDetail,
    isLoading: isLoadingDetail
  } = useQuery({
    queryKey: ['cspPermitDetail', id],
    queryFn: async () => await getPermitDetail(id)
  })

  useRefetchOnFocus([refetchDetail])

  useEffect(() => {
    if (
      detailData !== undefined &&
      detailData.detail.subcontractors.length > 0
    ) {
      const companies = uniqBy(
        detailData.detail.subcontractors.map((subcontractor) => ({
          id: subcontractor.companyId,
          name: subcontractor.companyName,
          uid: subcontractor.companyUid
        })),
        'id'
      )
      setSubcontractedCompanies(companies)
    }
  }, [detailData])

  if (detailData === undefined || isLoadingDetail) {
    return (
      <SafeArea>
        <TopBar
          style={styles.topBar}
          title={t('requestID', { id: seq })}
          leftIcon={{
            name: 'close',
            onPress: navigation.goBack
          }}
        />

        <PermitDetailSkeleton />
      </SafeArea>
    )
  }

  const { detail, signatures, attachments } = detailData
  const extendedStatus = getExtendedPermitStatus({
    status: detail.status,
    startDate: detail.startDate,
    endDate: detail.endDate
  })
  const status =
    extendedStatus === CSPPermitToWorkRequestStatusEnum.REJECTED
      ? CSPPermitToWorkRequestStatusEnum.PENDING
      : extendedStatus

  return (
    <View
      style={{
        flex: 1,
        paddingTop: top,
        paddingRight: right,
        paddingLeft: left
      }}
    >
      <TopBar
        style={styles.topBar}
        title={t('requestID', { id: seq })}
        leftIcon={{
          name: 'close',
          onPress: navigation.goBack
        }}
        rightIcons={[
          {
            name: 'sos',
            onPress: () => {
              // eslint-disable-next-line
              void callSOS(currentFacility!)
            },
            color: 'error'
          }
        ]}
      />

      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 25,
          paddingBottom: bottom + height * 0.25
        }}
        refreshControl={
          <RefreshControl
            onRefresh={() => {
              void refetchDetail()
            }}
            refreshing={isRefetchingDetail}
            progressViewOffset={-15}
          />
        }
      >
        <Divider style={{ marginVertical: 20 }} />

        <View style={styles.dateAndStatus}>
          <View style={styles.createdAt}>
            <Text variant="small">
              {t('requestCreatedAt', {
                date: formatDate(
                  new Date(detail.createdAt),
                  'Pp',
                  currentFacility?.info.timezone
                )
              })}
            </Text>
          </View>

          <PermitStatus compact status={status} />
        </View>

        <FacilityTimezone style={{ marginTop: 12 }} />

        <Divider style={{ marginVertical: 20 }} />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 12
          }}
        >
          <Input
            style={{ flex: 1 }}
            disabled
            label={t('startDate')}
            value={formatDate(
              new Date(detail.startDate),
              'Pp',
              currentFacility?.info.timezone
            )}
          />

          <Input
            style={{ flex: 1 }}
            disabled
            label={t('endDate')}
            value={formatDate(
              new Date(detail.endDate),
              'Pp',
              currentFacility?.info.timezone
            )}
          />
        </View>

        <Input disabled label={t('description')} value={detail.description} />

        {detail.status === CSPPermitToWorkRequestStatusEnum.APPROVED && (
          <Link
            style={{ alignSelf: 'center' }}
            onPress={async () => {
              try {
                setClosing(true)
                await closePermit(detail.id)
                toast.success({
                  data: {
                    messageTranslationKey: 'permitClosed'
                  }
                })
                await refetchDetail()
              } catch (error) {
                handleError(error)
              } finally {
                setClosing(false)
              }
            }}
            color={colors.rajah}
            icon={{
              name: 'clipboard-check',
              size: 16
            }}
            disabled={closing}
          >
            {t('closePermit')}
          </Link>
        )}

        <Divider style={{ marginVertical: 20 }} />

        <PermitCompany style={{ marginBottom: 30 }} company={detail.company} />

        {subcontractedCompanies.length > 0 && (
          <PermitSubcontractedCompanies
            style={{ marginBottom: 30 }}
            companies={subcontractedCompanies}
          />
        )}

        <PermitPersonCard
          style={{ marginBottom: 30 }}
          firstName={detail.cspManager.firstName}
          lastName={detail.cspManager.lastName}
          email={detail.cspManager.email}
          title={t('cspManager')}
          profileImage={detail.cspManager.profileImage}
        />

        <PermitPersonCard
          firstName={detail.preventiveContractor.firstName}
          lastName={detail.preventiveContractor.lastName}
          email={detail.preventiveContractor.email}
          title={t('preventiveOfficer')}
          profileImage={detail.preventiveContractor.profileImage}
        />

        <Divider style={{ marginVertical: 20 }} />

        {detail.zone !== undefined && (
          <View>
            <Input
              label={t('zone')}
              disabled
              value={detail.zone.fullPath}
              renderErrorMessage={false}
            />

            {detail.zone.specificZone !== undefined && (
              <Text variant="smallBold">
                {t('specificZone')}
                <Text
                  variant="small"
                  color={colors.placeholder}
                >{` ${detail.zone.specificZone}`}</Text>
              </Text>
            )}

            {detail.zone.questionAnswers?.map((answer) => (
              <ExtraQuestionAnswer
                key={answer.extraQuestionId}
                question={answer.questionDetails?.question}
                answer={answer.answer}
              />
            ))}
          </View>
        )}

        {detail.machinery.map.length > 0 && (
          <View style={{ gap: 4 }}>
            {detail.machinery.map((machinery, index) => (
              <View key={machinery.machineId}>
                <Input
                  disabled
                  label={t('selectedMachinery', { index: index + 1 })}
                  value={machinery.fullPath}
                  renderErrorMessage={false}
                />

                {machinery.specificZone !== undefined && (
                  <Text variant="smallBold">
                    {t('specificMachinery')}
                    <Text
                      variant="small"
                      color={colors.placeholder}
                    >{` ${machinery.specificZone}`}</Text>
                  </Text>
                )}

                {machinery.machineryZoneName !== undefined && (
                  <Text variant="smallBold">
                    {t('machineryZone')}
                    <Text
                      variant="small"
                      color={colors.placeholder}
                    >{` ${machinery.machineryZoneName}`}</Text>
                  </Text>
                )}

                {machinery.specificMachineryZoneName !== undefined && (
                  <Text variant="smallBold">
                    {t('specificZone')}
                    <Text
                      variant="small"
                      color={colors.placeholder}
                    >{` ${machinery.specificMachineryZoneName}`}</Text>
                  </Text>
                )}

                {machinery.questionAnswers?.map((answer) => (
                  <ExtraQuestionAnswer
                    key={answer.extraQuestionId}
                    question={answer.questionDetails?.question}
                    answer={answer.answer}
                  />
                ))}
              </View>
            ))}
          </View>
        )}

        <Divider style={{ marginVertical: 20 }} />

        <View
          style={{
            gap: 4,
            marginBottom: detail.workingMode.length > 0 ? 30 : 0
          }}
        >
          {detail.typeOfWorks.map((work, index) => (
            <View key={work.typeOfWorkId}>
              <Input
                disabled
                label={t('selectedWork', { index: index + 1 })}
                value={work.fullPath}
                renderErrorMessage={false}
              />

              {work.specificWork !== undefined && (
                <Text variant="smallBold">
                  {t('specificWork')}
                  <Text variant="small" color={colors.placeholder}>
                    {` ${work.specificWork}`}
                  </Text>
                </Text>
              )}
            </View>
          ))}
        </View>

        {detail.workingMode.length > 0 && (
          <PermitRichText title={t('workMethod')} html={detail.workingMode} />
        )}

        <Divider style={{ marginVertical: 20 }} />

        {(detail.drtObservations.length > 0 ||
          detail.prlObservations.length > 0) && (
          <>
            {detail.drtObservations.length > 0 && (
              <PermitRichText
                style={{
                  marginBottom: detail.prlObservations.length > 0 ? 30 : 0
                }}
                title={t('drtObservations')}
                html={detail.drtObservations}
              />
            )}

            {detail.prlObservations.length > 0 && (
              <PermitRichText
                title={t('prlObservations')}
                html={detail.prlObservations}
              />
            )}

            <Divider style={{ marginVertical: 20 }} />
          </>
        )}

        {detail.drtActions.length > 0 && (
          <>
            <PermitRichText
              title={t('actionsToBeCarriedOutByDRT')}
              html={detail.drtActions}
            />

            <Divider style={{ marginVertical: 20 }} />
          </>
        )}

        <View style={{ gap: 4 }}>
          {detail.additionalEquipment.map((equipment, index) => (
            <View key={equipment.equipmentId}>
              <Input
                disabled
                label={t('selectedEquipment', { index: index + 1 })}
                value={equipment.fullPath}
                renderErrorMessage={false}
              />

              {equipment.workers?.[0] !== undefined && (
                <Text variant="smallBold">
                  {t('personnelInCharge')}
                  <Text variant="small" color={colors.placeholder}>
                    {` ${equipment.workers[0].firstName} ${equipment.workers[0].lastName}`}
                  </Text>
                </Text>
              )}
            </View>
          ))}
        </View>

        <Divider style={{ marginVertical: 20 }} />

        <PermitPersonnel
          primaryContractors={detail.primaryContractors}
          subcontractors={detail.subcontractors}
        />

        <Divider style={{ marginVertical: 20 }} />

        <PermitAttachments
          attachments={attachments}
          requestId={detail.id}
          status={detail.status}
          requiredDocuments={detail.requiredDocuments}
          refetch={refetchDetail}
        />

        <Divider style={{ marginVertical: 20 }} />

        <ProceduresAndStandards
          applicationProcedures={detail.applicationProcedures}
          applicationStandards={detail.applicationStandards}
          goodPractices={detail.goodPractices}
        />
      </ScrollView>

      <PermitSignatures permit={detailData.detail} signatures={signatures} />
    </View>
  )
}

export default PermitDetail

const styles = StyleSheet.create({
  topBar: {
    marginBottom: 27,
    marginHorizontal: 25
  },
  createdAt: {
    paddingHorizontal: 10,
    paddingVertical: 6.5,
    backgroundColor: '#FAFAFA',
    borderRadius: 7,
    marginEnd: 'auto'
  },
  dateAndStatus: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8
  },
  textBox: {
    minHeight: 52,
    borderRadius: 7,
    backgroundColor: colors.inactive
  }
})
