import type { TextVariant } from '@/common/models'
import type { TextStyle } from 'react-native'

const baseBold = {
  fontFamily: 'PlusJakartaSans_700Bold',
  fontSize: 15,
  lineHeight: 22,
  letterSpacing: 0.38
}

export const textBaseStylesMap: Record<TextVariant, TextStyle> = {
  extraSmall: {
    fontSize: 12,
    fontFamily: 'PlusJakartaSans_400Regular',
    fontWeight: '400',
    lineHeight: 13,
    letterSpacing: 0.07
  },
  small: {
    fontSize: 13,
    fontFamily: 'PlusJakartaSans_400Regular',
    fontWeight: '400',
    lineHeight: 21,
    letterSpacing: 0.35
  },
  smallBold: {
    fontSize: 13,
    fontFamily: 'PlusJakartaSans_700Bold',
    lineHeight: 21,
    letterSpacing: 0.35
  },
  base: {
    fontFamily: 'PlusJakartaSans_400Regular',
    fontSize: 15,
    lineHeight: 21,
    letterSpacing: 0.35
  },
  baseBold,
  link: baseBold,
  button: {
    fontFamily: 'PlusJakartaSans_500Medium',
    fontWeight: '500',
    fontSize: 15,
    lineHeight: 21,
    letterSpacing: 0.35
  },
  buttonBold: baseBold,
  dropdownOption: baseBold,
  medium: {
    fontFamily: 'PlusJakartaSans_400Regular',
    fontSize: 17,
    lineHeight: 21,
    letterSpacing: 0.35
  },
  mediumBold: {
    fontFamily: 'PlusJakartaSans_700Bold',
    fontSize: 17,
    lineHeight: 21,
    letterSpacing: 0.35
  },
  largeBold: {
    fontFamily: 'PlusJakartaSans_700Bold',
    fontSize: 20,
    lineHeight: 23,
    letterSpacing: 0.35
  },
  h2Bold: {
    fontFamily: 'Georama_700Bold',
    fontSize: 23,
    lineHeight: 25,
    letterSpacing: 0.35
  },
  h1Bold: {
    fontFamily: 'Georama_600SemiBold',
    fontSize: 39,
    lineHeight: 41,
    letterSpacing: 0.35
  },
  label: {
    fontSize: 13,
    fontFamily: 'PlusJakartaSans_500Medium',
    marginBottom: 4
  }
}
