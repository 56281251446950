// External
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import {
  getDocumentAsync,
  type DocumentPickerAsset
} from 'expo-document-picker'
import {
  launchImageLibraryAsync,
  type ImagePickerAsset
} from 'expo-image-picker'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
// Components
import { Icon } from './Icon'
import { Text } from './Text'

interface Props {
  onImagePickerAsset: (asset: ImagePickerAsset) => void
  onDocumentPickerAsset?: (asset: DocumentPickerAsset) => void
}

export const UploadOptions = ({
  onImagePickerAsset,
  onDocumentPickerAsset
}: Props) => {
  const [disabled, setDisabled] = useState(false)
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <View style={styles.option}>
        <TouchableOpacity
          disabled={disabled}
          style={[styles.iconContainer, { backgroundColor: '#FFBB884D' }]}
          onPress={async () => {
            try {
              setDisabled(true)
              const result = await launchImageLibraryAsync({
                mediaTypes: 'images',
                allowsEditing: true
              })

              if (!result.canceled && result.assets[0] !== undefined) {
                onImagePickerAsset(result.assets[0])
              }
            } catch (error) {
              console.error(error)
            } finally {
              setDisabled(false)
            }
          }}
        >
          <MaterialIcons name="image-search" size={24} color="#E09650" />
        </TouchableOpacity>

        <Text>{t('selectAPhoto')}</Text>
      </View>

      {onDocumentPickerAsset !== undefined && (
        <View style={styles.option}>
          <TouchableOpacity
            disabled={disabled}
            style={[styles.iconContainer, { backgroundColor: '#FFBB884D' }]}
            onPress={async () => {
              try {
                setDisabled(true)
                const result = await getDocumentAsync()

                if (!result.canceled && result.assets[0] !== undefined) {
                  onDocumentPickerAsset(result.assets[0])
                }
              } catch (error) {
                console.error(error)
              } finally {
                setDisabled(false)
              }
            }}
          >
            <Icon name="file-search" size={24} color="#E09650" />
          </TouchableOpacity>

          <Text>{t('selectAFile')}</Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  option: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconContainer: {
    height: 78,
    width: 78,
    borderRadius: 39,
    marginBottom: 5,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
