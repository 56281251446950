import i18n from '@/common/config/i18n'
import { VisitCheckinQuestionTypes } from '@/common/models'
import { z } from 'zod'

const { t } = i18n

export const checkinQuestionSchema = z
  .object({
    questionId: z.string(),
    optional: z.boolean(),
    answer: z.string().optional(), // Initially optional
    type: z.nativeEnum(VisitCheckinQuestionTypes),
    attachment: z.any().optional() // Initially optional
  })
  .superRefine((data, ctx) => {
    if (
      !data.optional &&
      data.type !== VisitCheckinQuestionTypes.ATTACHMENT &&
      (data.answer === undefined || data.answer.length === 0)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('requiredField'),
        path: ['answer']
      })
    }

    if (
      !data.optional &&
      data.type === VisitCheckinQuestionTypes.ATTACHMENT &&
      (data.attachment === undefined || data.attachment === null)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t('requiredField'),
        path: ['attachment']
      })
    }
  })
