import { client } from '@/common/config'
import { SortOrder, type PaginatedGetParams } from '@/common/models'

interface Params extends PaginatedGetParams {
  parentZoneId?: string
}

export const getZones = async ({
  parentZoneId,
  page,
  limit = 100,
  sortField = 'name',
  sortOrder = SortOrder.ASC
}: Params) => {
  const { data, error } = await client.GET(
    '/api/app/csp-permit-to-work/procedures/zones/search',
    {
      params: {
        query: {
          parentZoneId,
          page,
          limit,
          sortField,
          sortOrder
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting zones:', error)
    return await Promise.reject(error)
  }

  return data.data
}
