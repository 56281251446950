// External
import type { StackScreenProps } from '@react-navigation/stack'
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { ImagePickerAsset } from 'expo-image-picker'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { SheetManager } from 'react-native-actions-sheet'
// Components
import {
  Input,
  ProgressBar,
  SelectAttachment,
  StepNavigationButtons,
  Text
} from '@/common/components'
import { PermitRichText } from '@/cspPermitToWork/components'
// Constants
import { alert, colors, toast } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
// Stores
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'
// Use cases
import { submitPermitRequest } from '@/cspPermitToWork/useCases'

// TODO - move to utils and reuse in RequestVisitForm
const getFileName = (file: ImagePickerAsset | DocumentPickerAsset) => {
  let fileName: string | undefined

  if ('name' in file && typeof file.name === 'string') {
    fileName = file.name
  } else if ('fileName' in file && typeof file.fileName === 'string') {
    fileName = file.fileName
  } else if ('uri' in file && typeof file.uri === 'string') {
    fileName = `${new Date().toDateString()}.${
      file.uri.split('.').pop() as string
    }`
  }

  return fileName
}

interface FormValues {
  attachments: Array<ImagePickerAsset | DocumentPickerAsset>
}

type Props = StackScreenProps<CSPPTWStackParamList, 'NewRequestStep9'>

const NewRequestStep9 = ({ navigation }: Props) => {
  const { values: currentValues } = useNewRequestStore()
  const [requiredDocumentation, setRequiredDocumentation] = useState<string>()
  const { t } = useTranslation()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = useForm<FormValues>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments'
  })

  const onSubmit = async (values: FormValues) => {
    if (currentValues === undefined) {
      return
    }

    await submitPermitRequest({
      attachments: values.attachments,
      additionalEquipment: currentValues.additionalEquipment!.map(
        ({ equipment, worker }) => ({
          equipmentId: equipment.id,
          workerIds: worker !== undefined ? [worker._id] : undefined
        })
      ),
      companyId: currentValues.company!._id,
      cspManagerFacilityMemberId: currentValues.cspManager!.id,
      description: currentValues.description!,
      endDate: currentValues.endDate!.toISOString(),
      primaryContractors: currentValues.employees!.map(({ _id }) => _id),
      startDate: currentValues.startDate!.toISOString(),
      subcontractors: currentValues.subcontractedCompanies!.flatMap(
        ({ subcontractedEmployees }) =>
          subcontractedEmployees.map(({ _id }) => _id)
      ),
      typeOfWorks: currentValues.works!.map(({ work }) => ({
        typeOfWorkId: work.id,
        specificWork: work.name
      })),
      ...(currentValues.zone !== undefined && {
        zone: {
          zoneId: currentValues.zone.id,
          questionAnswers:
            currentValues.extraQuestion !== undefined
              ? [
                  {
                    answer: currentValues.extraQuestion.answer,
                    extraQuestionId: currentValues.extraQuestion.extraQuestionId
                  }
                ]
              : [],
          specificZone: currentValues.specificZone
        }
      }),
      ...(currentValues.machinery !== undefined &&
        currentValues.machinery.length > 0 && {
          machinery: currentValues.machinery.map(
            ({
              machinery,
              specificMachinery,
              zone,
              specificZone,
              extraQuestion
            }) => ({
              machineId: machinery.id,
              machineryZoneId: zone.id,
              questionAnswers:
                extraQuestion !== undefined
                  ? [
                      {
                        answer: extraQuestion.answer,
                        extraQuestionId: extraQuestion.extraQuestionId
                      }
                    ]
                  : [],
              specificZone: specificMachinery,
              specificMachineryZoneName: specificZone
            })
          )
        })
    })
    toast.success({
      data: {
        messageTranslationKey: 'permitRequested'
      }
    })
    navigation.popToTop()
  }

  useEffect(() => {
    const totalDocumentation: string[] = []

    // Documentation from zones
    if (
      currentValues?.zone?.requiredDocuments !== undefined &&
      currentValues.zone.requiredDocuments.length > 0
    ) {
      totalDocumentation.push(currentValues?.zone.requiredDocuments)
    }

    // Documentation from each work
    if (currentValues?.works !== undefined) {
      for (const { work } of currentValues.works) {
        if (
          work.requiredDocuments !== undefined &&
          work.requiredDocuments.length > 0
        ) {
          totalDocumentation.push(work.requiredDocuments)
        }
      }
    }

    // Documentation from each additional equipment
    if (currentValues?.additionalEquipment !== undefined) {
      for (const { equipment } of currentValues.additionalEquipment) {
        if (
          equipment.requiredDocuments !== undefined &&
          equipment.requiredDocuments.length > 0
        ) {
          totalDocumentation.push(equipment.requiredDocuments)
        }
      }
    }

    setRequiredDocumentation(totalDocumentation.join('\n'))
  }, [currentValues])

  return (
    <SafeArea style={styles.container}>
      <View style={{ marginHorizontal: 25 }}>
        <ProgressBar style={styles.progressBar} value={1} />

        <Text variant="h2Bold" style={{ marginBottom: 8 }}>
          {t('documentsToAttach')}
        </Text>
      </View>

      <ScrollView
        bounces={false}
        contentContainerStyle={{ paddingHorizontal: 25 }}
      >
        {requiredDocumentation !== undefined &&
          requiredDocumentation.length > 0 && (
            <PermitRichText
              style={{ marginBottom: 30 }}
              tagsStyles={{
                ul: {
                  marginVertical: 0
                }
              }}
              title={t('attachTheFollowingDocuments')}
              html={requiredDocumentation}
            />
          )}

        <SelectAttachment
          iconName="upload-cloud"
          iconSize={28}
          text={t('uploadAttachments')}
          onPress={() => {
            void SheetManager.show('upload-options', {
              payload: {
                onImagePickerAsset: (asset) => {
                  append(asset)
                  void SheetManager.hide('upload-options')
                },
                onDocumentPickerAsset: (asset) => {
                  append(asset)
                  void SheetManager.hide('upload-options')
                }
              }
            })
          }}
        />

        <Text style={{ marginTop: 25 }} variant="baseBold">
          {t('documents')}
        </Text>

        {fields.length === 0 && <Input value={t('thereIsNoFileAttached')} />}

        {fields.map((attachment, index) => (
          <Input
            key={attachment.uri}
            value={getFileName(attachment)}
            rightIcon="x-circle"
            rightIconOnPress={() => {
              remove(index)
            }}
          />
        ))}

        <View style={{ flexDirection: 'row' }}>
          <Text variant="smallBold" color={colors.error}>
            *
          </Text>

          <Text style={{ marginStart: 6 }} variant="small">
            {t('maximumSizePerFile')}
          </Text>
        </View>
      </ScrollView>

      <StepNavigationButtons
        style={styles.buttons}
        nextTitleTranslationKey="requestPermit"
        nextOnPress={() => {
          if (
            requiredDocumentation !== undefined &&
            requiredDocumentation.length > 0 &&
            fields.length === 0
          ) {
            alert(t('noDocumentsUploaded'), t('youNeedToUploadTheDocuments'), [
              {
                text: t('cancel'),
                style: 'cancel'
              },
              {
                text: t('requestPermit'),
                onPress: () => {
                  void handleSubmit(onSubmit)()
                }
              }
            ])
            return
          }

          void handleSubmit(onSubmit)()
        }}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default NewRequestStep9

const styles = StyleSheet.create({
  container: {
    marginBottom: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  buttons: {
    marginTop: 'auto',
    paddingHorizontal: 25
  }
})
