import { create } from 'zustand'
import { type CreateAccountState } from '../models/create-account-state.interface'

const useCreateAccountStore = create<CreateAccountState>()((set) => ({
  email: '',
  setEmail: (email) => {
    set({ email })
  },
  password: '',
  setPassword: (password) => {
    set({ password })
  },
  userTypeUUID: '',
  setUserTypeUUID: (userTypeUUID) => {
    set({ userTypeUUID })
  },
  firstName: '',
  lastName: '',
  setFullName: (firstName, lastName) => {
    set({ firstName, lastName })
  },
  customFields: [],
  setCustomFields: (customFields) => {
    set({ customFields })
  },
  phoneNumber: {
    prefix: '',
    phone: ''
  },
  setFullPhoneNumber: (prefix, phone) => {
    set({ phoneNumber: { prefix, phone } })
  },
  profileImage: undefined,
  setProfileImage: (profileImage) => {
    set({ profileImage })
  },
  reset: () => {
    set({
      email: '',
      userTypeUUID: '',
      firstName: '',
      lastName: '',
      customFields: [],
      phoneNumber: {
        prefix: '',
        phone: ''
      }
    })
  }
}))

export default useCreateAccountStore
