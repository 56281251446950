import { RenderHTML, Text } from '@/common/components'
import { useState } from 'react'
import { ScrollView, View, type ViewStyle } from 'react-native'
import type { TRenderEngineConfig } from 'react-native-render-html'

interface Props {
  style?: ViewStyle
  tagsStyles?: TRenderEngineConfig['tagsStyles']
  title: string
  html: string
}

export const PermitRichText = ({ style, tagsStyles, title, html }: Props) => {
  const [contentWidth, setContentWidth] = useState<number>()

  return (
    <View
      style={style}
      onLayout={({ nativeEvent }) => {
        setContentWidth(nativeEvent.layout.width)
      }}
    >
      <Text variant="baseBold">{title}</Text>

      <ScrollView bounces={false}>
        <RenderHTML
          tagsStyles={tagsStyles}
          contentWidth={contentWidth}
          html={html}
        />
      </ScrollView>
    </View>
  )
}
