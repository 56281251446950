// External
import { type SheetProps } from 'react-native-actions-sheet'
import { type TextInput } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useRef } from 'react'
import { z } from 'zod'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
// Constants
import {
  noSpaceRegex,
  uppercaseRegex,
  lowercaseRegex,
  numbersRegex,
  symbolRegex
} from '@/common/constants'
// Components
import { Text, Input, Button } from '@/common/components'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'

type Props = SheetProps<'edit-password'>

export const EditPassword = ({ payload, sheetId }: Props) => {
  const { t } = useTranslation()
  const repeatPasswordInputRef = useRef<TextInput>(null)

  const validationSchema = z
    .object({
      password: z
        .string()
        .min(8, { message: t('passwordLengthError', { length: 8 }) })
        .regex(noSpaceRegex, { message: t('passwordSpaceError') })
        .regex(uppercaseRegex, { message: t('passwordUppercaseError') })
        .regex(lowercaseRegex, { message: t('passwordLowercaseError') })
        .regex(numbersRegex, { message: t('passwordNumberError') })
        .regex(symbolRegex, { message: t('passwordSymbolError') }),
      repeatPassword: z.string()
    })
    .refine((data) => data.password === data.repeatPassword, {
      message: t('passwordsMustMatch'),
      path: ['repeatPassword']
    })

  type FormValues = z.infer<typeof validationSchema>

  if (payload === undefined) {
    return null
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      password: '',
      repeatPassword: ''
    }
  })

  return (
    <ActionSheetContainer id={sheetId}>
      <Text
        style={{
          textAlign: 'center',
          marginBottom: 30
        }}
        variant="baseBold"
      >
        {t('editYourPassword')}
      </Text>

      <Controller
        control={control}
        name="password"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            secureTextEntry
            placeholder={t('yourPassword')}
            onChangeText={onChange}
            onSubmitEditing={() => {
              repeatPasswordInputRef.current?.focus()
            }}
            onBlur={onBlur}
            value={value}
            returnKeyType="next"
            autoComplete="password"
            autoCapitalize="none"
            errorMessage={errors.password?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="repeatPassword"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            secureTextEntry
            placeholder={t('repeatYourPassword')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(payload.onSubmit)}
            onBlur={onBlur}
            value={value}
            returnKeyType="done"
            autoComplete="password"
            autoCapitalize="none"
            errorMessage={errors.repeatPassword?.message}
          />
        )}
      />

      <Button
        style={{ marginTop: 18 }}
        title={t('saveChanges')}
        onPress={handleSubmit(payload.onSubmit)}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
    </ActionSheetContainer>
  )
}
