import { Text } from '@/common/components'
import { colors } from '@/common/constants'
import type { components } from '@/common/models'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, type ViewStyle } from 'react-native'

interface Props {
  style?: ViewStyle
  companies: Array<
    components['schemas']['AppCSPPermitToWorkRequestDetailResponseDTO']['company']
  >
}

export const PermitSubcontractedCompanies = ({ style, companies }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={style}>
      <Text style={{ marginBottom: 4 }} variant="label">
        {t('subcontractedCompanies')}
      </Text>

      {companies.map((company, index) => (
        <View
          key={company.id}
          style={[
            styles.company,
            {
              marginBottom: index === companies.length - 1 ? 0 : 4
            }
          ]}
        >
          <Text
            style={{
              fontFamily: 'PlusJakartaSans_500Medium'
            }}
            variant="small"
          >
            {company.name}
          </Text>

          <Text
            style={{
              fontFamily: 'PlusJakartaSans_300Light'
            }}
            variant="small"
          >
            {company.uid}
          </Text>
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  company: {
    height: 52,
    borderRadius: 7,
    backgroundColor: colors.inactive,
    paddingHorizontal: 12,
    justifyContent: 'center'
  }
})
