import type { components } from '@/common/models'
import { getCompanyContractor } from '@/common/services'
import {
  createPermitRequest,
  uploadPermitRequestAttachments
} from '@/cspPermitToWork/services'
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { ImagePickerAsset } from 'expo-image-picker'
import { Platform } from 'react-native'

type Params = {
  attachments: Array<ImagePickerAsset | DocumentPickerAsset>
} & Omit<
  components['schemas']['CreateCSPPermitToWorkRequestDTO'],
  'preventiveContractorId'
>

export const submitPermitRequest = async ({ attachments, ...rest }: Params) => {
  try {
    const { _id } = await getCompanyContractor({ companyId: rest.companyId })
    const { id: requestId } = await createPermitRequest({
      preventiveContractorId: _id,
      ...rest
    })
    if (attachments.length > 0) {
      await uploadPermitRequestAttachments({ requestId, attachments })
    }
    useNewRequestStore.getState().setValues()
    if (Platform.OS === 'web') {
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}
