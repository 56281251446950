import { client } from '@/common/config'

export const getPermitAttachments = async (requestId: string) => {
  const { data, error } = await client.GET(
    '/api/app/csp-permit-to-work/requests/{requestId}/attachments',
    {
      params: {
        path: {
          requestId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting permit attachments:', error)
    return await Promise.reject(error)
  }

  return data.data
}
