// External
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
// Components
import { Avatar, Text } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import type { components } from '@/common/models'
// Utils
import { generateImageUrl } from '@/common/utils'

interface Props {
  facility: components['schemas']['FacilityListItemResponse']
  distance?: {
    unit: string
    value: number | string
  }
  onPress: (facility: components['schemas']['FacilityListItemResponse']) => void
}

export const FacilityListItem = ({ facility, distance, onPress }: Props) => {
  const region = facility.info.country.regionCode
  const { t } = useTranslation()
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        onPress(facility)
      }}
    >
      <Avatar
        source={{
          uri: generateImageUrl(facility.avatarUrl, {
            height: 66.6,
            width: 66.6
          })
        }}
        size={66.6}
        borderRadius={6}
      />

      <View style={{ flex: 1 }}>
        <Text variant="mediumBold" numberOfLines={2}>
          {facility.name}
        </Text>

        <Text numberOfLines={1} color={colors.text70Percent}>
          {distance !== undefined && (
            <Text
              color={colors.hydro}
              variant="baseBold"
            >{`${distance.value} ${distance.unit} `}</Text>
          )}

          {`${facility.info.address}, ${t(`regions.${region}`)}`}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
    paddingVertical: 14
  },
  address: {
    flex: 1,
    lineHeight: 22
  }
})
