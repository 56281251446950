import i18n from '@/common/config/i18n'
import { useEffect } from 'react'
import { z } from 'zod'
import { zu } from 'zod_utilz'

const { t } = i18n

const useCustomErrorMap = () => {
  useEffect(() => {
    const errorMap = zu.makeErrorMap({
      required: t('requiredField')
    })

    z.setErrorMap(errorMap)
  }, [])
}

export default useCustomErrorMap
