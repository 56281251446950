// External
import { ListItem } from '@rneui/themed'
import { useQuery } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import {
  type ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, type TouchableOpacity } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { Button, Icon, Link, Text, TopBar } from '@/common/components'
import { ProcedureSelectSkeleton } from '../ProcedureSelectSkeleton'
// Constants
import { colors } from '@/common/constants'
import type { Work } from '@/cspPermitToWork/constants'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Layouts
import { SafeArea } from '@/common/layouts'
// Mdoels
import type { components } from '@/common/models'
// Services
import { getTypesOfWork } from '@/cspPermitToWork/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import { LastTypeOfWorkForm } from './LastTypeOfWorkForm'

interface Props {
  numberSelected?: number
  onSelect: (values: { work: Work; otherWork?: string }) => void
  onEdit: (index: number, values: { work: Work; otherWork?: string }) => void
}

export const TypeOfWorkSelect = forwardRef(
  (
    { numberSelected, onSelect, onEdit }: Props,
    ref: ForwardedRef<typeof TouchableOpacity>
  ) => {
    const { currentFacility } = useAppStore((state) => ({
      currentFacility: state.currentFacility
    }))
    const [cursor, setCursor] = useState<Work>()
    const [selecting, setSelecting] = useState(false)
    const [editing, setEditing] = useState<number>()
    const { t } = useTranslation()
    const { bottom } = useSafeAreaInsets()
    const isRTL = useIsRTL()

    const back = useCallback(() => {
      // the function is only available when cursor is defined
      if (cursor!.parents.length === 0) {
        setCursor(undefined)
        return
      }

      // @ts-expect-error - it only gets here when there's at least one parent
      setCursor((prev) => ({
        ...prev?.parents[0],
        parents: prev?.parents.slice(1)
      }))
    }, [cursor])
    const next = useCallback(
      (typeOfWork: components['schemas']['AppCSPTypeOfWorkResponseDto']) => {
        const currentCursor = cloneDeep(cursor)
        const newCursor = {
          applicationProcedures: typeOfWork.applicationProcedures,
          applicationStandards: typeOfWork.applicationStandards,
          goodPractices: typeOfWork.goodPractices,
          id: typeOfWork.id,
          isLast: typeOfWork.isLast,
          hasOtherSpecificWork: typeOfWork.hasOtherSpecificWork,
          name: typeOfWork.name,
          parents: [
            ...(currentCursor !== undefined
              ? [
                  {
                    applicationProcedures: currentCursor.applicationProcedures,
                    applicationStandards: currentCursor.applicationStandards,
                    goodPractices: currentCursor.goodPractices,
                    id: currentCursor.id,
                    isLast: currentCursor.isLast,
                    hasOtherSpecificWork: currentCursor.hasOtherSpecificWork,
                    name: currentCursor.name,
                    requiredDocuments: currentCursor.requiredDocuments
                  }
                ]
              : []),
            ...(currentCursor?.parents ?? [])
          ],
          requiredDocuments: typeOfWork.requiredDocuments
        }

        if (
          (newCursor?.isLast ?? false) &&
          !(newCursor?.hasOtherSpecificWork ?? false) &&
          newCursor?.goodPractices?.length === 0
        ) {
          if (selecting && editing === undefined) {
            onSelect({
              work: newCursor
            })
            setSelecting(false)
          } else if (typeof editing === 'number') {
            onEdit(editing, {
              work: newCursor
            })
            setEditing(undefined)
          }
          setCursor(undefined)
          return
        }

        setCursor(newCursor)
      },
      [cursor]
    )

    const { data, isFetching } = useQuery({
      queryKey: ['cspTypeOfWork', currentFacility?._id, cursor?.id],
      queryFn: async () =>
        await getTypesOfWork({
          parentTypeOfWorkId: cursor?.id,
          page: 1
        })
    })

    const edit = useCallback(
      (params: { cursor: Work; index: number }) => {
        setCursor(params.cursor)
        setEditing(params.index)
      },
      [data]
    )

    // @ts-expect-error TODO - correct typing
    useImperativeHandle(ref, () => ({
      edit: (params: { cursor: Work; index: number }) => {
        edit(params)
      }
    }))

    useEffect(() => {
      if (numberSelected === 0) {
        setSelecting(true)
      }
    }, [])

    return (
      <>
        <Link
          ref={ref}
          style={{ alignSelf: 'flex-end' }}
          icon={{
            name: 'add-file'
          }}
          onPress={() => {
            setSelecting(true)
          }}
        >
          {t(numberSelected === 0 ? 'addWork' : 'addAnotherWork')}
        </Link>

        {(selecting || editing !== undefined) && (
          <SafeArea style={styles.container} edges={['top', 'right', 'left']}>
            <TopBar
              title={cursor?.name ?? t('typeOfWork')}
              leftIcon={{
                name: 'close',
                onPress: () => {
                  setCursor(undefined)
                  setSelecting(false)
                  setEditing(undefined)
                }
              }}
            />

            {!(cursor?.isLast ?? false) && (
              <>
                <Text style={{ marginTop: 27 }} variant="small">
                  {t('selectTheTypeOfWork')}
                </Text>

                <ScrollView
                  bounces={false}
                  style={{ flex: 1 }}
                  contentContainerStyle={{
                    paddingBottom: bottom + 25
                  }}
                >
                  {isFetching ? (
                    <ProcedureSelectSkeleton />
                  ) : (
                    data?.map((equipment, index, { length }) => (
                      <ListItem
                        onPress={() => {
                          next(equipment)
                        }}
                        key={equipment.id}
                        bottomDivider={index < length - 1}
                      >
                        <ListItem.Title>{equipment.name}</ListItem.Title>

                        {
                          <Icon
                            name="next"
                            size={16}
                            color={colors.placeholder}
                          />
                        }
                      </ListItem>
                    ))
                  )}
                </ScrollView>

                {cursor !== undefined && (
                  <Button
                    style={styles.backButton}
                    compact
                    type="secondary"
                    onPress={back}
                    iconName={isRTL ? 'next' : 'back'}
                  />
                )}
              </>
            )}

            {(cursor?.isLast ?? false) && (
              <LastTypeOfWorkForm
                cursor={cursor!}
                onSubmit={(values) => {
                  if (selecting && editing === undefined) {
                    onSelect(values)
                    setSelecting(false)
                  } else if (typeof editing === 'number') {
                    onEdit(editing, values)
                    setEditing(undefined)
                  }
                  setCursor(undefined)
                }}
                back={back}
              />
            )}
          </SafeArea>
        )}
      </>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999
  },
  backButton: {
    marginTop: 'auto',
    alignSelf: 'flex-start',
    marginBottom: 25
  }
})
