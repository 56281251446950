import { client } from '@/common/config'

export const closePermit = async (requestId: string) => {
  const { error } = await client.PATCH(
    '/api/app/csp-permit-to-work/requests/close/{requestId}',
    {
      params: {
        path: {
          requestId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error requesting closing permit:', error)
    return await Promise.reject(error)
  }
}
