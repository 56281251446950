import type { ExtendedPermitStatus } from '@/common/models'
import { colors } from './colors'

export const permitStatusColors: Record<
  ExtendedPermitStatus,
  {
    background: string
    text: string
    icon: string
    iconBackground: string
  }
> = {
  ACTIVE: {
    background: '#EEFFFA',
    text: colors.success,
    icon: colors.white,
    iconBackground: colors.success
  },
  QUEUED: {
    background: '#F0FBFF',
    text: '#709BA9',
    icon: '#709BA9',
    iconBackground: '#C1F0FF'
  },
  REJECTED: {
    background: '#FFF6F4',
    text: colors.error,
    icon: colors.white,
    iconBackground: colors.error
  },
  CANCELED: {
    background: '#F5F5F5',
    text: '#919191',
    icon: colors.white,
    iconBackground: '#919191'
  },
  PENDING: {
    background: '#FFFAF6',
    text: colors.rajah,
    icon: colors.white,
    iconBackground: colors.rajah
  },
  CLOSED: {
    background: '#EBF5F8',
    text: '#778A92',
    icon: colors.white,
    iconBackground: '#778A92'
  },
  EXPIRED: {
    background: '#EBF5F8',
    text: '#778A92',
    icon: colors.white,
    iconBackground: '#778A92'
  },
  REQUESTED: {
    background: '#FFFAF6',
    text: colors.rajah,
    icon: colors.white,
    iconBackground: colors.rajah
  },
  DENIED: {
    background: '#FFF6F4',
    text: colors.error,
    icon: colors.white,
    iconBackground: colors.error
  },
  FINISHED: {
    background: '#F0FBFF',
    text: '#709BA9',
    icon: '#709BA9',
    iconBackground: '#C1F0FF'
  }
}
