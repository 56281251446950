import i18n from '@/common/config/i18n'
import { z } from 'zod'
import { checkinQuestionSchema } from './checkinQuestionSchema'

const { t } = i18n

export const requestVisitFormSchema = z.object({
  date: z
    .object({
      day: z.number(),
      month: z.number(),
      year: z.number()
    })
    .refine(
      (data) =>
        data.day !== undefined &&
        data.month !== undefined &&
        data.year !== undefined,
      {
        message: t('requiredField')
      }
    ),
  checkIn: z.string().min(1, { message: t('requiredField') }),
  checkOut: z.string().min(1, { message: t('requiredField') }),
  hosts: z.array(z.string()).min(1, { message: t('selectAtLeastOneHost') }),
  visitQuestionAnswers: z.array(checkinQuestionSchema)
})
