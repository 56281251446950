// External
import { zodResolver } from '@hookform/resolvers/zod'
import type { StackScreenProps } from '@react-navigation/stack'
import { useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { z } from 'zod'
// Components
import {
  Checkbox,
  InfiniteSelect,
  ProgressBar,
  StepNavigationButtons,
  Text
} from '@/common/components'
// Constants
import { alert, colors } from '@/common/constants'
import {
  contractorSchema,
  type Company,
  type Contractor
} from '@/cspPermitToWork/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
// Services
import { getCompanyContractor } from '@/common/services'
import {
  getAuthorizedCompanies,
  getContractors
} from '@/cspPermitToWork/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'
// Utils
import { handleError } from '@/common/utils'

type Props = StackScreenProps<CSPPTWStackParamList, 'NewRequestStep7'>

const NewRequestStep7 = ({ navigation }: Props) => {
  const { user, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentFacility: state.currentFacility
  }))
  const { values: currentValues, setValues } = useNewRequestStore()
  const [subcontractAnotherCompany, setSubcontractAnotherCompany] = useState(
    currentValues?.subcontractedCompanies?.length !== undefined &&
      currentValues.subcontractedCompanies.length > 0
  )
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      z.object({
        employees: z.array(contractorSchema),
        subcontractedCompanies: z.array(
          z.object({
            _id: z.string(),
            uuid: z.string(),
            name: z.string(),
            subcontractedEmployees: z
              .array(contractorSchema)
              .min(1, { message: t('selectAtLeastOneEmployee') })
          })
        )
      }),
    [t]
  )

  type FormValues = z.infer<typeof formSchema>

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      employees: currentValues?.employees ?? [],
      subcontractedCompanies: currentValues?.subcontractedCompanies ?? []
    }
  })

  const { fields, replace } = useFieldArray({
    control,
    name: 'subcontractedCompanies'
  })

  const subcontractedCompanies = watch('subcontractedCompanies')

  const onSubmit = async ({ employees, ...rest }: FormValues) => {
    try {
      const contractor = await getCompanyContractor({
        companyId: currentValues?.company?._id as string
      })

      let newEmployees = []
      if (
        (employees.length === 0 ||
          !employees.some((employee) => employee._id === contractor._id)) &&
        user !== undefined
      ) {
        const currentUserContractor = {
          _id: contractor._id,
          uuid: contractor.uuid,
          companyId: contractor.companyId,
          appUser: {
            _id: contractor.appUserId,
            uuid: user.uuid,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber!
          }
        }
        newEmployees = [currentUserContractor, ...employees]
      } else {
        newEmployees = employees
      }

      setValues({ ...currentValues, employees: newEmployees, ...rest })
      navigation.navigate('NewRequestStep8')
    } catch (error) {
      handleError(error)
    }
  }

  const employeesWithAssignedEquipment =
    currentValues?.additionalEquipment?.map(
      (equipment) => equipment.worker?._id
    )

  return (
    <SafeArea style={styles.container}>
      <ProgressBar style={styles.progressBar} value={0.7777} />

      <Text variant="h2Bold" style={{ marginBottom: 8 }}>
        {t('personnel')}
      </Text>

      <Text style={{ marginBottom: 25 }}>{t('selectThePersonnel')}</Text>

      <Controller
        control={control}
        name="employees"
        render={({
          field: { onBlur, onChange, value },
          fieldState: { error }
        }) => (
          <InfiniteSelect<Contractor>
            searchable
            label={t('companyEmployees')}
            placeholder={t('selectEmployees')}
            searchPlaceholder={t('searchEmployees')}
            onMultipleSelect={(options) => {
              onChange(options.map((option) => option.original))
              onBlur()
            }}
            errorMessage={error?.message}
            queryKey={[
              'cspContractors',
              currentValues?.company?._id,
              currentFacility?._id
            ]}
            queryFn={async ({ page, search }) =>
              await getContractors({
                companyId: currentValues?.company?._id as string,
                facilityId: currentFacility?._id as string,
                page,
                search
              })
            }
            getOptions={(data) =>
              data.map((employee) => ({
                label: `${employee.appUser.firstName as string} ${
                  employee.appUser.lastName as string
                }`,
                value: employee._id,
                original: {
                  _id: employee._id,
                  uuid: employee.uuid,
                  companyId: employee.companyId,
                  appUser: employee.appUser
                }
              }))
            }
            noResultsTranslationKey="noEmployeesFound"
            defaultSelectedOptions={value.map((employee) => ({
              label: `${employee.appUser.firstName} ${employee.appUser.lastName}`,
              value: employee._id,
              original: employee
            }))}
          />
        )}
      />

      <Text
        style={{ marginBottom: 10 }}
        variant="small"
        color={colors.placeholder}
      >
        {t('ifYouDontSelectAnyColleagues')}
      </Text>

      <View style={styles.subcontract}>
        <Text variant="small">{t('subcontractACompany')}</Text>

        <Checkbox
          checked={subcontractAnotherCompany}
          onPress={() => {
            if (
              subcontractedCompanies.length > 0 &&
              subcontractAnotherCompany
            ) {
              alert(t('deleteSubcontractingData'), t('uncheckingThisOption'), [
                {
                  text: t('cancel'),
                  style: 'cancel'
                },
                {
                  text: t('delete'),
                  style: 'destructive',
                  onPress: () => {
                    replace([])
                    setSubcontractAnotherCompany(false)
                  }
                }
              ])
              return
            }
            setSubcontractAnotherCompany((prev) => !prev)
          }}
        />
      </View>

      {subcontractAnotherCompany && (
        <>
          <Controller
            control={control}
            name="subcontractedCompanies"
            render={({ field: { value }, fieldState: { error } }) => (
              <InfiniteSelect<Company>
                searchable
                label={t('subcontractedCompanies')}
                placeholder={t('selectCompanies')}
                searchPlaceholder={t('searchCompanies')}
                onMultipleSelect={(options) => {
                  replace(
                    options.map(({ original }) => ({
                      _id: original?._id as string,
                      uuid: original?.uuid as string,
                      name: original?.name as string,
                      subcontractedEmployees: []
                    }))
                  )
                }}
                errorMessage={error?.message}
                noResultsTranslationKey="noCompaniesFound"
                getOptions={(data) =>
                  data
                    ?.filter(
                      (company) =>
                        ![currentValues?.company?._id as string].includes(
                          company._id
                        )
                    )
                    .map((company) => ({
                      label: company.name,
                      value: company._id,
                      original: company
                    })) ?? []
                }
                queryKey={['cspAuthorizedCompanies', currentFacility?._id]}
                queryFn={async (params) =>
                  await getAuthorizedCompanies({
                    facilityId: currentFacility?._id as string,
                    ...params
                  })
                }
                defaultSelectedOptions={value.map((company) => ({
                  label: company.name,
                  value: company._id,
                  original: company
                }))}
              />
            )}
          />

          {fields.map(({ _id, name }, index) => (
            <Controller
              key={_id}
              control={control}
              name={`subcontractedCompanies.${index}.subcontractedEmployees`}
              render={({
                field: { onBlur, onChange, value },
                fieldState: { error }
              }) => (
                <InfiniteSelect<Contractor>
                  searchable
                  label={t('employeesOf', {
                    company: name
                  })}
                  placeholder={t('selectEmployees')}
                  searchPlaceholder={t('searchEmployees')}
                  onMultipleSelect={(options) => {
                    const selectedValues = options.map((option) => option.value)
                    const unselectedEmployeesWithEquipment =
                      employeesWithAssignedEquipment?.filter(
                        (_id) => !selectedValues?.includes(_id as string)
                      )

                    if (
                      unselectedEmployeesWithEquipment !== undefined &&
                      unselectedEmployeesWithEquipment?.length > 0
                    ) {
                      alert(
                        t('confirmationRequired'),
                        t('userHasBeenAssignedTool'),
                        [
                          {
                            text: t('cancel'),
                            style: 'cancel'
                          },
                          {
                            text: t('confirmNewSelection'),
                            onPress: () => {
                              const newValues = {
                                ...currentValues,
                                additionalEquipment:
                                  currentValues?.additionalEquipment?.filter(
                                    (equipment) => {
                                      if (
                                        equipment.worker !== undefined &&
                                        unselectedEmployeesWithEquipment.includes(
                                          equipment.worker._id
                                        )
                                      ) {
                                        return false
                                      }
                                      return true
                                    }
                                  )
                              }
                              setValues(newValues)
                              onChange(options.map((option) => option.original))
                              onBlur()
                            }
                          }
                        ]
                      )
                      return
                    }

                    onChange(options.map((option) => option.original))
                    onBlur()
                  }}
                  errorMessage={error?.message}
                  queryKey={['cspContractors', _id, currentFacility?._id]}
                  queryFn={async ({ page, search }) =>
                    await getContractors({
                      companyId: _id,
                      facilityId: currentFacility?._id as string,
                      page,
                      search
                    })
                  }
                  getOptions={(data) =>
                    data.map((employee) => ({
                      label: `${employee.appUser.firstName as string} ${
                        employee.appUser.lastName as string
                      }`,
                      value: employee._id,
                      original: {
                        _id: employee._id,
                        uuid: employee.uuid,
                        companyId: employee.companyId,
                        appUser: employee.appUser
                      }
                    }))
                  }
                  noResultsTranslationKey="noEmployeesFound"
                  defaultSelectedOptions={value.map((employee) => ({
                    label: `${employee.appUser.firstName} ${employee.appUser.lastName}`,
                    value: employee._id,
                    original: employee
                  }))}
                />
              )}
            />
          ))}
        </>
      )}

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default NewRequestStep7

const styles = StyleSheet.create({
  container: {
    marginBottom: 25,
    marginHorizontal: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  subcontract: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25
  }
})
