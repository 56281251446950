// External
import { zodResolver } from '@hookform/resolvers/zod'
import type { StackScreenProps } from '@react-navigation/stack'
import { useMemo, useRef } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { z } from 'zod'
// Components
import {
  Icon,
  Link,
  ProgressBar,
  StepNavigationButtons,
  Text,
  TouchableInput
} from '@/common/components'
import { AdditionalEquipmentSelect } from '@/cspPermitToWork/components'
// Constants
import { colors } from '@/common/constants'
import { contractorSchema, equipmentSchema } from '@/cspPermitToWork/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
// Stores
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'
import { getCursorValueString } from '@/cspPermitToWork/utils'

type Props = StackScreenProps<CSPPTWStackParamList, 'NewRequestStep8'>

const NewRequestStep8 = ({ navigation }: Props) => {
  const { values: currentValues, setValues } = useNewRequestStore()
  const selectRef = useRef<any>(null)
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      z.object({
        additionalEquipment: z
          .array(
            z.object({
              equipment: equipmentSchema,
              worker: contractorSchema.optional()
            })
          )
          .min(1, { message: t('selectAtLeastOneEquipment') })
      }),
    [t]
  )

  type FormValues = z.infer<typeof formSchema>

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      additionalEquipment: currentValues?.additionalEquipment ?? []
    }
  })

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'additionalEquipment'
  })

  const onSubmit = (values: FormValues) => {
    setValues({ ...currentValues, ...values })
    navigation.navigate('NewRequestStep9')
  }

  return (
    <SafeArea style={styles.container}>
      <ProgressBar style={styles.progressBar} value={0.8888} />

      <Text variant="h2Bold" style={{ marginBottom: 8 }}>
        {t('additionalEquipment')}
      </Text>

      <Text style={{ marginBottom: 25 }}>
        {t('selectTheAdditionalEquipment')}
      </Text>

      {fields.map((field, index) => (
        <View key={field.equipment.id}>
          <View style={styles.equipment}>
            <Text variant="label">
              {t('selectedEquipment', {
                index: index + 1
              })}
            </Text>

            <Link
              icon={{
                name: 'minus'
              }}
              color={colors.error}
              onPress={() => {
                remove(index)
              }}
            >
              {t('removeEquipment')}
            </Link>
          </View>

          <TouchableInput
            value={getCursorValueString(field.equipment)}
            onPress={() => {
              selectRef.current?.edit({
                cursor: field.equipment,
                index
              })
            }}
            rightIcon={<Icon name="next" size={20} />}
          />

          {field.worker !== undefined && (
            <Text variant="smallBold">
              {t('equipmentEmployee')}
              <Text style={{ flex: 1 }} variant="small" numberOfLines={1}>
                {` ${field.worker.appUser.firstName} ${field.worker.appUser.lastName} `}
              </Text>
            </Text>
          )}
        </View>
      ))}

      {errors?.additionalEquipment != null && (
        <Text variant="extraSmall" color={colors.error}>
          {errors?.additionalEquipment.message}
        </Text>
      )}

      <AdditionalEquipmentSelect
        ref={selectRef}
        numberSelected={fields.length}
        onSelect={(value) => {
          append(value)
        }}
        onEdit={(index, value) => {
          update(index, value)
        }}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default NewRequestStep8

const styles = StyleSheet.create({
  container: {
    marginBottom: 25,
    marginHorizontal: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  equipment: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
