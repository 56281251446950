import { Icon, Text } from '@/common/components'
import { permitStatusColors, permitStatusIcons } from '@/common/constants'
import type { ExtendedPermitStatus } from '@/common/models'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

interface Props {
  compact?: boolean
  status: ExtendedPermitStatus
}

export const PermitStatus = ({ compact = false, ...rest }: Props) => {
  const { t } = useTranslation()
  const status = rest.status
  const text = t(status)

  return (
    <View style={{ flexDirection: 'row', gap: compact ? 5 : 10 }}>
      <View
        style={[
          styles.iconContainer,
          { backgroundColor: permitStatusColors[status].iconBackground }
        ]}
      >
        <Icon
          name={permitStatusIcons[status]}
          color={permitStatusColors[status].icon}
          size={24}
        />
      </View>

      <View
        style={[
          styles.statusTextContainer,
          { backgroundColor: permitStatusColors[status].background }
        ]}
      >
        <Text color={permitStatusColors[status].text} variant="smallBold">
          {text}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  status: {
    flexDirection: 'row',
    gap: 10
  },
  iconContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center'
  },
  statusTextContainer: {
    paddingHorizontal: 15,
    height: 32,
    justifyContent: 'center',
    borderRadius: 10
  }
})
