import { client } from '@/common/config'

export const getCSPManagers = async () => {
  const { data, error } = await client.GET(
    '/api/app/csp-permit-to-work/configuration/csp-managers'
  )

  if (error !== undefined) {
    console.error('Error getting CSP managers:', error)
    return await Promise.reject(error)
  }

  return data.data
}
