// External
import { zodResolver } from '@hookform/resolvers/zod'
import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type TextInput } from 'react-native'
import { type SheetProps } from 'react-native-actions-sheet'
import { z } from 'zod'
// Components
import { Button, Input, Text } from '@/common/components'
// Constants
import { nameRegEx } from '@/common/constants'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'

type Props = SheetProps<'edit-name'>

export const EditName = ({ payload, sheetId }: Props) => {
  const { t } = useTranslation()
  const lastNameInputRef = useRef<TextInput>(null)

  const validationSchema = z.object({
    firstName: z
      .string()
      .min(1, { message: t('provideName') })
      .regex(nameRegEx, { message: t('nameInstructions') }),
    lastName: z
      .string()
      .min(1, { message: t('provideLastName') })
      .regex(nameRegEx, { message: t('nameInstructions') })
  })

  type FormValues = z.infer<typeof validationSchema>

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstName: payload?.initialValues.firstName as string,
      lastName: payload?.initialValues.lastName as string
    }
  })

  if (payload === undefined) {
    return null
  }

  return (
    <ActionSheetContainer id={sheetId}>
      <Text
        style={{
          textAlign: 'center',
          marginBottom: 30
        }}
        variant="baseBold"
      >
        {t('editYourName')}
      </Text>

      <Controller
        control={control}
        name="firstName"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            placeholder={t('yourName')}
            onChangeText={onChange}
            onSubmitEditing={() => {
              lastNameInputRef.current?.focus()
            }}
            onBlur={() => {
              onChange(value?.trim())
              onBlur()
            }}
            value={value}
            returnKeyType="next"
            autoComplete="name"
            autoCapitalize="words"
            errorMessage={errors.firstName?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="lastName"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            ref={lastNameInputRef}
            placeholder={t('yourLastName')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(payload.onSubmit)}
            onBlur={() => {
              onChange(value?.trim())
              onBlur()
            }}
            value={value}
            returnKeyType="done"
            autoComplete="name-family"
            autoCapitalize="words"
            errorMessage={errors.lastName?.message}
          />
        )}
      />

      <Button
        style={{ marginTop: 18 }}
        title={t('saveChanges')}
        onPress={handleSubmit(payload.onSubmit)}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
    </ActionSheetContainer>
  )
}
