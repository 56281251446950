import { client } from '@/common/config'
import { type PaginatedGetParams } from '@/common/models'

interface Params extends PaginatedGetParams {
  parentAdditionalEquipmentId?: string
}

export const getAdditionalEquipment = async ({
  parentAdditionalEquipmentId,
  page,
  limit = 100
}: Params) => {
  const { data, error } = await client.GET(
    '/api/app/csp-permit-to-work/procedures/additional-equipment/search',
    {
      params: {
        query: {
          parentAdditionalEquipmentId,
          page,
          limit
        }
      }
    }
  )

  if (error !== undefined) {
    console.log('Error getting additional equipment:', error)
    return await Promise.reject(error)
  }

  return data.data
}
