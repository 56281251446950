import { Text } from '@/common/components'
import type { components } from '@/common/models'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { PermitPersonCard } from '../PermitPersonCard'

interface Props {
  primaryContractors: components['schemas']['AppCSPPermitToWorkRequestDetailResponseDTO']['primaryContractors']
  subcontractors: components['schemas']['AppCSPPermitToWorkRequestDetailResponseDTO']['subcontractors']
}

export const PermitPersonnel = ({
  primaryContractors,
  subcontractors
}: Props) => {
  const { t } = useTranslation()

  return (
    <View style={{ gap: 30 }}>
      <View style={{ gap: 4 }}>
        <Text variant="label">{t('personnel')}</Text>

        {primaryContractors.map((contractor) => (
          <PermitPersonCard
            key={contractor.id}
            email={contractor.email}
            firstName={contractor.firstName}
            lastName={contractor.lastName}
            profileImage={contractor.profileImage}
          />
        ))}
      </View>

      {subcontractors.length > 0 && (
        <View style={{ gap: 4 }}>
          <Text variant="label">{t('subcontractedPersonnel')}</Text>

          {subcontractors.map((contractor) => (
            <PermitPersonCard
              key={contractor.id}
              email={contractor.email}
              firstName={contractor.firstName}
              lastName={contractor.lastName}
              profileImage={contractor.profileImage}
            />
          ))}
        </View>
      )}
    </View>
  )
}
