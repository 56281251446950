// External
import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { z, ZodIssueCode } from 'zod'
// Components
import { Input, StepNavigationButtons } from '@/common/components'
import { PermitRichText } from '../PermitRichText'
// Constants
import { workSchema, type Work } from '@/cspPermitToWork/constants'

interface Props {
  cursor: Work
  back: () => void
  onSubmit: (values: { work: Work; otherWork?: string }) => void
}

export const LastTypeOfWorkForm = ({ cursor, back, onSubmit }: Props) => {
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      z
        .object({
          work: workSchema,
          otherWork: z.string().optional()
        })
        .superRefine(({ work, otherWork }, ctx) => {
          if (
            work.hasOtherSpecificWork &&
            (otherWork === undefined || otherWork === '')
          ) {
            ctx.addIssue({
              code: ZodIssueCode.custom,
              message: t('requiredField'),
              path: ['otherWork']
            })
          }
        }),
    [t]
  )

  type FormValues = z.infer<typeof formSchema>

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    watch
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      work: cursor
    }
  })

  const { work } = watch()

  return (
    <>
      {(work?.hasOtherSpecificWork ?? false) && (
        <Controller
          control={control}
          name="otherWork"
          render={({
            field: { onBlur, onChange, value },
            fieldState: { error }
          }) => (
            <Input
              value={value}
              onChangeText={onChange}
              onBlur={onBlur}
              errorMessage={error?.message}
              placeholder={t('specifyTheTypeOfWork')}
              label={t('other')}
            />
          )}
        />
      )}

      {cursor.goodPractices !== undefined &&
        cursor.goodPractices.length > 0 && (
          <PermitRichText
            style={styles.goodPractices}
            title={t('goodPracticesFor', { work: cursor.name })}
            html={cursor.goodPractices}
          />
        )}

      <StepNavigationButtons
        style={{ marginBottom: 25 }}
        backOnPress={() => {
          back()
          reset()
        }}
        nextOnPress={handleSubmit(onSubmit)}
        nextTitleTranslationKey="selectWork"
        nextDisabled={isSubmitting}
      />
    </>
  )
}

const styles = StyleSheet.create({
  goodPractices: {
    flex: 1,
    marginBottom: 25
  }
})
