import { z } from 'zod'

const baseWorkSchema = z.object({
  applicationStandards: z.string().optional(),
  applicationProcedures: z.string().optional(),
  goodPractices: z.string().optional(),
  id: z.string(),
  isLast: z.boolean(),
  hasOtherSpecificWork: z.boolean(),
  name: z.string(),
  requiredDocuments: z.string().optional()
})

export const workSchema = baseWorkSchema.extend({
  parents: z.array(baseWorkSchema)
})

export type Work = z.infer<typeof workSchema>
