// External
import { zodResolver } from '@hookform/resolvers/zod'
import type { StackScreenProps } from '@react-navigation/stack'
import { useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { z } from 'zod'
// Components
import {
  Input,
  Link,
  ProgressBar,
  StepNavigationButtons,
  Text
} from '@/common/components'
import {
  ExtraQuestionAnswer,
  MachinerySelect
} from '@/cspPermitToWork/components'
// Constants
import { colors } from '@/common/constants'
import {
  machinerySchema,
  machineryZoneSchema
} from '@/cspPermitToWork/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { CSPPTWStackParamList } from '@/cspPermitToWork/models'
// Stores
import useNewRequestStore from '@/cspPermitToWork/stores/useNewRequestStore'
// Utils
import { getCursorValueString } from '@/cspPermitToWork/utils'

type Props = StackScreenProps<CSPPTWStackParamList, 'NewRequestStep4Machinery'>

const NewRequestStep4Machinery = ({ navigation }: Props) => {
  const { values: currentValues, setValues } = useNewRequestStore()
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      z.object({
        machinery: z
          .array(
            z.object({
              machinery: machinerySchema,
              specificMachinery: z.string().optional(),
              zone: machineryZoneSchema,
              specificZone: z.string().optional(),
              extraQuestion: z
                .object({
                  answer: z.string(),
                  extraQuestionId: z.string(),
                  question: z.string()
                })
                .optional()
            })
          )
          .min(1, { message: t('selectAtLeastOneMachinery') })
      }),
    [t]
  )

  type FormValues = z.infer<typeof formSchema>

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      machinery: []
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'machinery'
  })

  const onSubmit = (values: FormValues) => {
    setValues({ ...currentValues, ...values })
    navigation.navigate('NewRequestStep5')
  }

  return (
    <SafeArea style={styles.container}>
      <ProgressBar style={styles.progressBar} value={0.4444} />

      <Text variant="h2Bold" style={{ marginBottom: 8 }}>
        {t('machinery')}
      </Text>

      <Text style={{ marginBottom: 25 }}>{t('selectTheMachinery')}</Text>

      {fields.map((field, index) => (
        <View key={field.id}>
          <View style={styles.machinery}>
            <Text variant="label">
              {t('selectedMachinery', {
                index: index + 1
              })}
            </Text>

            <Link
              icon={{
                name: 'minus'
              }}
              color={colors.error}
              onPress={() => {
                remove(index)
              }}
            >
              {t('removeMachinery')}
            </Link>
          </View>

          <Input
            editable={false}
            value={getCursorValueString(field.machinery)}
          />

          {field.specificMachinery !== undefined && (
            <Text variant="smallBold">
              {t('specificMachinery')}
              <Text variant="small" color={colors.placeholder}>
                {` ${field.specificMachinery}`}
              </Text>
            </Text>
          )}

          <Text variant="smallBold">
            {t('machineryZone')}
            <Text variant="small" color={colors.placeholder}>
              {` ${field.zone.name}`}
            </Text>
          </Text>

          {field.specificZone !== undefined && (
            <Text variant="smallBold">
              {t('specificZone')}
              <Text variant="small" color={colors.placeholder}>
                {` ${field.specificZone}`}
              </Text>
            </Text>
          )}

          {field.extraQuestion !== undefined && (
            <ExtraQuestionAnswer
              question={field.extraQuestion.question}
              answer={field.extraQuestion.answer}
            />
          )}
        </View>
      ))}

      {errors?.machinery != null && (
        <Text variant="extraSmall" color={colors.error}>
          {errors?.machinery.message}
        </Text>
      )}

      <MachinerySelect
        onSelect={(machinery) => {
          append(machinery)
        }}
        numberSelected={fields.length}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default NewRequestStep4Machinery

const styles = StyleSheet.create({
  container: {
    marginBottom: 25,
    marginHorizontal: 25
  },
  progressBar: {
    marginTop: 11,
    marginBottom: 48
  },
  machinery: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
