import { client } from '@/common/config'

export const getPermitCounts = async () => {
  const { data, error } = await client.GET(
    '/api/app/csp-permit-to-work/requests/status/count'
  )

  if (error !== undefined) {
    console.error('Error getting permit counts:', error)
    return await Promise.reject(error)
  }

  return data.data
}
