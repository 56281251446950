// External
import { zodResolver } from '@hookform/resolvers/zod'
import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, type TextInput } from 'react-native'
import { z } from 'zod'
import { type StackScreenProps } from '@react-navigation/stack'
// Components
import {
  Input,
  ProgressBar,
  StepNavigationButtons,
  Text
} from '@/common/components'
// Constants
import { toast } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Services
import { getEmailAvailable } from '@/auth/services'
import useCreateAccountStore from '@/auth/stores/useCreateAccountStore'
// Models
import { type PreHomeStackParamList } from '@/common/models'

type Props = StackScreenProps<PreHomeStackParamList, 'CreateAccountStep1'>

const CreateAccountStep1 = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { email, setEmail } = useCreateAccountStore((state) => ({
    email: state.email,
    setEmail: state.setEmail
  }))
  const repeatEmailInputRef = useRef<TextInput>(null)

  const validationSchema = z
    .object({
      email: z
        .string()
        .nonempty({ message: t('provideEmail') })
        .email({ message: t('emailNotValid') }),
      repeatEmail: z
        .string()
        .nonempty({ message: t('provideEmail') })
        .email({ message: t('emailNotValid') })
    })
    .refine((data) => data.email === data.repeatEmail, {
      message: t('emailsMustMatch'),
      path: ['repeatEmail']
    })

  type FormValues = z.infer<typeof validationSchema>

  const onSubmit = async ({ email: formEmail }: FormValues) => {
    try {
      const { available } = await getEmailAvailable(formEmail)

      if (!available) {
        toast.error({
          data: {
            titleTranslationKey: 'emailNotAvailableTitle',
            messageTranslationKey: 'emailNotAvailableMessage'
          }
        })
        return
      }

      setEmail(formEmail)
      navigation.navigate('CreateAccountStep2')
    } catch (error: any) {
      toast.error({
        data: {
          titleTranslationKey: 'error',
          messageTranslationKey: error.message
        }
      })
    }
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email,
      repeatEmail: email
    }
  })

  return (
    <SafeArea style={styles.container} withBackground>
      <ProgressBar style={{ marginTop: 11 }} value={0.2} />

      <Text variant="h2Bold" style={styles.header}>
        {t('createAnAccount')}
      </Text>

      <Text style={{ marginBottom: 25 }}>{t('typeEmailTwice')}</Text>

      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            placeholder={t('yourEmail')}
            onChangeText={onChange}
            onSubmitEditing={() => {
              repeatEmailInputRef.current?.focus()
            }}
            onBlur={onBlur}
            value={value}
            inputMode="email"
            returnKeyType="next"
            autoComplete="email"
            autoCapitalize="none"
            errorMessage={errors.email?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="repeatEmail"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            ref={repeatEmailInputRef}
            placeholder={t('repeatYourEmail')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(onSubmit)}
            onBlur={onBlur}
            value={value}
            inputMode="email"
            returnKeyType="done"
            autoComplete="email"
            autoCapitalize="none"
            errorMessage={errors.repeatEmail?.message}
          />
        )}
      />

      <StepNavigationButtons
        nextOnPress={handleSubmit(onSubmit)}
        nextDisabled={isSubmitting}
      />
    </SafeArea>
  )
}

export default CreateAccountStep1

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 25,
    marginBottom: 25
  },
  header: {
    marginTop: 48,
    marginBottom: 8
  }
})
