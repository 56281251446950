// External
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
// Components
import { Button, StepNavigationButtons, TextAndLink } from '@/common/components'
import { VerificationCodeInput } from './VerificationCodeInput'
// Constants
import { toast } from '@/common/constants'
// Services
import {
  generateEmailChangeRequest,
  getVerificationCode
} from '@/auth/services'
// Utils
import { handleError } from '@/common/utils'

const validationSchema = z.object({
  verificationCode: z.string().min(4).max(4)
})

type FormValues = z.infer<typeof validationSchema>

interface Props {
  email: string
  onSubmit: (values: FormValues) => void
  sendAgainType: 'change' | 'signupOrVerify'
  buttonType: 'submit' | 'stepNavigation'
}

export const VerifyEmailForm = ({
  email,
  onSubmit,
  sendAgainType,
  buttonType
}: Props) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      verificationCode: ''
    }
  })

  return (
    <>
      <Controller
        control={control}
        name="verificationCode"
        render={({ field: { onChange, value } }) => (
          <VerificationCodeInput
            style={{ marginBottom: 24 }}
            value={value}
            onChangeText={onChange}
          />
        )}
      />

      <TextAndLink
        style={{ textAlign: 'center' }}
        text={t('haventReceivedTheCode')}
        linkText={t('sendAgain')}
        linkOnPress={async () => {
          try {
            if (sendAgainType === 'change') {
              await generateEmailChangeRequest(email)
            } else {
              await getVerificationCode(email)
            }

            toast.success({
              data: {
                messageTranslationKey: 'sentYouANewCode'
              }
            })
          } catch (error) {
            handleError(error)
          }
        }}
      />

      {buttonType === 'submit' && (
        <Button
          style={{ marginTop: 48 }}
          onPress={handleSubmit(onSubmit)}
          title={t('verifyEmail')}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      )}

      {buttonType === 'stepNavigation' && (
        <StepNavigationButtons
          nextOnPress={handleSubmit(onSubmit)}
          nextDisabled={isSubmitting}
        />
      )}
    </>
  )
}
