import { client } from '@/common/config'
import type { PaginatedGetParams } from '@/common/models'

export const getMachineryZones = async ({
  page,
  limit = 100
}: PaginatedGetParams) => {
  const { data, error } = await client.GET(
    '/api/app/csp-permit-to-work/procedures/machinery/machinery-zones/search',
    {
      params: {
        query: {
          page,
          limit
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting machinery zones:', error)
    return await Promise.reject(error)
  }

  return data.data
}
