import { z } from 'zod'

export const contractorSchema = z.object({
  _id: z.string(),
  uuid: z.string(),
  appUser: z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    profileImage: z.string().optional(),
    phoneNumber: z.object({
      prefix: z.number().or(z.string()),
      phone: z.string()
    })
  })
})

export type Contractor = z.infer<typeof contractorSchema>
