import { client } from '@/common/config'
import type { components } from '@/common/models'

export const createPermitRequest = async (
  params: components['schemas']['CreateCSPPermitToWorkRequestDTO']
) => {
  const { data, error } = await client.POST(
    '/api/app/csp-permit-to-work/requests/submit',
    {
      body: params
    }
  )

  if (error !== undefined) {
    console.error('Error creating permit request:', error)
    return await Promise.reject(error)
  }

  return data.data
}
