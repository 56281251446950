import { Select } from '@/common/components'
import type { BaseSelectProps, components, Option } from '@/common/models'
import useAppStore from '@/common/stores/useAppStore'
import { getContractorCompanies } from '@/cspPermitToWork/services'
import { useQuery } from '@tanstack/react-query'

interface Props extends BaseSelectProps {
  onSelect?: (option: Option<components['schemas']['PTWCompany']>) => void
  onMultipleSelect?: (
    options: Array<Option<components['schemas']['PTWCompany']>>
  ) => void
  value?: string | string[]
  exclude?: string[]
}

export const ContractorCompanySelect = ({
  onSelect,
  value,
  errorMessage,
  label,
  placeholder,
  searchPlaceholder,
  noResultsTranslationKey,
  exclude = []
}: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))

  const { data, error, isError, isLoading } = useQuery({
    queryKey: ['cspCompanies', currentFacility?._id],
    queryFn: async () =>
      await getContractorCompanies(currentFacility?._id as string)
  })

  return (
    <Select
      options={
        data
          ?.filter((company) => !exclude?.includes(company._id))
          .map((company) => ({
            label: company.name,
            value: company._id,
            original: company
          })) ?? []
      }
      value={value}
      errorMessage={errorMessage}
      disabled={(error != null && isError) || isLoading}
      onSelect={onSelect}
      label={label}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      noResultsTranslationKey={noResultsTranslationKey}
    />
  )
}
