import {
  CSPPermitToWorkRequestStatusEnum,
  type ExtendedPermitStatus,
  PTWRequestStatus
} from '@/common/models'
import { isAfter, isBefore } from 'date-fns'

interface Params {
  status: PTWRequestStatus | CSPPermitToWorkRequestStatusEnum
  startDate: string
  endDate: string
}

export const getExtendedPermitStatus = ({
  status,
  ...rest
}: Params): ExtendedPermitStatus => {
  const now = new Date()
  const startDate = new Date(rest.startDate)
  const endDate = new Date(rest.endDate)

  if (
    status === PTWRequestStatus.APPROVED ||
    status === CSPPermitToWorkRequestStatusEnum.APPROVED
  ) {
    if (isAfter(now, endDate)) {
      return 'FINISHED'
    }

    if (isBefore(now, startDate)) {
      return 'QUEUED'
    }

    return 'ACTIVE'
  }

  return status
}
