import { client } from '@/common/config'
import { type PaginatedGetParams } from '@/common/models'

interface Params extends PaginatedGetParams {
  parentTypeOfWorkId?: string
}

export const getTypesOfWork = async ({
  parentTypeOfWorkId,
  page,
  limit = 100
}: Params) => {
  const { data, error } = await client.GET(
    '/api/app/cps-permit-to-work/procedures/types-of-work/search',
    {
      params: {
        query: {
          parentTypeOfWorkId,
          page,
          limit
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting types of work:', error)
    return await Promise.reject(error)
  }

  return data.data
}
