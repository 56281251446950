// External
import { zodResolver } from '@hookform/resolvers/zod'
import { type StackScreenProps } from '@react-navigation/stack'
import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, type TextInput } from 'react-native'
import { SheetManager } from 'react-native-actions-sheet'
import { z } from 'zod'
// Components
import {
  Button,
  Input,
  LanguageDropdown,
  Link,
  // ThemeDropdown,
  Logo,
  Text,
  TextAndLink
} from '@/common/components'
// Hooks
import { toast } from '@/common/constants'
// Layouts
import { KeyboardFix, SafeArea } from '@/common/layouts'
// Models
import { type PreHomeStackParamList } from '@/common/models'
// Use cases
import { signIn } from '@/auth/useCases'
// Utils
import { handleError } from '@/common/utils'

type Props = StackScreenProps<PreHomeStackParamList, 'Signin'>

const Signin = ({ navigation, route }: Props) => {
  const { t } = useTranslation()
  const passwordInputRef = useRef<TextInput>(null)

  const validationSchema = z.object({
    email: z
      .string()
      .nonempty({ message: t('provideEmail') })
      .email({ message: t('emailNotValid') }),
    password: z.string().min(1, { message: t('providePassword') })
  })

  type FormValues = z.infer<typeof validationSchema>

  const onSubmit = async ({ email, password }: FormValues) => {
    try {
      const res = await signIn({ email, password })

      toast.success({
        data: { messageTranslationKey: res.messageTranslationKey }
      })
    } catch (error) {
      handleError(error)
    }
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const { params } = route

  useEffect(() => {
    if (typeof params?.resetPasswordEmail === 'string') {
      // ! doesn't work on web without timeout
      setTimeout(() => {
        void SheetManager.show('forgot-password', {
          payload: {
            email: params.resetPasswordEmail
          }
        })
      }, 100)
    }
  }, [params])

  return (
    <SafeArea withBackground>
      <KeyboardFix contentContainerStyle={{ paddingHorizontal: 25 }}>
        <View style={styles.dropdowns}>
          <Logo style={{ marginEnd: 'auto' }} />

          <LanguageDropdown />

          {/* <ThemeDropdown /> */}
        </View>

        <Text variant="h2Bold" style={{ marginTop: 48 }}>
          {t('enterYourEmail')}
        </Text>

        <Text variant="h2Bold" style={{ marginBottom: 25 }}>
          {t('andYourPassword')}
        </Text>

        <TextAndLink
          style={{ marginBottom: 25 }}
          text={t('dontHaveAccount')}
          linkText={t('createAccount')}
          linkOnPress={() => {
            navigation.navigate('CreateAccountStep1')
          }}
        />

        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              placeholder={t('yourEmail')}
              onChangeText={onChange}
              onSubmitEditing={() => {
                passwordInputRef.current?.focus()
              }}
              onBlur={onBlur}
              value={value}
              inputMode="email"
              returnKeyType="next"
              autoComplete="email"
              autoCapitalize="none"
              errorMessage={errors.email?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              style={{ marginBottom: 25 }}
              ref={passwordInputRef}
              placeholder={t('yourPassword')}
              onChangeText={onChange}
              onSubmitEditing={handleSubmit(onSubmit)}
              onBlur={onBlur}
              value={value}
              returnKeyType="done"
              autoCapitalize="none"
              secureTextEntry
              errorMessage={errors.password?.message}
            />
          )}
        />

        <Link
          style={{ marginBottom: 25 }}
          onPress={() => {
            void SheetManager.show('forgot-password')
          }}
        >
          {t('forgotPassword')}
        </Link>

        <Button
          title={t('signIn')}
          style={{ marginTop: 'auto', marginBottom: 25 }}
          onPress={handleSubmit(onSubmit)}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </KeyboardFix>
    </SafeArea>
  )
}

export default Signin

const styles = StyleSheet.create({
  dropdowns: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 16,
    alignItems: 'center',
    marginTop: 11
  }
})
