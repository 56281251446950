// External
import { Divider } from '@rneui/themed'
import { useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { z } from 'zod'
// Components
import {
  Input,
  RadioButton,
  StepNavigationButtons,
  Text
} from '@/common/components'
import { ExtraQuestion } from '../ExtraQuestion'
import { PermitRichText } from '../PermitRichText'
// Constants
import { colors } from '@/common/constants'
import type { AnsweredExtraQuestion, Zone } from '@/cspPermitToWork/constants'

interface Props {
  zone: Zone
  onSelect: ({
    zone,
    specificZone,
    extraQuestion
  }: {
    zone: Zone
    specificZone?: string
    extraQuestion?: AnsweredExtraQuestion
  }) => void
  back: () => void
}

export const LastZoneForm = ({ zone, onSelect, back }: Props) => {
  const { t } = useTranslation()

  const formSchema = useMemo(
    () =>
      z
        .object({
          extraQuestion: z.any(),
          specificZone: z.string().optional()
        })
        .superRefine(({ specificZone }, ctx) => {
          if (
            zone.mustSpecifyZone &&
            (specificZone === undefined || specificZone.length === 0)
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('requiredField'),
              path: ['specificZone']
            })
          }
        }),
    [zone, t]
  )

  type FormValues = z.infer<typeof formSchema>

  const methods = useForm<FormValues>({})

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = methods

  const onSubmit = (values: FormValues) => {
    onSelect({
      zone,
      ...values
    })
  }

  return (
    <>
      <View style={styles.lastZone}>
        <Text variant="small" color={colors.placeholder}>
          {zone.name}
        </Text>

        <RadioButton checked />
      </View>

      <Divider style={{ marginBottom: 12 }} />

      {zone.mustSpecifyZone && (
        <Controller
          control={control}
          name="specificZone"
          render={({
            field: { onBlur, onChange, value },
            fieldState: { error }
          }) => (
            <Input
              label={t('specifyTheStartAndEndZone')}
              placeholder={t('specifyZone')}
              errorMessage={error?.message}
              value={value}
              onChangeText={onChange}
              onBlur={onBlur}
            />
          )}
        />
      )}

      {zone.extraQuestions[0] !== undefined && (
        <FormProvider {...methods}>
          <ExtraQuestion {...zone.extraQuestions[0]} />
        </FormProvider>
      )}

      {zone.applicationStandards !== undefined && (
        <PermitRichText
          style={styles.applicationStandards}
          title={t('applicationStandardsFor', { zone: zone.name })}
          html={zone.applicationStandards}
        />
      )}

      <StepNavigationButtons
        style={{ marginBottom: 25 }}
        backOnPress={back}
        nextOnPress={handleSubmit(onSubmit)}
        nextTitleTranslationKey="selectZone"
        nextDisabled={isSubmitting}
      />
    </>
  )
}

const styles = StyleSheet.create({
  lastZone: {
    height: 52,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 12
  },
  applicationStandards: {
    flex: 1,
    marginBottom: 25
  }
})
