// External
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { ImagePickerAsset } from 'expo-image-picker'
import { type SheetDefinition, registerSheet } from 'react-native-actions-sheet'
// Models
import type { Option, PaginatedGetParams, components } from '@/common/models'
import type { ReactNode } from 'react'
// Sheets
import { ChangeEmail } from '@/auth/sheets/ChangeEmail'
import { ForgotPassword } from '@/auth/sheets/ForgotPassword'
import {
  EditEmail,
  EditName,
  EditNumber,
  EditPassword,
  EditProfilePhoto,
  InfiniteSelectSheet,
  MultipleInfiniteSelectSheet,
  MultipleSelectSheet,
  SelectSheet,
  UploadOptionsSheet
} from '@/common/sheets'
import { EditCustomField } from '@/profile/sheets/EditCustomField'

registerSheet('upload-options', UploadOptionsSheet)
registerSheet('edit-email', EditEmail)
registerSheet('edit-name', EditName)
registerSheet('edit-number', EditNumber)
registerSheet('edit-password', EditPassword)
registerSheet('edit-profile-photo', EditProfilePhoto)
registerSheet('forgot-password', ForgotPassword)
registerSheet('change-email', ChangeEmail)
registerSheet('edit-custom-field', EditCustomField)
registerSheet('select', SelectSheet)
registerSheet('infinite-select', InfiniteSelectSheet)
registerSheet('multiple-select', MultipleSelectSheet)
registerSheet('multiple-infinite-select', MultipleInfiniteSelectSheet)

interface EditNameParams {
  firstName: string
  lastName: string
}

interface EditNumberParams {
  prefix: string
  phone: string
}

interface EditPasswordParams {
  password: string
  repeatPassword: string
}

declare module 'react-native-actions-sheet' {
  interface Sheets {
    'upload-options': SheetDefinition<{
      payload: {
        onImagePickerAsset:
          | ((asset: ImagePickerAsset) => Promise<void>)
          | ((asset: ImagePickerAsset) => void)
        onDocumentPickerAsset?:
          | ((asset: DocumentPickerAsset) => Promise<void>)
          | ((asset: DocumentPickerAsset) => void)
      }
    }>
    'edit-email': SheetDefinition<{
      payload: {
        onSubmit: (email: string) => Promise<void>
        initialValue: string
      }
    }>
    'edit-name': SheetDefinition<{
      payload: {
        onSubmit:
          | ((params: EditNameParams) => Promise<void>)
          | ((params: EditNameParams) => void)
        initialValues: {
          firstName: string
          lastName: string
        }
      }
    }>
    'edit-number': SheetDefinition<{
      payload: {
        onSubmit:
          | ((params: EditNumberParams) => Promise<void>)
          | ((params: EditNumberParams) => void)
        initialValues?: {
          prefix: number
          phone: string
        }
      }
    }>
    'edit-password': SheetDefinition<{
      payload: {
        onSubmit:
          | ((params: EditPasswordParams) => Promise<void>)
          | ((params: EditPasswordParams) => void)
      }
    }>
    'edit-profile-photo': SheetDefinition<{
      payload: {
        onSubmit: (photo: ImagePickerAsset | string | undefined) => void
        initialValue: ImagePickerAsset | string | undefined
      }
    }>
    'forgot-password': SheetDefinition<{
      payload: {
        email?: string
      }
    }>
    'permit-signatures': SheetDefinition<{
      payload: {
        permitUUID?: string
      }
    }>
    'change-email': SheetDefinition<{
      payload: {
        email: string
      }
    }>
    'edit-custom-field': SheetDefinition<{
      payload: {
        field: components['schemas']['GetAppUserCustomFieldResponseItem']
        refetch: () => Promise<unknown>
        isFilled: boolean
      }
    }>
    select: SheetDefinition<{
      payload: {
        options: Option[]
        searchable?: boolean
        placeholder?: string
        searchPlaceholder?: string
        noResultsTranslationKey?: string
      }
      returnValue: Option
    }>
    'infinite-select': SheetDefinition<{
      payload: {
        searchable?: boolean
        placeholder?: string
        searchPlaceholder?: string
        queryKey: any[]
        queryFn: (
          params: PaginatedGetParams & Record<string, string>
        ) => Promise<any>
        getOptions: (data: any[]) => Option[]
        noResultsTranslationKey?: string
        renderOption?: (option: Option) => ReactNode
      }
      returnValue: Option
    }>
    'multiple-select': SheetDefinition<{
      payload: {
        selectedOptions: Option[]
        options: Option[]
        searchable?: boolean
        placeholder?: string
        searchPlaceholder?: string
        noResultsTranslationKey?: string
      }
      returnValue: Option[]
    }>
    'multiple-infinite-select': SheetDefinition<{
      payload: {
        searchable?: boolean
        placeholder?: string
        searchPlaceholder?: string
        queryKey: any[]
        queryFn: (
          params: PaginatedGetParams & Record<string, string>
        ) => Promise<any>
        getOptions: (data: any[]) => Option[]
        noResultsTranslationKey?: string
        selectedOptions: Option[]
      }
      returnValue: Option[]
    }>
  }
}

export {}
