import { colors } from '@/common/constants'
import RenderHtml, {
  defaultSystemFonts,
  type TRenderEngineConfig
} from 'react-native-render-html'

interface Props {
  tagsStyles?: TRenderEngineConfig['tagsStyles']
  contentWidth?: number
  html: string
}

export const RenderHTML = ({ tagsStyles, contentWidth, html }: Props) => {
  return (
    <RenderHtml
      source={{ html }}
      systemFonts={[
        ...defaultSystemFonts,
        'PlusJakartaSans_400Regular',
        'PlusJakartaSans_700Bold',
        'PlusJakartaSans_400Regular_Italic'
      ]}
      contentWidth={contentWidth}
      baseStyle={{
        color: colors.text,
        fontFamily: 'PlusJakartaSans_400Regular',
        fontSize: 15,
        lineHeight: 21,
        letterSpacing: 0.35
      }}
      tagsStyles={{
        em: {
          fontFamily: 'PlusJakartaSans_400Regular_Italic'
        },
        h1: {
          fontSize: 20,
          margin: 0,
          marginBottom: 4
        },
        h2: {
          fontSize: 17,
          margin: 0,
          marginBottom: 4
        },
        h3: {
          fontSize: 15,
          margin: 0,
          marginBottom: 4
        },
        h4: {
          fontSize: 13,
          margin: 0,
          marginBottom: 4
        },
        hr: {
          backgroundColor: colors.lightGray10Percent,
          marginVertical: 20
        },
        // https://github.com/meliorence/react-native-render-html/issues/592#issuecomment-1547510349
        p: {
          marginVertical: 0
        },
        strong: {
          fontFamily: 'PlusJakartaSans_700Bold'
        },
        ...tagsStyles
      }}
    />
  )
}
