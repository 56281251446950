import { client } from '@/common/config'

interface Params {
  preventiveOfficerId: string
  requestId: string
  signatureId: string
}

export const signPermit = async ({
  preventiveOfficerId,
  requestId,
  signatureId
}: Params) => {
  const { error } = await client.POST(
    '/api/app/csp-permit-to-work/requests/signatures/{requestId}/sign/{signatureId}',
    {
      body: {
        preventiveOfficerId
      },
      params: {
        path: {
          requestId,
          signatureId
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error signing permit:', error)
    return await Promise.reject(error)
  }
}
